// import auth from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";
import { callSendOneSignalNotification } from "../one-signal-helper";
export const HOST_SERVER_NAME = "UAT";


const db = firebase.firestore();
export const getRef = ({ collection, doc }) => db.collection(collection).doc(doc);

export function sendChatMsg(senderId, receiverId, message, msgType = "TEXT", attachmentUrl = "", thumbUrl = "", isDeleted = false) {
    return new Promise((resolve, reject) => {
        if (msgType == "TEXT" && !message?.trim()) {
            reject();
            return;
        }
        let chatId = createChatId(receiverId, senderId);
        let senderChatData = {
            // created_time: firestore.FieldValue.serverTimestamp(),
            created_time: firebase.firestore.Timestamp.now().toMillis(),
            sender_id: senderId,
            message: message ? message.trim() : message,
            url: attachmentUrl,
            thumb_url: thumbUrl,
            msg_type: msgType,// TEXT / IMAGE / AUDIO / VIDEO,
            is_deleted: isDeleted
        }

        db.collection(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT/${chatId}`).add(senderChatData).then(docRef => {
            if (docRef?.id) {
                sendNewMessageBroadcast(receiverId);
                getChatSetting(senderId, receiverId).then(data => {
                    if (!data?.online_users?.length || data?.online_users.findIndex(o => o == receiverId) < 0) {
                        sendNotification(receiverId, msgType == "IMAGE" ? 'Sent an Image' : msgType == "AUDIO" ? 'Sent an Audio' : msgType == "VIDEO" ? 'Sent a Video' : message);
                    }
                }).catch(error => { });
                resolve({ message_id: docRef.id, created_time: senderChatData?.created_time, msg_type: senderChatData?.msg_type, message: senderChatData?.message });
            } else {
                reject();
            }
        }).catch(error => {
            console.log("aa ----- error=>", error);
            let newErr = { message: error.message }
            if (error.code === 'firestore/permission-denied') {
                newErr.message = "firebase_user_blocked_account";
                reject(newErr);
            } else {
                reject(newErr);
            }
        });
    });
}


/*
 * Method use to get message data using pagination
 * @param senderId: string
 * @param limit: number
 * @param isLoadPrevious: boolean
 */
export function getChatMessages(senderId, receiverId, limit, isLoadPrevious, isForChatList = false) {
    return new Promise((resolve, reject) => {
        let lastVisible;
        let chatId = createChatId(receiverId, senderId);
        let query = db.collection(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT/${chatId}`)
            // .where('isBlocked','==', isBlocked)
            // .where('created_time', '>=', startDate ? startDate : 0)
            .orderBy("created_time", "desc");
        if (limit && limit > -1) {
            if (isLoadPrevious && lastVisible) {
                query = query.startAfter(lastVisible).limit(limit);
            } else {
                lastVisible = '';
                query = query.limit(limit);
            }
        }
        query.get().then(querySnapshot => {
            if (querySnapshot) {
                if (!isForChatList) {
                    lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                }
                let list = [];
                querySnapshot.forEach(doc => {
                    if (doc?.data()) {
                        doc.data().docId = doc.id;
                        list.push(doc.data());
                    }
                });
                resolve(list);
            } else {
                resolve([]);
            }
        }).catch(error => {
            reject(error);
        });
    });
}

/*
 * Method use to set  Chat Snapshot
 */
export function setChatSnapshot(senderId, receiverId, callback) {
    let isFirstTime = true;
    let chatId = createChatId(receiverId, senderId)
    let snapshot = db
        .collection(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT/${chatId}`)
        // .where('created_time', '>=', startDate ? startDate : 0)
        .orderBy("created_time", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
            if (!isFirstTime) {
                querySnapshot?.docChanges()?.forEach(item => {
                    if (item?.type && item.doc?.data()?.created_time) {
                        callback({ type: item?.type, item: item?.doc?.data(), docId: item.doc.id });
                    }
                })
            } else {
                isFirstTime = false;
                // callback(null);
            }
        });
    return snapshot
}

export function getChatSetting(senderId, receiverId) {
    return new Promise((resolve, reject) => {
        let chatId = createChatId(receiverId, senderId);
        db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_SETTINGS/${chatId}/ROOM`).get().then(doc => {
            if (doc?.data()) {
                resolve(doc.data())
            } else {
                reject()
            }
        }).catch(error => {
            reject(error);
        });
    });
}

export function setChatSettingSnapshot(senderId, receiverId, callback) {
    let chatId = createChatId(receiverId, senderId);
    let snapshot = db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_SETTINGS/${chatId}/ROOM`)
        .onSnapshot(doc => {
            callback(doc?.data());
        });
    return snapshot
}

export function setChatSetting(senderId, receiverId) {
    return new Promise((resolve, reject) => {
        let chatId = createChatId(receiverId, senderId);
        let data = {
            admin: [senderId, receiverId],
            user: [senderId, receiverId],
            is_chat_freeze: { [senderId]: false, [receiverId]: false },
            clear_chat: { [senderId]: -1, [receiverId]: -1 },
        }
        db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_SETTINGS/${chatId}/ROOM`).set(data).then(doc => {
            resolve();
        }).catch(error => {
            reject(error);
        });
    });
}

export function updateLastMessage(userId, friendId, lastChatMsgTime, isSilentUpdate) {
    let senderQuery = db.collection(`users`).doc(userId);
    senderQuery.get().then((doc) => {
        if (doc?.exists && doc?.data()) {
            if (!isSilentUpdate) {
                sendNewMessageBroadcast(userId);
            }
            let updatedFriendsMap = doc?.data()?.friends ? { ...doc.data().friends, [friendId]: lastChatMsgTime } : { [friendId]: lastChatMsgTime }
            // console.log("aa -------- updatedFriendsMap=>", updatedFriendsMap);
            db.doc(`users/${userId}`).update({ friends: updatedFriendsMap });
        }
    });
}

export function createChatId(receiverId, senderId) {
    return senderId > receiverId ? (senderId + receiverId) : (receiverId + senderId)
}

export function getFriendList(callback) {
    let userId = firebase.auth()?.currentUser?.uid;
    // console.log("aa --------- userId=>", userId);
    if (userId) {
        db.collection(`users`).doc(userId).get().then((doc) => {
            if (doc?.exists && doc?.data()) {
                let friends = doc?.data()?.friends;
                // console.log("aa --------- friends=>", friends);
                // console.log("aa --------- friendIds=>", Object.keys(friends));
                let friendIds = Object.keys(friends);
                let dataMap = {};
                let count = 0;
                let callbackFun = () => {
                    count = count + 1;
                    if (count == friendIds.length) {
                        if (callback) {
                            callback(dataMap);
                        }
                    }
                }
                friendIds.forEach(id => {
                    db.collection(`users`).doc(id).get().then((friendDoc) => {
                        if (friendDoc?.exists && friendDoc?.data()) {
                            let chatId = createChatId(friendDoc?.data()?.userId, userId);
                            dataMap[chatId] = friendDoc?.data();
                            // console.log("aa --------- friend id=>", friendDoc?.data()?.userId);
                            let lastChatReadTime = friends[friendDoc?.data()?.userId];
                            // console.log("aa --------- lastChatReadTime=>", lastChatReadTime);
                            if (lastChatReadTime && lastChatReadTime != -1) {
                                getChatMessages(friendDoc?.data()?.userId, userId, 1, false, true).then((lastChatData) => {
                                    // console.log("aa --------- lastChatData=>", lastChatData);
                                    if (lastChatData?.length) {
                                        dataMap[chatId].chat_last_message = lastChatData[0];
                                        if (lastChatData[0].sender_id != userId) {
                                            getAllUnreadCount(chatId, lastChatReadTime).then(count => {
                                                // console.log("aa --------- count=>", count);
                                                dataMap[chatId].chat_unread_count = count;
                                                callbackFun();
                                            }).catch(error => {
                                                callbackFun();
                                            });
                                        } else {
                                            callbackFun();
                                        }
                                    } else {
                                        callbackFun();
                                    }
                                }).catch(error => {
                                    callbackFun();
                                });
                            } else {
                                getChatMessages(friendDoc?.data()?.userId, userId, -1, false, true).then((lastChatData) => {
                                    // console.log("aa -------else-- lastChatData=>", lastChatData);
                                    if (lastChatData?.length) {
                                        dataMap[chatId].chat_last_message = lastChatData[0];
                                        dataMap[chatId].chat_unread_count = lastChatData?.length;
                                    }
                                    callbackFun();
                                }).catch(error => {
                                    callbackFun();
                                });
                            }
                        } else {
                            callbackFun();
                        }
                    }).catch(error => {
                        callbackFun();
                    });
                });
            } else {
                callback({});
            }
        }).catch(error => {
            callback({});
        });
    }
}

export function getAllUnreadCount(chatId, lastChatData) {
    return new Promise((resolve, reject) => {
        db.collection(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT/${chatId}`)
            .orderBy("created_time", "desc").where('created_time', '>', lastChatData ? lastChatData : 0)
            .get().then(querySnapshot => {
                resolve(querySnapshot.size);
            }).catch(error => {
                console.log("getAllUnreadCount error=", error);
                reject();
            });
    });
}

function sendNewMessageBroadcast(userId) {
    return new Promise((resolve, reject) => {
        db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_BROADCAST/${userId}/DATA`)
            .set({ timestamp: firebase.firestore.Timestamp.now().toMillis() }).then(doc => {
                resolve();
            }).catch(error => {
                reject(error);
            });
    });
}

/*
 * Method use to set Chat Snapshot
 * @param userId: string
 */
export function setChatListSnapshot(callback) {
    let userId = firebase.auth()?.currentUser?.uid;
    return db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_BROADCAST/${userId}/DATA`).onSnapshot(doc => {
        if (callback) {
            callback();
        }
    }, error => {
        console.log("setChatListSnapshot error", error)
    });
}

/*
 * Method use to remove Snapshot
 */
export function removeSnapshot(snapshot) {
    if (snapshot) {
        snapshot();
    }
}

/*
 * Method use to update active user
 * @param userId: string
 */
export function updateActiveUser(userId, friendId, isActive) {
    // console.log("aa ------------ updateActiveUser ------------");
    if (userId) {
        let chatId = createChatId(userId, friendId);
        db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_SETTINGS/${chatId}/ROOM`)
            .update({ active_users: isActive ? firebase.firestore.FieldValue.arrayUnion(userId) : firebase.firestore.FieldValue.arrayRemove(userId) })
            .then(doc => { }).catch(error => {
                console.log("aa -----updateActiveUser error=", error);
            });
    }
}

/*
 * Method use to update online user
 * @param userId: string
 */
export function updateOnlineUser(userId, friendId, isOnline) {
    // console.log("aa ------------ updateOnlineUser ------------");
    try {
        if (userId) {
            let chatId = createChatId(userId, friendId);
            db.doc(`ENV/${HOST_SERVER_NAME}/CHAT_MODULE/CHAT_SETTINGS/${chatId}/ROOM`)
                .update({ online_users: isOnline ? firebase.firestore.FieldValue.arrayUnion(userId) : firebase.firestore.FieldValue.arrayRemove(userId) })
                .then(doc => { }).catch(error => {
                    console.log("aa -----updateOnlineUser error=", error.code);
                });
        }
    } catch (error) {
        console.log("aa ------------ updateOnlineUser -----catch-------error=>", error);
    }
}

////////////////////////////////////////////////////////////////////

// get user data
function getUserData(UID) {
    return new Promise((resolve, reject) => {
        db.doc(`users/${UID}`).get().then(doc => {
            resolve(doc.data())
        }).catch(error => {
            reject(error);
        });
    });
}

export function updateUserDetailsBrodcast() {
    let UID = firebase.auth()?.currentUser?.uid;
    getUserData(UID).then((data) => {
        if (data?.friends) {
            let friendIds = Object.keys(data?.friends);
            if (friendIds?.length) {
                friendIds.forEach(id => {
                    sendNewMessageBroadcast(id);
                });
            }
        }
    }).catch((error) => {
        console.log('getLoggedInUserData= error', error);
    })
}

////////////////////////////////
//update one signal device id
export function updateOneSignalDeviceId(deviceId, isRemove) {
    let UID = firebase.auth()?.currentUser?.uid;
    return new Promise((resolve, reject) => {
        db.doc(`users/${UID}`)
            .update({ onesignal_device_ids: !isRemove ? firebase.firestore.FieldValue.arrayUnion(deviceId) : firebase.firestore.FieldValue.arrayRemove(deviceId) })
            .then(doc => {
                // console.log("aa -----updateOneSignalDeviceId--- doc=>", doc);
                resolve();
            }).catch(error => {
                // console.log("aa -----updateOneSignalDeviceId--- error=>", error);
                let newErr = { message: error.message }
                if (error.code === 'firestore/permission-denied') {
                    newErr.message = "firebase_user_blocked_account";
                    reject(newErr)
                } else {
                    reject(newErr)
                }
            });
    });
}

export function sendNotification(receiverId, contents) {
    let UID = firebase.auth()?.currentUser?.uid;
    getUserData(UID).then((data) => {
        if (data) {
            let payload = {
                isNotiRead: false,
                notification_type: 'CHAT_MESSAGE',
                fullName: data?.fullName,
                email: data?.email,
                profileImageUrl: data?.profileImageUrl,
                notiText: contents
            };
            createNotification(receiverId, contents, payload);
        }
    }).catch((error) => {
        console.log('getLoggedInUserData= error', error);
    })
}

//  create notification  for notification screen
function createNotification(receiverId, contents, payload) {
    //  console.log("aa ------- receiverId=>", receiverId);
    //  console.log("aa ------- contents=>", contents);
    //  console.log("aa ------- payload=>", payload);
    payload.sentAt = new Date();
    payload.senderId = firebase.auth()?.currentUser?.uid;
    db.collection('NOTIFICATIONS').doc('USERS').collection(`${receiverId}`).add(payload).then(docRef => {
        // console.log("aa -------- docRef?.id=>", docRef?.id);
        getUserData(receiverId).then(response => {
            console.log('aa ----- createNotification response.onesignal_device_ids=>', response.onesignal_device_ids);
            if (response.onesignal_device_ids && response?.settings?.pushNotification) {
                payload.notiDocRefId = docRef?.id;
                callSendOneSignalNotification(response.onesignal_device_ids, contents, payload);
            }
        })
    }).catch(error => {
        console.log('createNotification error======', error);
    });
}

/**
 * getMediaUrl
 */
export const getMediaUrl = (path) => {
    return new Promise((resolve, reject) => {
        firebase.storage().ref(`${path}`).getDownloadURL().then((url) => {
            resolve(url);
        }).catch((error) => {
            reject(error);
        });
    });
};