import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './video-preview.scss';

import CloseIcon from '../assets/images/cropper/ic_remove.svg';
import { ReactComponent as VideoPlayIcon } from '../assets/images/ic_playIcon.svg';
import  DummyThumbnail from '../assets/images/dummy_thumbnail.png'
import { getMediaUrl } from '../utils/firebase_helper/firestore';

class VideoPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            src: '',
            thumb: '',
        }
    }

    componentDidMount() {
        this.setState({src: this.props.src});
        this.getThumbnailPath();
    }

    getThumbnailPath = () => {
        getMediaUrl(this.props.thumb)
            .then((url) => {
                this.setState({thumb: url});                
                console.log('Thumbnail', url)
            })
            .catch((err) => {
                console.error(err);
            });
    };

    render() {
        const { src, thumb } = this.state;
        return(
            src
            ?   <div className="video-preview-wrapper">
                    <div className="header-view">
                        <div className="left-header-view">
                            <img
                                src={CloseIcon}
                                onClick={() => this.props.onCloseVideoPreview()}
                                className="close-icon"
                                alt="close-icon" />
                        </div>
                    </div>

                   <div className="video-container">
                        <ReactPlayer
                            style={{ marginTop: '12px' }}
                            width={"100 %"}
                            className='plr'
                            url={src}
                            controls
                            muted={false}
                            playing={true}
                            stopOnUnmount={false}
                            playIcon={<VideoPlayIcon />}
                            previewTabIndex={1}
                            light={thumb ? thumb : true}
                        />
                    </div>    
                </div>
            :   null
        );
    }
}

export default VideoPreview;