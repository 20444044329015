import React from 'react';
import UserProfile from '../components/user-profile';

function UserProfilePage(props) {
  
  return (
    <UserProfile />
  );
}

export default UserProfilePage;