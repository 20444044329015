import { AUTH_DATA, USER_DATA, LOGIN_USER_DATA, OTHER_USER_DATA, FILTER_DATA, CHAT_USER_DATA, SEARCH_DATA } from "./types";

export const saveAuthData = (data) => {
  return (dispatch) => {
    // console.log(data)
    dispatch({ type: AUTH_DATA, payload: data });
  };
};

export const saveLoginUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_USER_DATA, payload: data });
  };
};

export const saveUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: data });
  };
};

export const saveOtherUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: OTHER_USER_DATA, payload: data });
  };
};

export const saveFilterData = (data) => {
  return (dispatch) => {
    dispatch({ type: FILTER_DATA, payload: data });
  }
}
export const saveSearchData = (data) => {
  return (dispatch) => {
    dispatch({ type: SEARCH_DATA, payload: data });
  }
}

export const saveChatUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: CHAT_USER_DATA, payload: data });
  }
}