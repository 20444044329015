import React, { Component } from 'react';
import { Modal, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './image-cropper.scss'

import CloseIcon from '../assets/images/cropper/ic_remove.svg';
import DropDownIcon from '../assets/images/cropper/ic_dropdown.svg';
import RotateIcon from '../assets/images/cropper/ic_rotate.svg';
import SelectedAscpectRatioIcon from '../assets/images/cropper/ic_aspect_ratio_selected.svg'


class ImageCropper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            src: null,
            currentRotate: 0,
            crop: {
              unit: '%',
              width: 30,
              aspect: 1/1, 
            },
            dropdownOpen: false,
            selectedAspectRatio: '1:1',
            ratioList: [
                {name: 'Free', id: 'Free'},
                {name: '1:1', id: '1:1', x:1, y:1 },
                {name: '4:5', id: '4:5', x:4, y:5},
                {name: '16:9', id: '16:9', x:16, y:9},
                {name: '3:2', id: '3:2', x:3, y:2},
                {name: '4:3', id: '4:3', x:4, y:3},
                {name: '4:6', id: '4:6', x:4, y:6},
                {name: '5:2', id: '5:2', x:5, y:2},
            ]
        }
    }

    componentDidMount() {
        this.setState({modal: true, src: this.props.src})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen === true && this.props.src) {
            
            let cropTemp = {...this.state.crop};
            cropTemp.unit = '%';
            cropTemp.width = 30;
            cropTemp.aspect = null;
            this.setState({modal: true, src: this.props.src, crop: cropTemp, currentRotate: 0, selectedAspectRatio: '1:1'})
        }
    }

    toggleDropdown = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen})
    }

    onOptionChange = (item) => {
        let cropTemp = {...this.state.crop};
        if(item.id !== 'Free'){
            cropTemp.aspect = item.x/item.y;
            this.setState({selectedAspectRatio: item.id, crop: cropTemp})
        } else {
            cropTemp.aspect = null;
            this.setState({selectedAspectRatio: item.id, crop: cropTemp})
        }
    }

    toggle = () => {
        this.setState({modal: !this.state.modal})
    };

    onRotate = () => {
        const { currentRotate } = this.state;
        if (true) {
            this.setState({currentRotate: currentRotate + 90})
        } else if (currentRotate === 360) {
            this.setState({currentRotate: 0})
        }
        this.onImageLoaded();
    }

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
        //   const croppedImageUrl = await this.getCroppedImg(
        //     this.imageRef,
        //     crop,
        //     'newFile.jpeg'
        //   );
        const croppedImageUrl = this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
              );
          this.setState({ croppedImageUrl });
          this.props.onCrop(croppedImageUrl);
          //console.log('on crop complete ==> ', croppedImageUrl)
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

            // As Base64 string
            const base64Image = canvas.toDataURL("image/jpeg");
            return base64Image;

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1
            );
        });
    }

    render() {
        const { modal, crop, src, currentRotate, ratioList, selectedAspectRatio } = this.state;
        return(
            // <Modal isOpen={modal} toggle={this.toggle} className={'image-crop-modal'} centered >
            //     <ModalBody>
                    <div className="cropper-view-wrapper">
                        <div className="header-view">
                            <div className="left-header-view">
                                <img
                                    src={CloseIcon}
                                    onClick={() => this.props.onCloseCropperView()}
                                    className="close-icon"
                                    alt="close-icon" />
                            </div>
                            <div className="right-header-view">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle >
                                        {selectedAspectRatio}
                                        <img
                                            src={DropDownIcon}
                                            className="dropdown-icon"
                                            alt="dropdown-icon" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem header>Select Ratio</DropdownItem>
                                        {ratioList.map((ratioItem, index) => {
                                            return(
                                                <DropdownItem 
                                                    key={`007${index}`}
                                                    className={selectedAspectRatio === ratioItem.id ? 'selected-item': ''}
                                                    onClick={() => this.onOptionChange(ratioItem)}
                                                    >
                                                    {ratioItem.name}
                                                        {selectedAspectRatio === ratioItem.id ?
                                                            <img
                                                                src={SelectedAscpectRatioIcon}
                                                                className="selected-item-icon"
                                                                alt="selected-item-icon" />
                                                        :   null}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                                <img
                                    src={RotateIcon}
                                    onClick={this.onRotate}
                                    className="rotate-icon"
                                    alt="close-icon" />
                            </div>
                        </div>
                        <div className="crop-container">
                            <ReactCrop
                                src={src}
                                crop={crop}
                                rotate={currentRotate}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </div> 
                    </div>
                    // <button onClick={this.onRotate}>Rotate Pic</button>
                // </ModalBody>
            // </Modal>
        );
    }
}

export default ImageCropper;