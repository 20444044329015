import React from 'react';
import ChatComponent from '../components/chat-module/chat_screen';

const Chats = props => {
    return (
        <div>
            <ChatComponent />
        </div>
    );
}
export default Chats;