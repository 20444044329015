import React from 'react';
import LoginComponent from '../components/user_registration/login';

const Login = props => {
    return (
        <div>
            <LoginComponent />
        </div>
    );
}
export default Login;