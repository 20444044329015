import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth';
import { updateReadStatusFB } from "../../../utils/firebase_helper/auth";
import { updateOneSignalDeviceId } from "./firebase_helper/firestore";
import { get, set } from "./local-storage-helper";

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;


const ONESIGNAL_APP_ID = 'b504b319-5cd8-495d-b683-680936038c4b';


export const userTappedProvideConsentButton = () => {
    OneSignal.provideUserConsent(true)
    console.log("logged in Onesignal");
}
export const userTappedRemoveConsentButton = () => {
    // Will initialize the SDK and register for push notifications
    OneSignal.provideUserConsent(false);
    console.log("logged out Onesignal");
}

/*
 * Method to init OneSignal
 * */
export async function initOneSignal() {
    OneSignal.init({
        appId: ONESIGNAL_APP_ID,
        // subdomainName: "VENTYOUR",
        requiresUserPrivacyConsent: true,
        autoResubscribe: true,
        // notificationClickHandlerMatch: 'exact',
        // notificationClickHandlerAction: 'navigate',
        // persistNotification: true,
    })
    
}

/*
 * Method to add Notification Opened Event Listener
 * @param: onOpened : callback method for Opened Event
 * */
export function addOpenedEventListener(navigation) {
    OneSignal.setNotificationOpenedHandler(data => {
        // console.log("aa ------- ");
        if (data.notification?.additionalData) {
            if (firebase.auth()?.currentUser?.uid) {
                if (data.notification?.additionalData?.notiDocRefId) {
                    updateReadStatusFB(data.notification?.additionalData?.notiDocRefId);
                }
                if (data.notification?.additionalData?.notification_type == "CHAT_MESSAGE") {
                    navigation?.navigate('Chat', {
                        senderData: {
                            userId: firebase.auth()?.currentUser?.uid
                        },
                        receiverData: {
                            userId: data.notification?.additionalData?.senderId,
                            fullName: data.notification?.additionalData?.fullName,
                            profileImageUrl: data.notification?.additionalData?.profileImageUrl
                        }
                    });
                }
            } else {
                //Invalid user
                console.log("Invalid User")
            }
        }
    });
}

/*
 * Method use to register OnesignalPlayerId at backend
 * */
export async function registerOnesignalPlayerId() {
    const deviceState = await OneSignal.getUserId();

    console.log('respnse-----------------1', deviceState);

    if (deviceState && navigator.onLine) {
        get("ONESIGNAL_PLAYER_ID", 0, (id) => {
            console.log("respnse-----------------2", id)
            if (!deviceState || deviceState != id) {
                updateOneSignalDeviceId(id, true);
            }
        })
        set("ONESIGNAL_PLAYER_ID", deviceState);
        updateOneSignalDeviceId(deviceState).then(response => {
            console.log('respnse-----------------3');
            //update playerId of other users if they used the same device before
            // updateOtherUsersPlayerId(deviceState.userId);
        }).catch(error => {
            console.log(error);
        })

    }
}

// Send notification to users from front end
export async function callSendOneSignalNotification(oneSignalDeviceIds, contents, payload) {
    if (oneSignalDeviceIds?.length) {
        const notificationObj = {
            app_id: ONESIGNAL_APP_ID,
            contents: { "en": contents },
            headings: { "en": payload.fullName },
            include_player_ids: oneSignalDeviceIds,
            data: { ...payload } // additional payload
        };
        const jsonString = JSON.stringify(notificationObj);
        postNotification(notificationObj);
        // OneSignal.sendTags(notificationObj, (success) => {
        //     console.log("Success:", success);
        // }, (error) => {
        //     console.log("callSendOneSignalNotification Error:", error);
        // });
    }
}

export async function postNotification(obj) {
    const response = await fetch('https://onesignal.com/api/v1/notifications', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
    })
    console.log(response);
}

export function clearNotifications() {
    OneSignal.clearOneSignalNotifications();
}