import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import closeIcon from '../../../assets/images/modal_cross.svg';
import { showSpinner } from '../../../utils/common_helper';
import { getUser, updateUser } from '../../../utils/firebase_helper/auth';
import { data } from '../../../utils/locales/data';
import './notification.scss';

let strings = new LocalizedStrings(data);

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;


class NotificationSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            pushNotificationCheck: true,
            emailNotificationCheck: false,
            userDetails: null,
        }
    }

    componentDidMount() {
        // this.getUserDetails();
        if (this.props.authData) {
            console.log('this.props.authData  cccc==> ', this.props.authData);
            this.getUserDetails();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.modalStatus !== prevProps.modalStatus && this.props.modalStatus === true) {
            // this.getUserDetails();
        }
        if (this.props.authData != prevProps.authData) {
            this.getUserDetails();
        }

    }

    getUserDetails = () => {
        getUser(this.props.authData.userId).then(res => {
            if (res) {
                this.setState({ userDetails: res, pushNotificationCheck: res.settings.pushNotification, emailNotificationCheck: res.settings.emailNotification })
            }
        }).catch(error => {
            console.log('setting error ==> ', error)
        })
    }

    updateUserNotificationSettings = (payload) => {
        const { userDetails } = this.state;
        updateUser(payload, this.props.authData.userId).then(res => {
            if (res) {
                this.setState({ userDetails: res, pushNotificationCheck: res.settings.pushNotification, emailNotificationCheck: res.settings.emailNotification })
                this.getUserDetails();
                OneSignal.setSubscription(res.settings.pushNotification)

                // Occurs when the user's subscription changes to a new value.
                // OneSignal.isPushNotificationsEnabled().then((isEnabled) =>{
                //     if (isEnabled)
                //       console.log("Push notifications are enabled!");
                //     else
                //       console.log("Push notifications are not enabled yet.");      
                //   });

                // This event can be listened to via the `on()` or `once()` listener.


            }
        }).catch(error => {
            console.log('setting update error ==> ', error)
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.modal !== nextProps.modalStatus) {
            return {
                modal: nextProps.modalStatus,
            };
        }
    }

    toggle = () => {
        if (this.state.modal) {

        }
        if (this.state.modal === true) {
            this.props.onClose();
        }
        this.setState({ modal: !this.state.modal });
    }

    handleNotificationChange = (event, name) => {
        if (name === 'pushNotificationCheck') {
            this.setState({ pushNotificationCheck: event.target.checked })
            let payload = {
                settings: {
                    pushNotification: event.target.checked,
                    emailNotification: this.state.emailNotificationCheck
                }
            }
            this.updateUserNotificationSettings(payload);
        } else {
            this.setState({ emailNotificationCheck: event.target.checked })
            let payload = {
                settings: {
                    emailNotification: event.target.checked,
                    pushNotification: this.state.pushNotificationCheck,
                }
            }
            this.updateUserNotificationSettings(payload);
        }

    }

    NotificationSwitch = withStyles((theme) => ({
        root: {
            width: 32,
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            transform: 'translateX(24px)',
            '&$checked': {
                transform: 'translateX(36px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#00B5FD !important',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#00B5FD !important',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: 20 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
                checked={props.checked}
                onChange={(e) => this.handleNotificationChange(e, props.name)}
                name="pushNotificationCheck"
            />
        );
    });

    render() {
        return (
            <div>
                {showSpinner(this.state.showLoader)}
                {/* <Button color="danger" onClick={this.toggle}>Change Password</Button> */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} centered backdrop='static' className="change-pwd-modal" >
                    {/* <ModalHeader className="change-pwd-modal-header" toggle={this.toggle}>Change Password</ModalHeader> */}
                    <ModalBody>
                        <div className="modal-custom-header">
                            <div className="heading-text">{strings.notifications}</div>
                            <div className="close-icon" onClick={this.toggle}>
                                <img src={closeIcon} alt="close-icon" />
                            </div>
                        </div>

                        <div className="settings-list-container">
                            <div className="setting-item">
                                <div className="item-name">{strings.push_notifications}</div>
                                <div className="item-action">
                                    <this.NotificationSwitch checked={this.state.pushNotificationCheck} name="pushNotificationCheck" />
                                </div>
                            </div>

                            {/* <div className="setting-item">
                                <div className="item-name">{strings.email_notifications}</div>
                                <div className="item-action">
                                    <this.NotificationSwitch checked={this.state.emailNotificationCheck} name="emailNotificationCheck" />
                                </div>
                            </div> */}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

/* *Set data to store.* */
const mapDispatchToProps = (dispatch) => { }
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings)