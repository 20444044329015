import React, { Component } from "react";
import defaultUserProfilePic from "../../assets/images/ic_default_profile_pic.svg";
import defaultUserProfilePicSquare from "../../assets/images/ic_default_profile_pic_square.svg";
import { getMediaUrl } from "../../utils/firebase_helper/auth";
import "./firebaseImage.scss";

export default class FirebaseImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      path: '',
      className: props.className,
      src: props.src
    }
    this.getPath(props.src);
  }

  componentWillReceiveProps(props) {
    // console.log("aa ---------- src=>", props.src);
    if (props.src != this.state.src) {
      this.state.src = props.src;
      this.getPath(props.src);
    }
  }

  componentDidMount() {
    this.state.isMount = true;
  }

  componentWillUnmount() {
    this.state.isMount = false;
  }

  // const [path, setPath] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  getPath = (src) => {
    // setIsLoading(true);
    // console.log("aa ---------- src=>", src);
    if (!src) {
      if (this.state.isMount) {
        this.setState({ path: '', isLoading: false })
      }
      return;
    }
    getMediaUrl(src).then((data) => {
      if (this.state.isMount) {
        // console.log("aa --------- data.path=>", data.path);
        // console.log("aa --------- this.state.src=>", this.state.src);
        if (data.path == this.state.src) {
          this.setState({ path: data.url, isLoading: false })
        }
      }
      // setPath(url);
      // setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      if (this.state.isMount) {
        this.setState({ path: '', isLoading: false })
      }
    });
  }

  render() {
    return (
      <img
        className={`profile-pic  ${this.state.className}`}
        alt="profile-pic"
        src={this.state.path ? this.state.path : this.props.type && this.props.type === "square" ? defaultUserProfilePicSquare : defaultUserProfilePic}
      />
    );
  }
}