import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import OtpInput from "react-otp-input";
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { saveAuthData, saveLoginUserData } from '../../../actions/auth';
import { showContactNumber, showSpinner, showToast } from '../../../utils/common_helper';
import { secToMinutes } from "../../../utils/date-time-helper";
import { auth, getUser } from '../../../utils/firebase_helper/auth';
import { data } from '../../../utils/locales/data';
import { userTappedProvideConsentButton } from '../../../components/chat-module/utils/one-signal-helper';
import LogoBlack from '../../../assets/logo_black.svg';
import { Hidden } from '@material-ui/core';
import '../auth.scss';
let strings = new LocalizedStrings(data);

class VerificationCode extends Component {
    constructor(props) {
        super(props)

        this.state = {
            otpValue: '',
            otp: '',
            number: '',
            timer: 30,
            defaultTime: 30,
            otpError: false,
            showAlert: false,
            isFromSignup: props?.location?.state?.isFromSignup,
            requestParam: props?.location?.state?.requestParam,
            isSubmitted: false,
            hasErrored: false,
            isRedirect: false,
            userId: '',
        }
    }

    handleChange = (enteredOtp) => {
        this.setState({ otp: enteredOtp });
    };

    configureCaptcha = (cls) => {
        // setTimeout(() => {
        window.recaptchaVerifier = new auth.RecaptchaVerifier(cls, {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.onSignInSubmit();
            },
        });
        // }, 2000);
    }

    resendOtp = () => {
        this.setState({ showLoader: true });
        let number = `${this.state.requestParam?.selectedCountry?.code}${this.state.requestParam?.phoneNo}`;
        console.log(number)
        this.configureCaptcha('resend-otp-button')
        const appVerifier = window.recaptchaVerifier;
        // LoginInWithPhoneNumber(phoneNumber, appVerifier)
        // auth().setPersistence(auth.Auth.Persistence.LOCAL);
        auth().signInWithPhoneNumber(number, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.log("confirm1", window.confirmationResult)
                this.setState({ showLoader: false });
                showToast(strings.otp_success);
            }).catch((error) => {
                this.setState({ showLoader: false });
                showToast(error.message);
            });
    };


    onSubmitOTP = (payload) => {
        const code = payload.otp
        this.setState({ showLoader: true });
        window.confirmationResult.confirm(code)
            .then((result) => {
                // User signed in successfully.
                if (result?.user?.uid) {
                    this.setState({ userId: result?.user?.uid });
                    getUser(result?.user?.uid).then(res => {
                        console.log('Get user response - ', res, 'For user Id - ', result?.user?.uid);
                        if (res?.fullName) {
                            console.log('Home')
                            userTappedProvideConsentButton();
                            this.props.saveAuthData(res);
                            this.setState({ showLoader: false });
                            this.props.history.push({ pathname: "/home" });
                        } else {
                            console.log('SignUp')
                            const body = {
                                contactNo: this.state.requestParam.phoneNo,
                                selectedCountry: this.state.requestParam.selectedCountry
                            }
                            this.props.saveLoginUserData(body);
                            this.setState({ showLoader: false, isRedirect: true });
                        }
                    })
                        .catch(error => {
                            if (error.code === 'auth/invalid-verification-code') {
                                showToast("Invalid verification code entered.");
                            } else {
                                showToast(error.message);
                            }
                            this.setState({ showLoader: false });
                        })
                }
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                this.setState({ showLoader: false });
                if (error.code === 'auth/invalid-verification-code') {
                    showToast("Invalid verification code entered.");
                } else {
                    showToast(error.message);
                }
            });
    }

    handleClick = (e) => {
        e.preventDefault()
        console.log(this.state.requestParam);
        if (this.state.otp && this.state.otp.length === 6) {
            let number = `${this.state.requestParam?.selectedCountry?.code}${this.state.requestParam?.phoneNo}`;
            const payload = {
                phoneNumber: number,
                otp: this.state.otp,
            };
            this.onSubmitOTP(payload);
            this.setState({ hasErrored: false });
        } else {
            console.log('Here')
            this.setState({ hasErrored: true });
        }
    };

    componentDidMount() {
        console.log("isFromSignup", this.props);
        console.log(this.props.location.state.requestParam)
    }

    setTimer = () => {
        this.setState({ timer: this.state.defaultTime }, () => {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.interval = setInterval(() => {
                this.setState((prevState) => ({ timer: prevState.timer - 1 }));
            }, 1000);
        })
    }

    componentWillMount = () => {
        this.setTimer();
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    componentDidUpdate = () => {
        if (this.state.timer === 0) {
            clearInterval(this.interval);
        }
    }

    render() {
        return (
            <div className="common-auth-wrapper">
                {showSpinner(this.state.showLoader)}
                <Hidden xsDown>
                    <Row>
                        <Col className="bg-image" >
                            <div className="logo"></div>
                        </Col>
                        <Col className="common-reg-wrapper">
                            <div className="form-wrapper">
                                {strings.getLanguage() ?
                                    <>
                                        <p className="title">{strings.verification_code}</p>
                                        <p className="subtext" style={{ marginBottom: '20px', marginTop: 10 }}>{strings.verification_code_text}
                                            {' '}
                                            <span className="number">{showContactNumber(this.state.requestParam?.phoneNo, this.state.requestParam?.selectedCountry.code)}</span></p>
                                    </>
                                    : <></>
                                }
                                <form autoComplete="off" onSubmit={this.handleClick}>
                                    <div className="text-item" style={this.state.hasErrored ? { marginBottom: '5px' } : { marginBottom: '13px' }} >
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={this.handleChange}
                                            numInputs={6}
                                            containerStyle="otp-container"
                                            focusStyle="on-focus"
                                            errorStyle="otp-invalid"
                                            isInputNum
                                            shouldAutoFocus
                                            name="otpValue"
                                            inputStyle="otp-input"
                                            hasErrored={this.state.hasErrored}
                                        />
                                    </div>
                                    {this.state.hasErrored ?
                                        <div className="otp-error-msg">
                                            {this.state.otp == 0 ? strings.error_code : `${strings.error_invalid_code}`}
                                        </div>
                                        : null}

                                    <div className="links" style={{ fontWeight: '500', textAlign: 'left', marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            {this.state.timer ? <p style={{ margin: 0 }}>{secToMinutes(this.state.timer)}</p> : null}
                                        </div>
                                        <div style={{ textTransform: 'capitalize', margin: 0, cursor: 'pointer', fontWeight: '500' }}
                                            className={`link-text ${(this.state.timer != 0) ? "disable-link" : ""}`}
                                            onClick={this.state.timer != 0 ? (e) => e.stopPropagation() : () => this.resendOtp()}>{strings.getLanguage() ? strings.resend_code : ''}</div>
                                    </div>

                                    <div className="text-item">
                                        <Button type="submit" disabled={!(this.state.otp)} className="common-button signup-button">{strings.getLanguage() ?
                                            strings.submit : ''}</Button>{' '}
                                        <p className="backtext">{strings.getLanguage() ? strings.backto_link_text : ''}
                                            &nbsp;<Link className="login-text" to="/">{strings.getLanguage() ? strings.login : ''}</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Hidden>
                <Hidden smUp>
                    <Col className="common-reg-wrapper">
                        <div className="form-wrapper">
                            <img alt='logo' src={LogoBlack} />
                            {strings.getLanguage() ?
                                <>
                                    <p className="title">{strings.verification_code}</p>
                                    <p className="subtext" style={{ marginBottom: '20px', marginTop: 10 }}>{strings.verification_code_text}
                                        {' '}
                                        <span className="number">{showContactNumber(this.state.requestParam?.phoneNo, this.state.requestParam?.selectedCountry.code)}</span></p>
                                </>
                                : <></>
                            }
                            <form autoComplete="off" onSubmit={this.handleClick}>
                                <div className="text-item" style={this.state.hasErrored ? { marginBottom: '5px' } : { marginBottom: '13px' }} >
                                    <OtpInput
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                        numInputs={6}
                                        containerStyle="otp-container"
                                        focusStyle="on-focus"
                                        errorStyle="otp-invalid"
                                        isInputNum
                                        shouldAutoFocus
                                        name="otpValue"
                                        inputStyle="otp-input"
                                        hasErrored={this.state.hasErrored}
                                    />
                                </div>
                                {this.state.hasErrored ?
                                    <div className="otp-error-msg">
                                        {this.state.otp == 0 ? strings.error_code : `${strings.error_invalid_code}`}
                                    </div>
                                    : null}

                                <div className="links" style={{ fontWeight: '500', textAlign: 'left', marginBottom: '20px', marginRight: '30px', marginLeft: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        {this.state.timer ? <p style={{ margin: 0 }}>{secToMinutes(this.state.timer)}</p> : null}
                                    </div>
                                    <div style={{ textTransform: 'capitalize', margin: 0, cursor: 'pointer', fontWeight: '500' }}
                                        className={`link-text ${(this.state.timer != 0) ? "disable-link" : ""}`}
                                        onClick={this.state.timer != 0 ? (e) => e.stopPropagation() : () => this.resendOtp()}>{strings.getLanguage() ? strings.resend_code : ''}</div>
                                </div>

                                <div className="text-item">
                                    <Button type="submit" disabled={!(this.state.otp)} className="common-button signup-button">{strings.getLanguage() ?
                                        strings.submit : ''}</Button>{' '}
                                    <p className="backtext">{strings.getLanguage() ? strings.backto_link_text : ''}
                                        &nbsp;<Link className="login-text" to="/">{strings.getLanguage() ? strings.login : ''}</Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Hidden>
                <div id='resend-otp-button'></div>
                {this.state.isRedirect ?
                    <Redirect to={{
                        pathname: "/signup", state: {
                            isFromSignup: true,
                            userId: this.state.userId,
                            requestParam: this.state
                        }
                    }}></Redirect> : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, { saveAuthData, saveLoginUserData })(VerificationCode)