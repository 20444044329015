import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import 'cropperjs/dist/cropper.css';
import React, { useEffect, useState, useRef } from 'react';
import Cropper from 'react-cropper';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveAuthData } from '../../actions/auth';
import AddCircleIcon from "../../assets/add_circle.svg";
import CrossIcon from "../../assets/cross_icon.svg";
import { ReactComponent as DownArrow } from "../../assets/down_arrow.svg";
import EditIcon from "../../assets/edit_icon.svg";
import ProfilePicIcon from "../../assets/profile_picture_icon.svg";
import RemoveIcon from "../../assets/remove_icon.svg";
import Tag from "../../assets/tag.svg";
import UploadIcon from "../../assets/upload_icon.svg";
import FirebaseVideo from '../../components/firebase_video_view';
import { showAlert as showAlertModal, showContactNumber, showSpinner } from '../../utils/common_helper';
import { roles } from '../../utils/constants';
import Emitter from '../../utils/Emitter';
import { getCategories, getUser, updateUser } from '../../utils/firebase_helper/auth';
import { deleteFile, fileType, uploadFileBase64 } from '../../utils/firebase_helper/store';
import { data } from '../../utils/locales/data';
import { updateUserDetailsBrodcast } from '../chat-module/utils/firebase_helper/firestore';
import FirebaseImage from "../firebase_fast_image_view";
import { validateEmail, validateWebsite, validatePassword, validateTextOnly } from '../../utils/input-validation';
// import InfoCard from '../Info-Card/index';
// import SubHeader from '../Sub-Header/index';
import "./user-details.scss";

const photoArray = ['First']

let strings = new LocalizedStrings(data);

const defaultCategory = { id: 1, name: 'Adtech' };

function UserProfile({ ...props }) {
    // const location = useLocation();
    // const history = useHistory();
    const [userDetails, setUserDetails] = useState(props?.authData);
    const [userId, setUserId] = useState(props?.authData?.userId);
    const [editBasicInfo, seteditBasicInfo] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setrole] = useState('');
    const [roleKey, setRoleKey] = useState('');
    const [website, setWebsite] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [imageSelected, setImageSelected] = useState(false);
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [openImageUpdate, setOpenImageUpload] = useState(false);
    const [selectedImageSrc, setSelectedImageSrc] = useState('');
    const [editBio, setEditBio] = useState(false);
    const [bio, setBio] = useState('');
    const [editVideo, setEditVideo] = useState(false);
    const [video, setVideo] = useState({})
    const [playVideo, setPlayVideo] = useState(false);
    const [selectedVideosrc, setSelectedVideosrc] = useState('');
    const [selectedVideoDuration, setSelectedVideoDuration] = useState(null)
    const [thumbnail, setThumbnail] = useState('');
    const [selectedVideoBase64, setSelectedVideoBase64] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
    const [categoryList, setCategoryList] = useState(null);
    const [businessName, setBusinessName] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [businessDetails, setBusinessDetails] = useState('');
    const [openEditBusiness, setOpenEditBuisness] = useState(false);
    // const [userData, setUserData] = useState(null);
    // const [userId, setUserId] = useState(null);
    const [image, setImage] = useState('');
    const [position, setPos] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const ref = useRef();

    const getDetails = () => {
        getUser(userId).then(res => {
            if (res) {
                console.log('Response:', res)
                setUserDetails(res);
                setName(res?.fullName);
                setEmail(res?.email);
                setrole(res?.role);
                setRoleKey(res?.roleKey)
                setWebsite(res?.website);
                setImageUrls(res?.imageUrls)
                setBio(res?.bio);
                setVideo(res?.videoUrl)
                setBusinessName(res?.businessInfo?.name);
                setBusinessAddress(res?.businessInfo?.location);
                setBusinessDetails(res?.businessInfo?.bio);
                props.saveAuthData(res);
                setIsLoading(false);
                // console.log(res?.videoUrl)
            }
        }).catch(error => {
            setIsLoading(false);
            setAlertMsg(error.message)
            setShowAlert(true);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        getDetails();
        getCategories().then(res => {
            const documents = res.docs.map(doc => doc.data());
            setCategoryList(documents);
        });
    }, [])


    const getFinalURL = (url) => {
        let trimUrl = url.toLowerCase().trim();
        if (trimUrl) {
            if (trimUrl.startsWith("https://")) {
                return trimUrl;
            } else {
                return 'https://' + trimUrl;
            }
        }

    }

    const resetVideoInput = () => {
        if (ref && ref.current) {
            ref.current.value = "";
        }
      };

    const handleClickOpen = (e) => {
        e.preventDefault();
        seteditBasicInfo(true);
    };

    const handleClose = () => {
        seteditBasicInfo(false);
        setName(userDetails?.fullName);
        setEmail(userDetails?.email);
        setrole(userDetails?.role);
        setRoleKey(userDetails?.roleKey);
        setWebsite(userDetails?.website);
        setErrors({});
    };

    const handleCropClose = () => {
        setImageSelected(false);
        setSelectedImageSrc('');
        setOpenImageUpload(false);
    }

    const closeAlerts = () => {
        setShowAlert(false);
    }

    const validate = () => {
        let temp = errors;
        let iserror = false;
        if(!validateTextOnly(name)){
            errors.fullName= 'Please enter valid name.';
            iserror = true;
        } else {
            errors.fullName = '';
        }
        if(!validateEmail(email)){
            errors.email= 'Please enter valid email address.';
            iserror = true;
        } else {
            errors.email = '';
        }
        if(!validateWebsite(website)){
            errors.website= 'Please enter valid website address.';
            iserror = true;
        } else {
            errors.website = '';
        }
        setErrors(temp);
        console.log(errors)
        return iserror;
    }

    const handleChange = (e) => {
        
        if (e.target.name === 'fullName') {
            setName(e.target.value)
        }
        if (e.target.name === 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name === 'role') {
            const data = roles.find(o => o.name === e.target.value);
            setrole(data?.name)
            setRoleKey(data?.id)
        }
        if (e.target.name === 'website') {
            setWebsite(e.target.value)
        }
        if (e.target.name === 'bio') {
            setBio(e.target.value);
        }
        if (e.target.name === 'name') {
            setBusinessName(e.target.value);
        }
        if (e.target.name === 'location') {
            setBusinessAddress(e.target.value);
        }
        if (e.target.name === 'bbio') {
            setBusinessDetails(e.target.value);
        }
    }

    const updateBasic = (e) => {
        setIsLoading(true);
        if(validate()) {
            setIsLoading(false);
            return;
        }
        const body = {
            fullName: name,
            role,
            roleKey,
            website,
            email,
        }
        updateUser(body, userId).then(res => {
            if (res) {
                setUserDetails(res);
                props.saveAuthData(res);
                updateUserDetailsBrodcast();
                setIsLoading(false);
                seteditBasicInfo(false);
                Emitter.emit('VENTYOUR_USER_UPDATE', 1);
            }
        }).catch(err => {
            setIsLoading(false);
            setAlertMsg(err.message)
            setShowAlert(true);
        });
    }

    const getNumber = () => {
        const data = userDetails?.contactNo.split('-');
        return data[1];
    }
    const renderBasicInfo = () => {
        return (
            <Dialog open={editBasicInfo} onClose={handleClose}>
                <DialogTitle>{strings.BASIC_INFO}</DialogTitle>
                <DialogContent style={{ paddingRight: '20px' }}>
                    <Grid container className="info-container">
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <TextField className="text-field" onChange={(e) => handleChange(e)}
                                    error={errors.fullName}
                                    id="fullName"
                                    name="fullName"
                                    label={strings.full_name}
                                    type="text"
                                    variant="outlined"
                                    value={name}
                                    helperText={errors.fullName}
                                    InputLabelProps={{ shrink: name }}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="row-div">
                                <TextField className="text-field" style={{ paddingRight: '0 !important' }} onChange={(e) => handleChange(e)}
                                    error={errors.email}
                                    id="email"
                                    name="email"
                                    label={strings.email}
                                    type="email"
                                    variant="outlined"
                                    value={email}
                                    helperText={errors.email}
                                    InputLabelProps={{ shrink: email }}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div text-field">
                                <FormControl variant="outlined" className="role-dropdown" error={errors.role}>
                                    {/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
                                    <InputLabel id="demo-simple-select-filled-label">{strings.role}</InputLabel>
                                    <Select onChange={(e) => handleChange(e)}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="role"
                                        value={role}
                                        MenuProps={{
                                            getContentAnchorEl: () => null,
                                        }}
                                        IconComponent={() => (
                                            <SvgIcon style={{ paddingBottom: 3, marginRight: 10 }}>
                                                <DownArrow />
                                            </SvgIcon>
                                        )}>
                                        {roles.map((option) => (
                                            <MenuItem className="roles-option" key={option.id} value={option.name} selected>
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} className="row-div">
                                <TextField className="text-field disable-field"
                                    style={{ width: '30%', paddingRight: 5 }}
                                    disabled
                                    id="code"
                                    name="countryCode"
                                    label={strings.getLanguage() ? strings.country_code : ''}
                                    type="text"
                                    variant="outlined"
                                    value={userDetails?.countryCode ?? ''}
                                    inputProps={{ className: 'text-field-disabled' }}
                                    InputLabelProps={{ shrink: userDetails?.countryCode }}
                                    InputProps={{
                                        endAdornment: <SvgIcon style={{ paddingBottom: 3 }}>
                                            <DownArrow />
                                        </SvgIcon>
                                    }}
                                />
                                <TextField className="text-field disable-field"
                                    style={{ width: '70%', paddingLeft: 5, paddingRight: '0 !important' }}
                                    disabled
                                    id="phone"
                                    name="contactNo"
                                    label={strings.getLanguage() ? strings.phoneNo : ''}
                                    type="text"
                                    variant="outlined"
                                    value={getNumber() ?? ''}
                                    inputProps={{ className: 'text-field-disabled' }}
                                    InputLabelProps={{ shrink: userDetails?.contactNo }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <TextField className="text-field" onChange={(e) => handleChange(e)}
                                    error={errors.website}
                                    id="website"
                                    name="website"
                                    label={strings.website}
                                    type="text"
                                    variant="outlined"
                                    value={website}
                                    helperText={errors.website}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: website }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingRight: '20px', paddingBottom: '20px' }}>
                    <Button className="cancel-btn" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color={'#00B5FD'} className="save-btn" onClick={(e) => updateBasic(e)}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleImageUpload = () => {
        setOpenImageUpload(true);
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    let cropper = null;
    const onCropperInit = (crop) => {
        cropper = crop;
    }

    const _crop = () => {
        // image in dataUrl
        console.log(cropper.getCroppedCanvas().toDataURL());
    }

    const handleSetAsProfile = () => {
        let imageUrls = userDetails?.imageUrls;
        let profileImageUrl = userDetails?.profileImageUrl;
        imageUrls.map((url, index) => {
            if (position === index) {
                url.isProfile = true;
                profileImageUrl = url.url;
            } else {
                url.isProfile = false;
            }
        });
        userDetails.profileImageUrl = profileImageUrl;
        userDetails.imageUrls = imageUrls;
        updateUser(userDetails, userId).then(res => {
            setUserDetails(userDetails);
            updateUserDetailsBrodcast();
            setImageUrls(imageUrls);
            props.saveAuthData(res);
            handlePreviewClose();
            setIsLoading(false);
            Emitter.emit('VENTYOUR_USER_UPDATE', 1);
        }).catch(err => {
            setIsLoading(false);
            setAlertMsg(err.message)
            setShowAlert(true);
        })
    }

    const handleCropSave = () => {
        setIsLoading(true);
        setSelectedImageSrc(cropper.getCroppedCanvas().toDataURL())
        uploadFileBase64(
            cropper.getCroppedCanvas().toDataURL(),
            '',
            fileType.IMAGE_JOB,
            userId,
            'jpg',
        ).then(res => {
            let imageUrls = [];
            let body = {
                url: res.metadata.fullPath,
                isProfile: false,
            };
            if (userDetails?.imageUrls?.length > 0) {
                imageUrls = userDetails.imageUrls;
                imageUrls.push(body);
            } else {
                body.isProfile = true;
                imageUrls.push(body);
                userDetails.profileImageUrl = res.ref;
            }
            userDetails.imageUrls = imageUrls;
            updateUser(userDetails, userId).then(res => {
                setUserDetails(userDetails);
                props.saveAuthData(userDetails);
                updateUserDetailsBrodcast();
                setImageUrls(imageUrls);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                setAlertMsg(err.message)
                setShowAlert(true);
            })
        }).catch(err => {
            setIsLoading(false);
            setAlertMsg(err.message)
            setShowAlert(true);
        });
        setImageSelected(false);
        setOpenImageUpload(false);
    }

    const deleteImage = (pos, e) => {
        e.stopPropagation();
        const url = userDetails?.imageUrls[pos];
        let temp = userDetails;
        setIsLoading(true);
        if (pos !== -1) {
            temp?.imageUrls?.splice(pos, 1);
            if (temp?.imageUrls?.length > 0) {
                if (url?.isProfile) {
                    temp.imageUrls[0].isProfile = true;
                    temp.profileImageUrl = temp.imageUrls[0]?.url;
                }
            } else {
                temp.profileImageUrl = '';
            }
        }
        const body = {
            profileImageUrl: temp?.profileImageUrl,
            imageUrls: temp?.imageUrls,
        };
        updateUser(body, temp?.userId).then(
            res => {
                deleteFile(url?.url).then(
                    res => {
                        setUserDetails(temp)
                        props.saveAuthData(temp);
                        updateUserDetailsBrodcast();
                        handlePreviewClose();
                        setIsLoading(false);

                    },
                    err => {
                        setIsLoading(false);
                        setAlertMsg(err.message)
                        setShowAlert(true);

                    },
                );
            },
            err => {
                setIsLoading(false);
                setAlertMsg(err.message)
                setShowAlert(true);
            },
        );
    };

    const renderImageCropper = () => {
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={openImageUpdate}
                onClose={handleCropClose}
                TransitionComponent={Transition}
            >
                <AppBar className="appbar" sx={{ position: 'relative' }}>
                    <Toolbar style={{ justifyContent: 'center' }}>
                        <Button style={{ marginRight: '34px' }} className="cancel-btn" onClick={handleCropClose}>Cancel</Button>
                        <Button className='save-btn' onClick={() => handleCropSave()}>
                            {strings.save}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div>
                    <Cropper
                        src={selectedImageSrc}
                        style={{ height: '75vh', width: '100%' }}
                        // Cropper.js options
                        dragMode='move'
                        background={false}
                        initialAspectRatio={1}
                        guides={true}
                        crop={_crop.bind(this)}
                        onInitialized={onCropperInit.bind(this)}
                    />
                </div>
            </Dialog>
        )
    }

    const handlePreviewOpen = (url, pos) => {
        setOpenImagePreview(true);
        setImage(url);
        setPos(pos);
    }

    const handlePreviewClose = () => {
        setImage('');
        setPos(0);
        setOpenImagePreview(false);
    }


    const renderImagePreview = () => {
        return (
            <>
                <Dialog
                    open={openImagePreview}
                    onClose={handlePreviewClose}
                    TransitionComponent={Transition}
                >
                    <DialogTitle style={{ padding: 0 }}>
                        <AppBar className="appbar" sx={{ position: 'relative' }}>
                            <Toolbar style={{ justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
                                    <Button style={{ marginRight: '34px' }} className="cancel-btn" onClick={(e) => deleteImage(position, e)}>Delete</Button>
                                    <Button className='save-btn-preview' onClick={() => handleSetAsProfile()}>
                                        {strings.default}
                                    </Button>
                                </div>
                                <img style={{ cursor: 'pointer' }} src={CrossIcon} onClick={handlePreviewClose}></img>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                    <DialogContent style={{ padding: 0 }}>
                        <div style={{ maxHeight: 600, maxWidth: 600 }}>
                            <FirebaseImage
                                src={image}
                                className={"profile-img-preview"}
                                type={'square'}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setIsLoading(true);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImageSelected(true);
                setIsLoading(false);
                setSelectedImageSrc(reader.result);
                setOpenImageUpload(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleProfileUpload = () => {
        let input = document.getElementById('profile_image');
        input.click();
    }

    const handleBioClose = () => {
        setBio('');
        setEditBio(false);
    }

    const renderAddBio = () => {
        return (
            <Dialog open={editBio} onClose={handleBioClose}>
                <DialogTitle style={{ paddingRight: '10px', fontSize: '1.15rem' }}>{strings.bio}  
                    <img className={"cross-icon"} onClick={handleBioClose} src={CrossIcon} />
                </DialogTitle>
                <DialogContent className='bio-dialog' style={{ paddingRight: '10px' }}>
                    <TextField className="text-field bio-text" onChange={(e) => handleChange(e)}
                        error={errors.bio}
                        id="bio"
                        name="bio"
                        type="text"
                        multiline
                        minRows={10}
                        maxRows
                        variant="outlined"
                        value={bio}
                        helperText={errors.bio}
                        inputProps={{ maxLength: 500 }}
                    />
                </DialogContent>
                <DialogActions style={{ paddingRight: '20px', paddingBottom: '20px' }}>
                    <Button className="cancel-btn" onClick={handleBioClose}>Cancel</Button>
                    <Button variant="contained" color={'#00B5FD'} className="save-btn" onClick={() => updateBio()}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const updateBio = () => {
        setIsLoading(true);
        const body = {
            bio,
        }
        console.log('Here', body, userId)
        updateUser(body, userId).then(res => {

            if (res) {

                console.log('Here', res)
                setUserDetails(res);
                props.saveAuthData(res);
                setIsLoading(false);
                setEditBio(false);
            } else {
                console.log(res)
            }
        }).catch(err => {
            setIsLoading(false);
            setAlertMsg(err.message)
            setShowAlert(true);
        });
    }

    const handleVideoUpload = () => {
        let input = document.getElementById('video_image');
        input.click();
    }

    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                const thumb = canvas.toDataURL("image/png");
                console.log(thumb)
                uploadFileBase64(
                    thumb,
                    '',
                    'THUMBNAILS',
                    userDetails?.userId,
                    'jpg',
                ).then(res => {
                    return resolve(res.metadata.fullPath);
                })
                    .catch(err => {
                        console.log(err)
                        return resolve('');
                    });
            };
        });
    };

    window.URL = window.URL || window.webkitURL;
    const getDuration = (file) => {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            setSelectedVideoDuration(parseFloat(video.duration).toFixed(2))
            if(parseFloat(video.duration).toFixed(2) <= 60) {
                startProcessVideo(file);
            } else {
                setAlertMsg(strings.video_duration_above_60_sec)
                setShowAlert(true);
                setIsLoading(false);
                resetVideoInput();
            }
        }
        video.src = URL.createObjectURL(file);
    }

    const onSelectVideo = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setIsLoading(true);
            let file = e.target.files[0];
            let duration = getDuration(file);
            if (file && file.type && file.type.includes('video')) {
                getDuration(file);
            } else {
                setAlertMsg(strings.video_type_error)
                setShowAlert(true);
                setIsLoading(false);
            }
        }
    };

    const startProcessVideo = (file) => {
        generateVideoThumbnail(file).then(thumb => {
            console.log('Firebase thumb', thumb);
            setThumbnail(thumb);
            setSelectedVideosrc(URL.createObjectURL(file));
            setSelectedVideoBase64(file);
            setIsLoading(false);
        });
    }

    const handleEditVideoClose = () => {
        setEditVideo(false);
        setSelectedVideosrc('');
        setSelectedVideoBase64('');
        resetVideoInput();
    }

    const handleSelectCategory = (e) => {
        console.log(e.target.value);
        const index = categoryList.filter((category) => {
            console.log(category.name)
            return category.name.toLowerCase() === e.target.value.toLowerCase();
        });
        console.log(index)
        setSelectedCategory(index[0]);
    }

    const handleUploadVideo = (e) => {
        setIsLoading(true);
        uploadFileBase64(
            selectedVideoBase64,
            '',
            'VIDEO',
            userDetails?.userId,
            'mp4',
        ).then(res => {
            let videoUrl = [];
            let videoData = {
                isDefault: true,
                videoThumbnailUrl: thumbnail,
                videoUrl: res?.metadata?.fullPath,
            };
            videoUrl.push(videoData);
            const body = {
                videoUrl: videoUrl,
                categoryId: selectedCategory.id,
                categoryName: selectedCategory.name,
            };
            if (body.videoUrl !== userDetails?.videoUrl) {
                body.videoUploadedAt = new Date();
            }
            console.log('Here', body, userId)
            updateUser(body, userId).then(res => {
                if (res) {
                    console.log('Here', res)
                    setVideo(res?.videoUrl)
                    setUserDetails(res);
                    props.saveAuthData(res);
                    setIsLoading(false);
                    setEditVideo(false);
                    getDetails();
                } else {
                    console.log(res)
                }
            }).catch(err => {
                setIsLoading(false);
                setAlertMsg(err.message)
                setShowAlert(true);
            });
        })
    }

    const renderAddVideo = () => {
        return (
            <>
                <Dialog maxWidth='md' open={editVideo} onClose={handleEditVideoClose}>
                    <DialogContent className='video-dialog' style={{ paddingRight: '10px' }}>
                        <div className="video-header">{strings.short_video}</div>
                        <TextField className="text-field" //onChange={this.handleCodeChange}
                            //   style={{ paddingRight: 5 }}
                            select
                            id="catergory"
                            name="catergory"
                            label="Category"
                            // label={strings.getLanguage() ? strings.catergory : ''}
                            type="text"
                            variant="outlined"
                            onChange={(e) => handleSelectCategory(e)}
                            value={selectedCategory?.name}
                            SelectProps={{
                                IconComponent: ArrowIcon
                            }}
                        >
                            {categoryList.map((option) => (
                                <MenuItem autoFocus={selectedCategory?.id === option?.id} key={option.id} value={option.name}>
                                    {option?.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        {selectedVideosrc ? (
                            <FirebaseVideo
                                key={Math.random()}
                                src={selectedVideosrc}
                                from={'upload'}
                                index={1}
                                thumbnail={thumbnail ? thumbnail : null}
                            />
                        ) : (
                            <>
                                <div className="image-row-div upload-image-row-div" style={{ textAlign: 'center' }} onClick={() => handleVideoUpload()}>
                                    <input id="video_image" type="file" ref={ref} accept="video/mp4, video/x-m4v, video/*" style={{ display: 'none' }} onChange={(e) => onSelectVideo(e)}  />
                                    <img src={UploadIcon} />
                                    <div className='upload-text'>Upload</div>
                                </div>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ paddingRight: '20px', paddingBottom: '20px' }}>
                        <Button className="cancel-btn" onClick={handleEditVideoClose}>Cancel</Button>
                        <Button variant="contained" color={'#00B5FD'} onClick={() => handleUploadVideo()} className="save-btn" >Save</Button>
                    </DialogActions>
                </Dialog >
                {showSpinner(isLoading)}
            </>
        )
    }

    const handleVideoClose = () => {
        setPlayVideo(false);
    }

    const renderVideoPlayer = () => {
        return (
            <Dialog open={playVideo} onClose={handleVideoClose}>
                <DialogContent className='bio-dialog' style={{ paddingRight: '10px' }}>
                    <FirebaseVideo
                        src={video[0]?.videoUrl}
                        thumbnail={video[0]?.videoThumbnailUrl}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    const handleBusinessClose = () => {
        setOpenEditBuisness(false);
        setBusinessDetails(userDetails?.businessInfo?.bio || '');
        setBusinessAddress(userDetails?.businessInfo?.location || '');
        setBusinessName(userDetails?.businessInfo?.name || '');
    }

    const updateBusiness = () => {
        setIsLoading(true);
        if (businessDetails.length > 2 && businessName.length > 2 && businessAddress.length > 2) {
            const body = {
                businessInfo: {
                    bio: businessDetails,
                    name: businessName,
                    location: businessAddress,
                }
            };
            updateUser(body, userId).then(res => {
                if (res) {
                    console.log('Here11111', res)
                    setUserDetails(res);
                    props.saveAuthData(res);
                    setIsLoading(false);
                    setOpenEditBuisness(false);
                } else {
                    console.log(res);
                    setOpenEditBuisness(false);
                }
            }).catch(err => {
                setIsLoading(false);
                setAlertMsg(err.message)
                setShowAlert(true);
            });
        } else {
            setIsLoading(false);
            setAlertMsg('Atlest 3 characters are needed in all fields.')
            setShowAlert(true);
        }
    }

    const renderAddBusinessInfo = () => {
        return (
            <Dialog open={openEditBusiness} onClose={handleBusinessClose}>
                <DialogTitle>{strings.business_info}</DialogTitle>
                <DialogContent className='bio-dialog' style={{ paddingRight: '10px' }}>
                    <TextField className="text-field" onChange={(e) => handleChange(e)}
                        error={errors.bio}
                        id="name"
                        name="name"
                        type="text"
                        label={strings.business_name}
                        maxRows
                        variant="outlined"
                        value={businessName}
                        helperText={errors.bio}
                        inputProps={{ maxLength: 50 }}
                    />
                    <TextField className="text-field" onChange={(e) => handleChange(e)}
                        error={errors.bio}
                        id="location"
                        name="location"
                        type="text"
                        label={strings.business_location}
                        maxRows
                        variant="outlined"
                        value={businessAddress}
                        helperText={errors.bio}
                    // inputProps={{ maxLength: 50 }}
                    />
                    <TextField className="text-field business-details-text" onChange={(e) => handleChange(e)}
                        error={errors.bio}
                        id="details"
                        name="bbio"
                        type="text"
                        label={strings.business_details}
                        multiline
                        minRows={5}
                        maxRows
                        variant="outlined"
                        value={businessDetails}
                        helperText={errors.bio}
                        inputProps={{ maxLength: 500 }}
                    />
                </DialogContent>
                <DialogActions style={{ paddingRight: '20px', paddingBottom: '20px' }}>
                    <Button className="cancel-btn" onClick={handleBusinessClose}>Cancel</Button>
                    <Button variant="contained" className="save-btn" onClick={() => updateBusiness()}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div className="user-details-wrapper">
            {showSpinner(isLoading)}
            <div className="common-wrapper">
                <div className="page-header">
                    <div className="title">{strings.my_profile}</div>
                </div>
                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.BASIC_INFO}</div>
                        <div>
                            <IconButton
                                onClick={(e) => { handleClickOpen(e) }}>
                                <img src={EditIcon} />
                            </IconButton>
                        </div>
                    </div>
                    <Grid container className="info-container">
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.full_name}</div>
                                <div className="value-text">{userDetails?.fullName ? userDetails?.fullName : ''}</div>
                            </Grid>
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text-right">{strings.email}</div>
                                <div className="value-text" >{userDetails?.email ? userDetails?.email : ''}</div>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.phoneNo}</div>
                                <div className="value-text">{userDetails?.contactNo ? showContactNumber(userDetails?.contactNo, userDetails?.countryCode) : ''}</div>
                            </Grid>
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text-right">{strings.role}</div>
                                <div className="value-text">{userDetails?.role ? userDetails?.role : ''}</div>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.website}</div>
                                {userDetails?.website ?
                                        <a className="value-link" href={getFinalURL(userDetails?.website)} target="_blank" rel="noopener noreferrer">{userDetails?.website ? userDetails?.website.toLowerCase() : 'NA'}</a>
                                        : <div className="value-text">{userDetails?.website ? userDetails?.website : '-'}</div>
                                    }
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.profile_image_3}</div>
                    </div>
                    <Grid container className="info-container image-container" direction='row'>
                        <Grid item className="image-row-div" style={{
                            justifyContent: imageUrls?.length > 0 ? 'inherit' : 'center',
                        }}>
                            {imageUrls?.length > 0 ? (
                                <div onClick={() => handlePreviewOpen(imageUrls[0]?.url, 0)}>
                                    <FirebaseImage
                                        key={imageUrls[0]?.url}
                                        src={imageUrls[0]?.url}
                                        className={"profile-img"}
                                        type={'square'}
                                    />
                                    {imageUrls[0]?.isProfile && (
                                        <IconButton className={"profile-icon"}>
                                            <img src={ProfilePicIcon} />
                                        </IconButton>
                                    )}
                                    <IconButton className={"remove-icon"} onClick={(e) => deleteImage(0, e)}>
                                        <img src={RemoveIcon} />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }} onClick={() => handleProfileUpload()}>
                                    <input id="profile_image" type="file" accept="image/*" style={{ display: 'none' }} onChange={(e) => onSelectFile(e)} />
                                    <img src={UploadIcon} />
                                    <div className='upload-text'>Upload</div>
                                </div>
                            )}
                        </Grid>
                        <Grid item className="image-row-div" style={{
                            justifyContent: imageUrls?.length > 1 ? 'inherit' : 'center',
                        }}>
                            {imageUrls?.length > 1 ? (
                                <div onClick={() => handlePreviewOpen(imageUrls[1]?.url, 1)}>
                                    <FirebaseImage
                                        key={imageUrls[1]?.url}
                                        src={imageUrls[1]?.url}
                                        className={"profile-img"}
                                        type={'square'}
                                    />
                                    {imageUrls[1]?.isProfile && (
                                        <IconButton className={"profile-icon"}>
                                            <img src={ProfilePicIcon} />
                                        </IconButton>
                                    )}
                                    <IconButton className={"remove-icon"} onClick={(e) => deleteImage(1, e)}>
                                        <img src={RemoveIcon} />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }} onClick={() => handleProfileUpload()}>
                                    <input id="profile_image" type="file" accept="image/*" style={{ display: 'none' }} onChange={(e) => onSelectFile(e)} />
                                    <img src={UploadIcon} />
                                    <div className='upload-text'>Upload</div>
                                </div>
                            )}
                        </Grid>
                        <Grid item className="image-row-div upload-image-row-div" style={{
                            justifyContent: imageUrls?.length > 2 ? 'inherit' : 'center',
                        }}>
                            {imageUrls?.length > 2 ? (
                                <div onClick={() => handlePreviewOpen(imageUrls[2]?.url, 2)}>
                                    <FirebaseImage
                                        key={imageUrls[2]?.url}
                                        src={imageUrls[2]?.url}
                                        className={"profile-img"}
                                        type={'square'}
                                    />
                                    {imageUrls[2]?.isProfile && (
                                        <IconButton className={"profile-icon"}>
                                            <img src={ProfilePicIcon} />
                                        </IconButton>
                                    )}
                                    <IconButton className={"remove-icon"} onClick={(e) => deleteImage(2, e)}>
                                        <img src={RemoveIcon} />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center' }} onClick={() => handleProfileUpload()}>
                                    <input id="profile_image" type="file" accept="image/*" style={{ display: 'none' }} onChange={(e) => onSelectFile(e)} />
                                    <img src={UploadIcon} />
                                    <div className='upload-text'>Upload</div>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.bio}</div>
                        {userDetails?.bio && (
                            <div>
                                <IconButton onClick={() => setEditBio(true)}>
                                    <img src={EditIcon} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    {userDetails?.bio ? (
                        <div className="bio-text">{userDetails?.bio ? userDetails?.bio : 'aaaaaaaaa'}</div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="add-text">{strings.add_bio}</div>
                            <div>
                                <IconButton onClick={() => setEditBio(true)}>
                                    <img src={AddCircleIcon} />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.short_video}</div>
                        {userDetails?.videoUrl?.length > 0 && (
                            <div>
                                <IconButton onClick={() => setEditVideo(true)}>
                                    <img src={EditIcon} />
                                </IconButton>
                            </div>
                        )}

                    </div>
                    {userDetails?.videoUrl?.length > 0 ? (
                        <>
                            <div>
                                <div className="tag-container">
                                    <img src={Tag} />
                                    <div className="tag-name">{userDetails?.categoryName}</div>
                                </div>
                            </div>
                            <div className="video-div">
                                <FirebaseVideo
                                    key={userDetails?.videoUrl}
                                    src={video[0]?.videoUrl}
                                    className={"video-image-profile"}
                                    index={1}
                                    thumbnail={video[0]?.videoThumbnailUrl ? video[0]?.videoThumbnailUrl : null}
                                />
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="add-text">{strings.add_short_video}</div>
                            <div>
                                <IconButton onClick={() => setEditVideo(true)}>
                                    <img src={AddCircleIcon} />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
                <div className="personal-info-wrapper" style={{ marginBottom: 10 }}>
                    <div className="section-header">
                        <div className="title">{strings.business_info}</div>
                        {(userDetails?.businessInfo?.name || userDetails?.businessInfo?.location || userDetails?.businessInfo?.bio) && (
                            <div>
                                <IconButton onClick={() => setOpenEditBuisness(true)}>
                                    <img src={EditIcon} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    {(userDetails?.businessInfo?.name || userDetails?.businessInfo?.location || userDetails?.businessInfo?.bio) ? (
                        <div style={{ padding: 17 }}>
                            <div className="business-subheading">{strings.heading_business_name}</div>
                            <div className="business-text">{userDetails?.businessInfo?.name || '-'}</div>
                            <div className="business-subheading">{strings.heading_business_location}</div>
                            <div className="business-text">{userDetails?.businessInfo?.location || '-'}</div>
                            <div className="business-subheading">{strings.heading_business_details}</div>
                            <div className="business-text">{userDetails?.businessInfo?.bio || '-'}</div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className="add-text">{strings.add_business_info}</div>
                            <div>
                                <IconButton onClick={() => setOpenEditBuisness(true)}>
                                    <img src={AddCircleIcon} />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {editBasicInfo && renderBasicInfo()}
            {openImageUpdate && renderImageCropper()}
            {openImagePreview && renderImagePreview()}
            {editBio && renderAddBio()}
            {editVideo && renderAddVideo()}
            {playVideo && renderVideoPlayer()}
            {openEditBusiness && renderAddBusinessInfo()}
            {showAlert && showAlertModal(alertMsg, 'Ok', showAlert, '', () => closeAlerts())}
        </div >
    );
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});

export default connect(mapStateToProps, { saveAuthData })(withRouter(UserProfile));
