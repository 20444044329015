import React from 'react';
import SearchComponent from '../components/search';

const Search = props => {
    return (
        <div>
            <SearchComponent />
        </div>
    );
}
export default Search;