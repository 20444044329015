import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { data } from '../../utils/locales/data';
import { connect } from 'react-redux';
import FirebaseImage from '../../components/firebase_fast_image_view/index';
import FirebaseVideo from '../../components/firebase_video_view/index';
import { getDividedSearchData, getMarketPlaceListFB, getMediaUrl, getUser } from '../../utils/firebase_helper/auth';
import { ReactComponent as EmptyFeedIcon } from '../../assets/images/ic_empty_feed_icon.svg';
import { ReactComponent as ChatIcon } from '../../assets/chat_icon.svg';
import { showSpinner, showAlert as showAlertModal, goclone } from '../../utils/common_helper';
import { registerOnesignalPlayerId } from "../../components/chat-module/utils/one-signal-helper";
import { saveOtherUserData, saveChatUserData, saveFilterData } from '../../actions/auth';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import './home.scss'
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import { ReactComponent as TagFeed } from '../../assets/tag-feed.svg'

let strings = new LocalizedStrings(data);
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            language: this.props?.language ? this.props.language : 'en',
            filterData: this.props?.filterData ? this.props?.filterData : { filterRole: { id: 'all', name: 'All' }, filterCategory: [] },
            data: [],
            isLoading: false,
            showAlert: false,
            alertMsg: '',
            businessData: [],
            bioData: [],
            userData: [],
            flag: '',
            currentVideoIndex: 0
        }
        this.arrayHolder = goclone(this.props.searchData);
        //addOpenedEventListener(this.props.location);
        // this.focusListener = window.addEventListener('focus', () => {
        //     this.getFeedList();
        // });
    }
    componentDidMount() {
        registerOnesignalPlayerId();
        this.getFeedList(this.state.filterData);
        console.log("Auth:::feedlist", this.state.filterData)
        // if (this.props?.location?.state?.flag) {
        //     this.setState({ flag: this.props?.location?.state?.flag })
        // }
    }
    componentDidUpdate(prevProps) {
        if (!(_.isEqual(prevProps.filterData, this.props.filterData)) && this.props.filterData) {
            console.log('Hereaaaaaaaaaaaaa', prevProps.filterData, this.props.filterData)
            this.setState({ filterData: this.props.filterData });
            this.getFeedList(this.props.filterData);
        }
    }
    componentWillUnmount() {
        if (this.focusListener) {
            this.focusListener();
            this.focusListener = null;
        }
    }
    getFeedList = (filterData) => {
        console.log("res::---->filter", filterData)

        this.setState({ isLoading: true })
        try {
            getMarketPlaceListFB(false, 20, filterData).then(res => {
                this.setState({ data: res, isLoading: false })
            });
        }
        catch (ex) {
            console.error(ex);
        }
    }

    renderSubHeader = () => {
        return (
            <>
                {
                    strings.getLanguage() ?
                        <div className="sub-header">
                            <span>Marketplace</span>
                        </div>

                        : <></>
                }
            </>
        )
    }
    renderEmptyFeed = () => {
        return (
            <div className="empty-view">
                <div>
                    <EmptyFeedIcon />
                    <h6>Posts not available</h6>
                </div>
            </div>
        )
    }

    navigateTo = (userId) => {
        getUser(userId).then(res => {
            if (res) {
                this.props.saveOtherUserData(res)
                this.props.history.push('/viewprofile')
            }
        }).catch(error => {
            // setIsLoading(false);
            // setAlertMsg(error.message)
            this.setState({ showAlert: true, alertMsg: error.message })
        });
    }

    closeAlerts = () => {
        this.setState({ showAlert: false })
    }

    getTime = (item) => {
        const curr = new Date();
        curr.setHours(0, 0, 0, 0);
        let timeToDisplay = ''

        var isBefore = moment.unix(item?.videoUploadedAt ? item?.videoUploadedAt?.seconds : item?.createdAt?.seconds).isBefore(moment(curr));
        if (isBefore) {
            timeToDisplay = moment.unix(item?.videoUploadedAt ? item?.videoUploadedAt?.seconds : item?.createdAt?.seconds).format('MMM DD, yyyy, hh:mm A')
        } else {
            timeToDisplay = moment.unix(item?.videoUploadedAt ? item?.videoUploadedAt?.seconds : item?.createdAt?.seconds).format('hh:mm A')
        }
        return timeToDisplay;
    }

    navigateToChat = (item) => {
        this.props.saveChatUserData(item);
        this.props.history.push('/chats');
    }

    setCurrentVideoIndex = (index) => {
        this.setState({currentVideoIndex: index})
    }

    renderFeed = () => {
        return (
            <>
                {this.state?.data?.map((item, index) => {
                    console.log(item.videoUrl)
                    return (
                        <div className="feed-card" >
                            <div className="video-card" >
                                <div className="video-card-header" >
                                    <div className="profile-details" onClick={() => this.navigateTo(item?.userId)}>
                                        <div className="drawer-profile-img-div">
                                            <FirebaseImage
                                                src={item?.profileImageUrl ? item?.profileImageUrl : null}
                                                className={"drawer-profile-img"}
                                            />
                                        </div>
                                        <div className="user-details">
                                            <div className="user-name">{item?.fullName}</div>
                                            <div className="user-role">{item?.businessInfo?.name ? item?.businessInfo?.name : null}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="user-role feed-time">
                                            {this.getTime(item)}
                                        </div>
                                    </div>
                                </div>
                                <FirebaseVideo
                                    key={item?.videoUrl[0]?.videoUrl}
                                    src={item?.videoUrl[0]?.videoUrl ? item?.videoUrl[0]?.videoUrl : null}
                                    index={index}
                                    currentIndex={this.state.currentVideoIndex}
                                    from={'list'}
                                    onChangeIndex={() => this.setCurrentVideoIndex(index)}
                                    thumbnail={item?.videoUrl[0]?.videoThumbnailUrl ? item?.videoUrl[0]?.videoThumbnailUrl : null}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="user-role" style={{ color: "#828282", marginTop: 30 }}>
                                        <div className="category-icon" ><TagFeed fill="#828282" /></div>
                                        <div className="category-text">{item?.categoryName ? item?.categoryName : null}</div>
                                    </div>
                                    <div className="user-role feed-time" style={{ marginTop: 20 }}>
                                        <IconButton
                                            style={{ padding: '0px' }}
                                            onClick={() => this.navigateToChat(item)}>
                                            <ChatIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
                <div className="last-card-space"></div>
            </>
        )
    }

    render() {
        return (
            <>
                <div className="App" style={{ width: '100%', height: '100%' }}>
                    {this.renderSubHeader()}
                    {this.state.data.length < 1 && !this.state.isLoading ? this.renderEmptyFeed() : this.renderFeed()}
                    {showSpinner(this.state.isLoading)}
                    {this.state.showAlert && showAlertModal(this.state.alertMsg, 'Ok', this.state.showAlert, '', () => this.closeAlerts())}
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    language: state.language,
    filterData: state.auth.filterData,
    searchData: state.auth.searchData,

});
export default connect(mapStateToProps, { saveOtherUserData, saveFilterData, saveChatUserData })(Home)
