import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";
import { firebaseConfig } from '../constants';
export const HOST_SERVER_NAME = "UAT";

let storage;
export const firebaseResendTime = 30; // in seconds


export function signInWithCustomToken(token) {
  return new Promise((resolve, reject) => {
    //  console.log("aa -------- token=>", token);
    if (!token) {
      reject("Invalid Token");
      return;
    }
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(newUser => {
        //  console.log("aa -------- newUser=>", newUser);
        resolve(newUser);
      }).catch(error => {
        // console.log("signIn error code=>", error?.code);
        // console.log("signIn error message=>", error?.message);
        reject(error);
      });
  });
}

/**
 * Notification
 */
export function updateReadStatusFB(documentID) {
  let UID = firebase.auth()?.currentUser?.uid;
  return new Promise((resolve, reject) => {
    if (documentID) {
      firebase.firestore().doc(`NOTIFICATIONS/USERS/${UID}/${documentID}`).update({
        isNotiRead: true
      }).then(() => {
        console.log('aa ------- updateReadStatus success');
        resolve();
      }).catch(error => {
        console.log('aa ------- updateReadStatus error', error);
        reject(error)
      });
    } else {
      resolve();
    }
  });
}

export function updateOneSignalDeviceId(deviceId, isRemove) {
  let UID = firebase.auth()?.currentUser?.uid;
  return new Promise((resolve, reject) => {
    firebase.firestore().doc(`users/${UID}`)
      .update({ onesignal_device_ids: !isRemove ? firebase.firestore.FieldValue.arrayUnion(deviceId) : firebase.firestore.FieldValue.arrayRemove(deviceId) })
      .then(doc => {
        resolve();
      }).catch(error => {
        let newErr = { message: error.message }
        if (error.code === 'firestore/permission-denied') {
          newErr.message = "firebase_user_blocked_account";
          reject(newErr)
        } else {
          reject(newErr)
        }
      });
  });
}

if (firebase.apps.length > 0) {
  storage = firebase.storage();
} else {
  // use environment-specific firebase config
  firebase.initializeApp(firebaseConfig);
  storage = firebase.storage();
}

/**
 * Auth
 */
export const auth = firebase.auth;

/**
 * DB
 */
export const firestore = firebase.firestore();

export const firebaseStorage = storage;

let uid = '';
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    uid = user.uid;
  } else {
    // User is signed out
    // ...
  }
});

export const currentUserId = uid;

/**
 * Method to login using google social login
 * @param {*} token
 */


/**
 * Function use to get current login firebase user in app
 */
export function getFirebaseUser() {
  return firebase?.auth()?.currentUser;
}

export async function signOut() {
  return firebase?.auth()?.signOut();
}

export const initializeApp = (token) => {
  if (firebase.apps.length > 0) {
    storage = firebase.storage();
  } else {
    // use environment-specific firebase config
    let firebaseApp = firebase.initializeApp(firebaseConfig);
    storage = firebase.storage();
  }
  // signInWithCustomToken(token);
};


/**
 * getMediaUrl
 */
export const getMediaUrl = (path) => {
  return new Promise((resolve, reject) => {
    storage
      .ref(`${path}`)
      .getDownloadURL()
      .then((url) => {
        resolve({ url, path });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const db = firebase.firestore();
export const getRef = ({ collection, doc }) => db.collection(collection).doc(doc);

export function createUser(data) {
  //  Add and updated given set of document
  return new Promise(async (resolve, reject) => {
    try {
      const id = data.userId;
      const dbRef = db.collection('users');
      let docRef;
      if (id) {
        dbRef.doc(id);
        await dbRef.doc(id).set(data);
        docRef = getRef({ collection: 'users', doc: id });
      } else {
        docRef = await dbRef.add(data);
      }
      const doc = await docRef.get();
      const { id: docId } = doc;
      const docData = doc.data();
      resolve({ ...docData });
    } catch (error) {
      reject(error);
    }
  });
}

export const getUser = async user => {
  const doc = await db.collection('users').doc(user).get();
  return doc.data();
};

export function updateUser(data, user) {
  //  Add and updated given set of document  
  return new Promise(async (resolve, reject) => {
    try {
      await db.collection('users').doc(user).update(data);
      resolve(getUser(user));
    } catch (error) {
      reject(error);
    }
  });
}

export function updateBusiness(data, user) {
  //  Add and updated given set of document
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        businessInfo: data,
      }
      await db.collection('users').doc(user).update(body);
      resolve(getUser(user));
    } catch (error) {
      reject(error);
    }
  });
}

export const getRegistredUsers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docs = await db.collection('users').get();
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docs = await db.collection('categories').orderBy('id', 'asc').get();
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategoryById = categoryId => {
  return new Promise(async (resolve, reject) => {
    try {
      const doc = await db.doc('categories/' + categoryId).get();
      resolve(doc?.data());
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docs = await db.collection('categories').get();
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

let UserList = [];
let getUserListAIPInprogress = false;


export function getMarketPlaceListFB(isLoadPrevious, limit, filterData) {
  // let UID = auth()?.currentUser?.uid;
  // console.log(":::filterData3", filterData)
  var UID;
  auth().onAuthStateChanged((user) => {
    if (user) {
      UID = user.uid;
      // console.log("not signed in", UID)
      // ...
    } else {
      // User is signed out
      // ...
      // console.log("not signed in======")
    }
  });
  let lastVisible = '';
  return new Promise((resolve, reject) => {
    let query = db.collection('users');
    if (filterData?.filterRole?.id != 'all' && filterData?.filterCategory?.length > 0) {
      query = query.where('categoryName', 'in', filterData?.filterCategory);
      query = query.where('roleKey', '==', filterData?.filterRole?.id);
      query = query.orderBy('videoUploadedAt', 'desc');

    } else if (filterData?.filterRole?.id != 'all') {
      query = query.where('roleKey', '==', filterData?.filterRole?.id).orderBy('videoUploadedAt', 'desc');
    } else if (filterData?.filterCategory?.length > 0) {
      query = query
        .where('categoryName', 'in', filterData?.filterCategory)
        .orderBy('videoUploadedAt', 'desc');

    } else {
      query = query.orderBy('videoUploadedAt', 'desc');
      // .orderBy('videoUrl', 'desc');
    }
    if (isLoadPrevious && lastVisible) {
      query = query.startAfter(lastVisible).limit(limit);
    } else {
      lastVisible = '';
      //query = query.limit(limit);
    }
    query
      .get()
      .then(querySnapshot => {
        if (querySnapshot) {
          //lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          var list = [];
          // console.log('querySnapshot', querySnapshot.docs.length)
          querySnapshot.forEach(doc => {
            if (doc?.data()) {
              doc.data().id = doc.id;
              // console.log("doc?.data()?.userId != UID", doc?.data()?.userId, UID)
              if (doc?.data()?.userId != UID && doc?.data()?.videoUrl?.length > 0) {
                list.push(doc.data());
              }
            }
          });
          // list.filter(user => user?.userId != UID);
          // console.log("List===>", list.length)
          resolve(list);
          UserList = [];
        } else {
          resolve([]);
        }
      }).catch(error => {
        // console.log('getMarketPlaceListFB error', error);
        reject(error);
      });
  });
}

function getUserList() {
  let UID;
  // let UID = firebase.auth()?.currentUser?.uid;
  // const currentUser = firebase.auth()?.currentUser
  // if (currentUser) {
  //   UID = currentUser.uid;
  //   console.log("New UID==>", UID)

  // }
  // else {
  //   console.log("New UID no generated==>", UID)
  // }
  // let UID;
  // firebase.auth().then(currentUser => {
  //   UID = currentUser?.uid
  // }).catch(error => console.log(error))

  auth().onAuthStateChanged((user) => {
    if (user) {
      UID = user.uid;
      // console.log("not signed in1", UID)
      // ...
    } else {
      // User is signed out
      // ...
      // console.log("not signed in1======")
    }
  });

  return new Promise(async (resolve, reject) => {
    if (!UserList?.length && !getUserListAIPInprogress) {
      getUserListAIPInprogress = true;
      UserList = [];
      console.log('Firebase called')
      db.collection('users').get().then(docs => {
        getUserListAIPInprogress = false;
        docs.docs.map(doc => {
          if (doc?.data()) {
            doc.data().id = doc.id;
            UserList.push(doc.data());
          }
        })
        resolve(UserList);
      }).catch(error => {
        getUserListAIPInprogress = false;
        console.log('error called')
        resolve(UserList);
      })
    } else {
      console.log('Cached called')
      resolve(UserList);
    }
  });
}
// docType = fullName/bio/businessInfo.bio/businessInfo.name/businessInfo.location
export function getSearchMarketPlaceData(searchText, docType = 'fullName', limit) {
  let UID = firebase.auth()?.currentUser?.uid;
  // let lastVisible = '';
  let trimmedText = searchText.trim();
  return new Promise(async (resolve, reject) => {
    let data = await getUserList();
    let searchObj = [];
    switch (docType) {
      case 'fullName':
        searchObj = data?.filter(item => {
          if (UID !== item?.userId) {
            return item?.fullName
              ?.toLowerCase()
              .includes(trimmedText.toLowerCase());
          }
        });
        break;
      case 'bio':
        searchObj = data?.filter(item => {
          if (UID !== item?.userId) {
            return item?.bio
              ?.toLowerCase()
              .includes(trimmedText.toLowerCase());
          }
        });
        break;
      case 'businessInfo.name':
        searchObj = data?.filter(item => {
          if (UID !== item?.userId) {
            return item?.businessInfo?.name
              ?.toLowerCase()
              .includes(trimmedText.toLowerCase());
          }
        });
        break;
      default:
        searchObj = [];
    }
    resolve(searchObj);
  });
}

export async function getDividedSearchData(searchText = 'a', limit = 3) {
  let UID = firebase.auth()?.currentUser?.uid;
  let trimmedText = searchText.trim();
  // let query = db.collection('users');
  let nameSearch = [];
  let bioSearch = [];
  let businessNameSearch = [];

  let data = await getUserList();
  nameSearch = data?.filter(item => {
    if (nameSearch.length < 4) {
      if (UID !== item?.userId) {
        return item?.fullName
          ?.toLowerCase()
          .includes(trimmedText.toLowerCase());
      }
    }
  });
  bioSearch = data?.filter(item => {
    if (bioSearch.length < 4 && item?.bio) {
      if (UID !== item?.userId) {
        return item?.bio
          ?.toLowerCase()
          .includes(trimmedText.toLowerCase());
      }
    }
  });
  businessNameSearch = data?.filter(item => {
    if (businessNameSearch.length < 4 && item?.businessInfo) {
      if (UID !== item?.userId) {
        return item?.businessInfo?.name
          ?.toLowerCase()
          .includes(trimmedText.toLowerCase());
      }
    }
  });
  const searchObject = {
    nameSearch,
    bioSearch,
    businessNameSearch
  }
  // console.log('nameSsearchObjectearch', searchObject);
  return (searchObject);
}

export const getOtherUser = async userId => {
  const doc = await db.collection('users').doc(userId).get();
  return doc.data();
};

let lastVisible = '';
export function getNotificationsFB(isLoadPrevious, limit) {
  let UID = auth()?.currentUser?.uid;
  // let lastVisible = '';
  return new Promise((resolve, reject) => {
    let query = db
      .collection('NOTIFICATIONS')
      .doc('USERS')
      .collection(`${UID}`);

    query = query
      .orderBy('sentAt', 'desc')

    if (isLoadPrevious && lastVisible) {
      query = query.startAfter(lastVisible).limit(limit);
    } else {
      lastVisible = '';
      query = query.limit(limit);
    }
    query
      .get()
      .then(querySnapshot => {
        if (querySnapshot) {
          lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          let list = [];
          querySnapshot.forEach(doc => {
            if (doc?.data()) {
              doc.data().id = doc.id;
              let tempData = doc.data();
              tempData.id = doc.id
              list.push(tempData);
            }
          });
          resolve(list);
        } else {
          resolve([]);
        }
      }).catch(error => {
        console.log('getNotificationsFB error', error);
        reject(error);
      });
  });
}

export function deleteNotificationFB(documentID) {
  let UID = auth()?.currentUser?.uid;
  return new Promise((resolve, reject) => {
    db.doc(`NOTIFICATIONS/USERS/${UID}/${documentID}`).delete().then(() => {
      console.log('deleteNotification=====success');
      resolve();
    }).catch(error => {
      console.log('deleteNotification=====error', error);
      reject(error);
    })
  });
}



export function markAsReadAllNotifications() {
  let UID = auth()?.currentUser?.uid;
  return new Promise((resolve, reject) => {
    db
      .collection('NOTIFICATIONS')
      .doc('USERS')
      .collection(`${UID}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          updateReadStatusFB(doc?.id)
        });
        resolve();
      }).catch(error => {
        console.log('markAsReadAllNotifications error', error);
        reject(error)
      });
  })
}

export function deleteAllNotifications() {
  let UID = auth()?.currentUser?.uid;

  return new Promise((resolve, reject) => {
    db
      .collection('NOTIFICATIONS')
      .doc('USERS')
      .collection(`${UID}`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
        resolve('deleteAllNotifications success');

      }).catch(error => {
        console.log('deleteAllNotifications error', error);
        reject(error)
      });
  })
}

export function setNotificationListSnapshot(callback) {
  let userId = auth()?.currentUser?.uid;
  getUnreadNotificationsFB(callback);
  firestore.collection(`NOTIFICATIONS/USERS/${userId}`).onSnapshot(querySnapshot => {
    getUnreadNotificationsFB(callback);
  }, error => {
    console.log("setNotificationListSnapshot error", error)
  });
}

export function getUnreadNotificationsFB(callback) {
  let UID = auth()?.currentUser?.uid;
  firestore.collection(`NOTIFICATIONS/USERS/${UID}`).where('isNotiRead', '==', false).get().then(querySnapshot => {
    callback(querySnapshot.size);
  }).catch(error => {
    callback(0);
  });
}

export default firebase;
