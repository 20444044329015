import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { MenuItem, IconButton, Hidden } from '@material-ui/core';
import '../auth.scss';
import EmailIcon from '../../../assets/email.png';
import PasswordIcon from '../../../assets/password.png';
import FacebookIcon from '../../../assets/ic_facebook.svg'
import AppleIcon from '../../../assets/ic_apple.svg';
import LinkedInIcon from '../../../assets/ic_linkedIn.svg';
import LogoBlack from '../../../assets/logo_black.svg';
import { validateEmail, validatePassword } from '../../../utils/input-validation';
import { showToast, showSpinner, showAlert } from '../../../utils/common_helper';
import { Link } from 'react-router-dom';
import { loginAPI, OAUTH2_CLIENT_ID, setAuthToken } from '../../../utils/api-helper';
import { auth, firestore } from '../../../utils/firebase_helper/auth';
import { countryCodeList } from '../../../utils/constants';
import { saveAuthData } from '../../../actions/auth';
import { Redirect } from 'react-router'
import { set, setObj, setArray } from "../../../utils/local-storage-helper";

let strings = new LocalizedStrings(data);
const defaultCountry = { code: '+1', name: 'United States' };
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNo: '',
      selectedCountry: defaultCountry,
      showPassword: false,
      errors: {},
      isSubmitted: false,
      isRedirect: false,
      showAlertFlag: false,
      isRedirectToTerms: false,
      isRedirectToPolicy: false,
    }

  }

  configureCaptcha = (cls) => {
    window.recaptchaVerifier = new auth.RecaptchaVerifier(cls, {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.onSignInSubmit();
      },
    });
  }

  handleSubmit = (e) => {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    this.setState({ showLoader: true });
    let number = `${this.state.selectedCountry.code}${this.state.phoneNo}`;
    console.log(number, '-----SubmitStart')
    this.configureCaptcha('sign-in-button')
    const phoneNumber = number;
    const appVerifier = window.recaptchaVerifier;
    console.log(phoneNumber, appVerifier);
    auth().setPersistence(auth.Auth.Persistence.LOCAL);
    auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        console.log(confirmationResult, '-----SubmitEnd')
        set('VENTYOUR_PROFILE_COMPLETED', false);
        setArray('filterData', []);
        window.confirmationResult = confirmationResult;
        this.setState({ showLoader: false, isRedirect: true });
        this.props.saveAuthData(confirmationResult.data);
      }).catch((error) => {
        setTimeout(() => {
          window.location.reload();
        }, 1200);
        this.setState({ showLoader: false });
        if(error.code === 'auth/invalid-phone-number') {
          showToast(strings.getLanguage() ? strings.error_phone_number : '');
        } else {
          showToast(error.message);
        }
      });
  };

  handleChange = (e) => {
    this.setState({ phoneNo: e.target.value });
  }

  handleCodeChange = (event) => {
    const data = countryCodeList.find(o => o.name === event.target.value);
    this.setState({ selectedCountry: data });
  };

  redirectToTerms = () => {
    this.setState({ isRedirectToTerms: true });
    // this.props.history.push('/terms_and_conditions')
  }

  redirectToPolicy = () => {
    this.setState({ isRedirectToPolicy: true })
  }

  closeAlerts = () => {
    this.setState({ showAlertFlag: false });
  }

  handleClick = () => {
    showToast(strings.getLanguage() ? strings.coming_soon : '');//TODO remove this toast message 
  }

  render() {
    return (
      <>
        <div className="common-auth-wrapper">
          {showSpinner(this.state.showLoader)}
          <Hidden xsDown>
            <Row>
              <Col className="bg-image" >
                <div className="logo"></div>
              </Col>
              <Col className="common-reg-wrapper">
                <div className="form-wrapper">
                  {strings.getLanguage() ?
                    <p className="title" style={{ marginBottom: '40px' }}>{strings.login}</p>
                    : <></>
                  }
                  <div id="sign-in-button"></div>
                  <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="text-item email-field">
                      <TextField className="text-field" onChange={this.handleCodeChange}
                        style={{ width: '30%', paddingRight: 5 }}
                        select
                        error={this.state.errors.email}
                        id="code"
                        name="country code"
                        label={strings.getLanguage() ? strings.country_code : ''}
                        type="text"
                        variant="outlined"
                        value={this.state.selectedCountry.name}
                        helperText={this.state.errors.email}
                      >
                        {countryCodeList.map((option) => (
                          <MenuItem autoFocus={this.state.selectedCountry?.name === option?.name} key={option.name} value={option.name}>
                            ({option?.code}) {option?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField className="text-field" onChange={this.handleChange}
                        style={{ width: '70%', paddingLeft: 5 }}
                        error={this.state.errors.email}
                        id="email"
                        name="email"
                        label={strings.getLanguage() ? strings.phoneNo : ''}
                        type="text"
                        variant="outlined"
                        value={this.state.phoneNo}
                        helperText={this.state.errors.email}
                        inputProps={{ maxLength: 10 }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="terms-privacy-policy-container">
                      <div className="privacy-policy-text">{strings.terms_privacy_policy}
                        <span className="terms-text" onClick={() => this.redirectToTerms()}>{" " + strings.terms_service}</span> {strings.and}
                        <span className="terms-text" onClick={() => this.redirectToPolicy()}>{" " + strings.privacy_policy}</span> {strings.ventyour}
                      </div>
                    </div>
                    <div className="text-item">
                      <Button type="submit" disabled={!(this.state.phoneNo?.length > 4 && this.state.selectedCountry)} className="common-button signup-button" style={{ textTransform: "capitalize" }}>{strings.getLanguage() ?
                        strings.login : ''}</Button>
                    </div>
                    <div className="divider-container">
                      <div style={{ marginRight: 10 }} className="divider" />
                      <span className="or-text">{strings.or}</span>
                      <div style={{ marginLeft: 10 }} className="divider" />
                    </div>
                    <IconButton>
                      <img src={FacebookIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                    </IconButton>
                    <IconButton>
                      <img src={LinkedInIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                    </IconButton>
                    <IconButton>
                      <img src={AppleIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                    </IconButton>
                    {showAlert('Coming Soon...', 'Ok', this.state.showAlertFlag, '', () => this.closeAlerts())}
                  </form>
                </div>
              </Col>
            </Row>
          </Hidden>
          <Hidden smUp>
            <Col className="common-reg-wrapper">
              <div className="form-wrapper">
                <img alt='logo' src={LogoBlack} />
                {strings.getLanguage() ?
                  <p className="title" style={{ marginBottom: '40px' }}>{strings.login}</p>
                  : <></>
                }
                <div id="sign-in-button"></div>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                  <div className="text-item email-field">
                    <TextField className="text-field" onChange={this.handleCodeChange}
                      style={{ width: '30%', paddingRight: 5 }}
                      select
                      error={this.state.errors.email}
                      id="code"
                      name="country code"
                      label={strings.getLanguage() ? strings.country_code : ''}
                      type="text"
                      variant="outlined"
                      value={this.state.selectedCountry.name}
                      helperText={this.state.errors.email}
                    >
                      {countryCodeList.map((option) => (
                        <MenuItem 
                          className='dropDownFont'
                          autoFocus={this.state.selectedCountry?.name === option?.name} 
                          key={option.name} value={option.name}>
                          ({option?.code}) {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField className="text-field" onChange={this.handleChange}
                      style={{ width: '70%', paddingLeft: 5 }}
                      error={this.state.errors.email}
                      id="email"
                      name="email"
                      label={strings.getLanguage() ? strings.phoneNo : ''}
                      type="text"
                      variant="outlined"
                      value={this.state.phoneNo}
                      helperText={this.state.errors.email}
                      inputProps={{ maxLength: 10 }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="terms-privacy-policy-container">
                    <div className="privacy-policy-text">{strings.terms_privacy_policy}
                      <span className="terms-text" onClick={() => this.redirectToTerms()}>{" " + strings.terms_service}</span> {strings.and}
                      <span className="terms-text" onClick={() => this.redirectToPolicy()}>{" " + strings.privacy_policy}</span> {strings.ventyour}
                    </div>
                  </div>
                  <div className="text-item">
                    <Button type="submit" disabled={!(this.state.phoneNo?.length > 4 && this.state.selectedCountry)} className="common-button signup-button" style={{ textTransform: "capitalize" }}>{strings.getLanguage() ?
                      strings.login : ''}</Button>
                  </div>
                  <div className="divider-container">
                    <div style={{ marginRight: 10 }} className="divider" />
                    <span className="or-text">{strings.or}</span>
                    <div style={{ marginLeft: 10 }} className="divider" />
                  </div>
                  <IconButton>
                    <img src={FacebookIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                  </IconButton>
                  <IconButton>
                    <img src={LinkedInIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                  </IconButton>
                  <IconButton>
                    <img src={AppleIcon} className="social-media-login-button" onClick={() => this.handleClick()} />
                  </IconButton>
                  {showAlert('Coming Soon...', 'Ok', this.state.showAlertFlag, '', () => this.closeAlerts())}
                </form>
              </div>
            </Col>
          </Hidden>

        </div>
        {this.state.isRedirect ? <Redirect push  to={{
          pathname: "/VerificationCode", state: {
            isFromSignup: true,
            requestParam: this.state
          }
        }}></Redirect> : null}
        {this.state.isRedirectToTerms ? <Redirect push  to={{
          pathname: "/terms_of_service"
        }}></Redirect> : null}
        {this.state.isRedirectToPolicy ? <Redirect push  to={{
          pathname: "/privacy_policy"
        }}></Redirect> : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, { saveAuthData })(Login)