import {createStore, applyMiddleware} from 'redux'
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import Reducer from './reducers'


export const store = createStore(Reducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

export default { store, persistor };

// const store = createStore(Reducer)

// export default store