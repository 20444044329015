import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import languageReducer from './languageReducer';
import authReducer from './authReducer';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"], //name of value you wanna persist
  };

  const rootReduces = combineReducers({
    // language: languageReducer,
    auth: authReducer,
})

export default persistReducer(persistConfig, rootReduces);