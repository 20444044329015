import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { saveAuthData, saveFilterData, saveLoginUserData } from '../../actions/auth';
import { ReactComponent as ChatIcon } from '../../assets/images/drawer/ic_chat.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/drawer/ic_home.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/drawer/ic_logout.svg';
import { ReactComponent as SelectedChatIcon } from '../../assets/images/drawer/ic_selected_chat.svg';
import { ReactComponent as SelectedHomeIcon } from '../../assets/images/drawer/ic_selected_home.svg';
import { ReactComponent as SelectedSettingIcon } from '../../assets/images/drawer/ic_selected_setting.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/drawer/ic_settings.svg';
import { userTappedRemoveConsentButton } from '../../components/chat-module/utils/one-signal-helper';
import FirebaseImage from '../../components/firebase_fast_image_view/index';
import TopBar from '../../components/top-bar';
import { capitalizeText, confirmAlert, showToast } from '../../utils/common_helper';
import { terms_privacy_base_url } from '../../utils/constants';
import Emitter from '../../utils/Emitter';
import { getUser, signOut } from '../../utils/firebase_helper/auth';
import { clearAllLocalStorage } from '../../utils/local-storage-helper';
import { data } from '../../utils/locales/data';
import './style.scss';

const drawerWidth = 288;
const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9) + 1,
    // },
    width: 73,
  },
  MuiPaperRoot: {
    marginLeft: '15vw',
    marginTop: '65px',
    zIndex: '999',
    borderRadius: 5,
    border: '1px solid #ebebeb',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '4vw',
    },
  },
  mainDrawerCSS: {
    position: 'absolute'
  }
});

let strings = new LocalizedStrings(data);

const menuList = [
  { name: 'Home', icon: <HomeIcon />, selected_icon: <SelectedHomeIcon /> },
  { name: 'Messages', icon: <ChatIcon />, selected_icon: <SelectedChatIcon /> },
  { name: 'Settings', icon: <SettingsIcon />, selected_icon: <SelectedSettingIcon /> },
  { name: 'Logout', icon: <LogoutIcon /> },
];

class DrawerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isHoverOpen: false,
      selectedMenu: 'Home',
      showConfirmAlert: false,
      profileData: null,
      isDataFatched: false,
      isMenuClicked: false,
      userData: props.authData,
    }
    Emitter.on('VENTYOUR_USER_UPDATE', this.updateUser);
  }

  updateUser = (newValue) => {
    // console.log("aa ---------------------- VENTYOUR_USER_UPDATE=>", this.props.authData?.profileImageUrl);
    if (this.state.isMount) {
      this.setState({ userData: this.props.authData });
    }
  }

  componentDidUpdate(prevProps) {
    // if ((prevProps.authData?.profileImageUrl !== this.props.authData?.profileImageUrl ||
    //   prevProps.authData?.fullName !== this.props.authData?.fullName ||
    //   prevProps.authData?.role !== this.props.authData?.role) && this.props.authData) {
    //   this.setState({ userData: this.props.authData });
    // }
  }

  componentDidMount() {
    this.state.isMount = true;
    this.getProfileData();
    this.getCurrentPage();
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ showConfirmAlert: false });
    });
  }

  componentWillUnmount() {
    this.state.isMount = false;
    this.unlisten();
    Emitter.off('VENTYOUR_USER_UPDATE', this.updateUser);
  }

  getCurrentPage = () => {
    let currentPage = '';
    if (window.location.pathname == '/home') {
      currentPage = 'Home';
    } else if (window.location.pathname == '/Chats' || window.location.pathname == '/chats') {
      currentPage = 'Messages';
    } else if (window.location.pathname == '/settings') {
      currentPage = 'Settings';
    }
    this.setState({ selectedMenu: currentPage });
  }

  handleDrawerOpen = () => {
    // console.log("drawer icon clicked");
    this.setState({ isHoverOpen: false });
    this.setState({ open: !this.state.open, isMenuClicked: !this.state.isMenuClicked });
    this.props.isStaticOpen(!this.state.isMenuClicked)
  };

  onHoverOpen = () => {
    this.setState({ open: true });
    this.setState({ isHoverOpen: true })
  }

  onMenuSelect = (menu) => {
    if (menu?.name == 'Logout') {
      this.setState({ showConfirmAlert: true });
    } else if (menu?.name == 'Payment') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/Payment');
    } else if (menu?.name === 'Messages') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/chats');
    } else if (menu?.name == 'Settings') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/settings');
    } else if (menu?.name == 'Home') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push({ pathname: '/home' });
      // this.props.history.push({ pathname: '/home', state: { handleDrawerOpen: this.handleDrawerOpen, onHoverOpen: this.onHoverOpen, onMenuSelect: this.onMenuSelect } });
    } else if (menu?.name == 'Privacy Policy' || menu?.name == 'Terms of Service') {
      // this.setState({ selectedMenu: menu.name });
      this.openURLS(menu.name)
    } else {
      alert("Coming Soon...")
    }
    this.setState({ open: false });
  }

  openURLS = (type) => {
    let link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (type === 'Privacy Policy') {
      link.setAttribute('href', `${terms_privacy_base_url}privacy-policy.html`);
      link.click();
    } else if (type === 'Terms of Service') {
      link.setAttribute('href', `${terms_privacy_base_url}terms-of-service.html`);
      link.click();
    }
  }

  onConfirmLogout = () => {
    userTappedRemoveConsentButton();
    clearAllLocalStorage();
    this.props.saveAuthData(null);
    this.props.saveLoginUserData(null);
    this.props.saveFilterData([]);
    this.props.history.push('/');
    signOut();
  }

  getProfileData = () => {
    this.setState({ isLoading: true });
    getUser(this.props.authData?.userId).then(response => {
      this.setState({ isDataFatched: true });
      // console.log('getProfileData drawer Here', response, this.props.authData?.userId);
      this.props.saveAuthData(response);
      var updatedProfileData = response ? response : null;
      this.setState({ profileData: updatedProfileData })
    }).catch(this.handleError);
  }

  handleError = (error) => {
    this.setState({ isDataFatched: true }, () => {
      if (error?.message) {
        showToast(error?.message, "error");
      }
    });
  }

  onCancelLogout = () => {
    this.setState({ showConfirmAlert: false });
  }

  render() {
    const { classes, theme, authData } = this.props;
    const { selectedMenu, profileData } = this.state;
    return (
      <>
        <TopBar
          handleDrawerOpen={this.handleDrawerOpen}
          onHoverOpen={this.onHoverOpen}
          onMenuSelect={this.onMenuSelect}
          // menuList={menuList}
          role={authData?.role}
          selectedMenu={this.state.selectedMenu}
          history={this.props.history}
        />
        <Hidden xsDown>
          <Drawer
            onMouseOver={
              !this.state.isMenuClicked
                ? () => {
                  this.setState({ open: true })
                }
                : null
            }
            onMouseLeave={
              !this.state.isMenuClicked
                ? () => {
                  this.setState({ open: false })
                }
                : null
            }
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
              [classes.mainDrawerCSS]: !this.state.isMenuClicked
              // [classes.drawerOpen]: this.state.absoluteOpen,
              // [classes.drawerClose]: !this.state.absoluteOpen,
            })}
            classes={{
              paper: clsx(classes.MuiPaperRoot, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              }),
            }}
          >
            <div
              className="d-flex drawer-profile"

              style={
                this.state.open
                  ? { padding: "16px 26px" }
                  : { padding: "16px 7px" }
              }
              onClick={() => {
                this.setState({ open: false });
                this.props.history.push('/userProfile');
              }}
            >

              <div className="drawer-profile-img-div">
                <FirebaseImage
                  key={this.state.userData?.profileImageUrl ? this.state.userData?.profileImageUrl : `default-user`}
                  src={
                    this.state.userData && this.state.userData?.profileImageUrl
                      ? this.state.userData?.profileImageUrl
                      : null
                  }
                  className={"drawer-profile-img"}
                />
              </div>
              <div style={{ marginLeft: "15px", position: "relative" }}>
                <span className="drawer-profile-name-align">
                  <div className="drawer-profile-name">
                    {`${this.state.userData && this.state.userData?.fullName
                      ? this.state.userData.fullName
                      : ""
                      }`}
                  </div>
                  <div className="drawer-profile-desi">  {`${this.state.userData && this.state.userData?.role ? capitalizeText(this.state.userData.role) : ''} `} </div>
                </span>
              </div>
            </div>
            <Divider />
            <List className={"menu-list-custom"}>
              {menuList?.map((menu, index) => (
                <ListItem
                  key={menu?.name}
                  onClick={() => {
                    this.onMenuSelect(menu);
                  }}
                  className={clsx('pad',
                    selectedMenu == menu?.name ? "selected-menu" : "")
                  }
                >
                  <ListItemIcon>{selectedMenu == menu?.name ? menu?.selected_icon : menu?.icon}</ListItemIcon>
                  <ListItemText
                    primary={menu?.name}
                    className="menuText"
                  />
                </ListItem>
              ))}
              {this.state.open ?
                <div className="version-text">App version 1.0</div> : null}
            </List>
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <Drawer
            // variant="persistent"
            anchor="left"
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
              [classes.mainDrawerCSS]: !this.state.isMenuClicked
            })}
            classes={{
              paper: clsx(classes.MuiPaperRoot, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              }),
            }}
          >
            <div
              className="d-flex drawer-profile"

              style={
                this.state.open
                  ? { padding: "16px 26px" }
                  : { padding: "16px 7px" }
              }
              onClick={() => {
                this.setState({ open: false });
                this.props.history.push('/userProfile');
              }}
            >

              <div className="drawer-profile-img-div">
                <FirebaseImage
                  key={this.state.userData?.profileImageUrl ? this.state.userData?.profileImageUrl : `default-user`}
                  src={
                    this.state.userData && this.state.userData?.profileImageUrl
                      ? this.state.userData?.profileImageUrl
                      : null
                  }
                  className={"drawer-profile-img"}
                />
              </div>
              <div style={{ marginLeft: "15px", position: "relative" }}>
                <span className="drawer-profile-name-align">
                  <div className="drawer-profile-name">
                    {`${this.state.userData && this.state.userData?.fullName
                      ? this.state.userData.fullName
                      : ""
                      }`}
                  </div>
                  <div className="drawer-profile-desi">  {`${this.state.userData && this.state.userData?.role ? capitalizeText(this.state.userData.role) : ''} `} </div>
                </span>
              </div>
            </div>
            <Divider />
            <List className={"menu-list-custom"}>
              {menuList?.map((menu, index) => (
                <ListItem
                  key={menu?.name}
                  onClick={() => {
                    this.onMenuSelect(menu);
                  }}
                  className={clsx('pad',
                    selectedMenu == menu?.name ? "selected-menu" : "")
                  }
                >
                  <ListItemIcon>{selectedMenu == menu?.name ? menu?.selected_icon : menu?.icon}</ListItemIcon>
                  <ListItemText
                    primary={menu?.name}
                    className="menuText"
                  />
                </ListItem>
              ))}
              {this.state.open ?
                <div className="version-text">App version 1.0</div> : null}
            </List>
          </Drawer>
        </Hidden>
        {confirmAlert(strings.logout_msg, "Yes", "Cancel", this.state.showConfirmAlert, "", () => this.onConfirmLogout(), () => this.onCancelLogout())}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  userData: state.auth.loginUserData,
});

export default withRouter(compose(connect(mapStateToProps, { saveAuthData, saveLoginUserData, saveFilterData }), (withStyles(styles, { withTheme: true })))(DrawerComponent));
//  ,(withStyles(styles, { withTheme: true })(DrawerComponent));