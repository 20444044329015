import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { data } from './locales/data';
let strings = new LocalizedStrings(data);

let myInterceptor;
let refreshToken;
let auth;

// export let HOST_SERVER_NAME = 'UAT';
export let HOST_SERVER_NAME = 'DEV';
export let OAUTH2_CLIENT_ID = 'ventyour-web-app';
let OAUTH2_CLIENT_PASSWORD = 'iT4_lC2.wJ8)tI3%bS9,fD3$iX6}fE0*oA6$aB2)lX8_dE6>sE7@eG8>vZ9<hI7<';

const getHostURL = () => {
    if (HOST_SERVER_NAME == 'UAT') {
        return 'https://uat.ventyour.com/ventyour';
    } else if (HOST_SERVER_NAME == 'DEV') {
        return 'https://dev.ventyour.com/ventyour';
    }
    return 'https://uat.ventyour.com/ventyour';
}

export function setAuthToken(token, refreshtoken) {
    auth = token ? "Bearer " + token : "";
    refreshToken = refreshtoken ? refreshtoken : "";
}

if (!myInterceptor) {
    //let that = this;
    myInterceptor = axios.interceptors.request.use(
        function (config) {
            config.timeout = 0.5 * 60 * 1000; // 0.5 min
            if (auth) {
                // console.log("auth =====================>", auth);
                config.headers["Authorization"] = auth;
            }
            config.headers["Content-Type"] = "application/json";
            config.headers["Accept-Language"] = "en";
            config.headers["Device-Id"] = "awd";
            config.headers["Device-Type"] = "windows";
            config.headers["App-Version"] = "0.0.1";
            config.headers["Device-Name"] = "moto";
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            // console.log("response========>", response);
            if (response && (response?.data?.code === 200 || response?.code === 200)) {
                // return response.data;
                return Promise.resolve(response.data);
                //change to promise
            }
            return Promise.reject(response);
        },
        async function (error) {
            const originalRequest = error.config;
            // console.log("error========>", error, error && error.response && error.response.status === 401 && !originalRequest._retry);
            if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const access_token = await refreshAccessToken();
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + access_token;
                return axios(originalRequest);
            } else if (error.message && error.message.toLowerCase().indexOf("timeout") > -1) {
                error.data = {};
                error.data.message = strings.ERROR_SERVER_MESSAGE;
            } else if (error.message && error.message.toLowerCase().indexOf("network") > -1) {
                error.data = {};
                error.data.message = strings.ERROR_NETWORK_MESSAGE;
            }
            return Promise.reject(error);
        }
    );
}

export function refreshAccessToken(body) {
    const basicAuthData = "Basic " + btoa(`${OAUTH2_CLIENT_ID}:${OAUTH2_CLIENT_PASSWORD}`);
    return new Promise((resolve, reject) => {
        //Calling oauth/token api with separate axios instance and config
        let instance = axios.create();
        instance.request({
            baseURL: getHostURL(),
            url: "oauth/token",
            method: "post",
            headers: {
                Authorization: basicAuthData,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Language": "en",
                "Device-Id": "awd",
                "Device-Type": "windows",
                "App-Version": "0.0.1",
                "Device-Name": "moto",
            },
            data: new URLSearchParams({
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            }),
        }).then(function (response) {
            // console.log("oauthLogin response-->", response?.data);
            if (response && response.data && response.status === 200) {
                setAuthToken(response.data.access_token, response.data.refresh_token);
                // Emitter.emit("UPDATE_TOKEN", response.data);
                resolve(response.data.access_token);
                //change to promise
            }
            reject(response);
        }).catch(function (error) {
            // console.log("oauthLogin error -->", error);
            //   Emitter.emit("LOGOUT", {});
            reject(error);
        });
    });
}

export function loginAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/auth/email/login`, body);
}

export function sendOTPAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/otp/send/mail`, body);
}

//qualification and experience
export function addQualAndExp(role, body) {//FLIGHT_ATTENDANT // PILOT
    let context = role === 'PILOT' ? 'pilot' : 'flight/attendant';
    return axios.post(`${getHostURL()}/api/v1/${context}/qualification/and/experience`, body);
}

export function addUpdateAircraftInfo(role, body) {
    let context = role === 'AIRCRAFT_OWNER' ? 'aircraft/owner' : 'aviation/company';
    return axios.post(`${getHostURL()}/api/v1/${context}/aircraft/information`, body);
}

export function updateQualAndExp(role, body) {
    let context = role === 'PILOT' ? 'pilot'
        : role === 'FLIGHT_ATTENDANT' ? 'flight/attendant'
            : role === 'AIRCRAFT_OWNER' ? 'aircraft/owner'
                : 'aviation/company';
    return axios.put(`${getHostURL()}/api/v1/${context}/qualification/and/experience`, body);
}

export function saveOrUpdatePersonalInfo(body) {
    return axios.post(`${getHostURL()}/api/v1/user/personal/information`, body);
}

export function saveOrUpdateAboutMe(body) {
    return axios.post(`${getHostURL()}/api/v1/user/about/me`, body);
}

export function getProfile(role) {
    let context = role === 'PILOT' ? 'pilot'
        : role === 'FLIGHT_ATTENDANT' ? 'flight/attendant'
            : role === 'AIRCRAFT_OWNER' ? 'aircraft/owner'
                : 'aviation/company';
    return axios.get(`${getHostURL()}/api/v1/${context}/profile`);
}

export function getMasterAircraftType() {
    return axios.get(`${getHostURL()}/api/v1/master/aircraft/type`);
}

export function getMasterLocation() {
    return axios.get(`${getHostURL()}/api/v1/master/location`);
}

export function getEducationalQualification() {
    return axios.get(`${getHostURL()}/api/v1/master/educational/qualification`);
}

export function signupVerifyCodeAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/otp/signup/verify`, body);
}

export function forgotPasswordVerifyCodeAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/otp/forgot/password/verify`, body);
}

export function resetPasswordAPI(body) {
    return axios.put(`${getHostURL()}/api/v1/auth/reset/password`, body);
}

export function changePasswordAPI(body) {
    return axios.put(`${getHostURL()}/api/v1/auth/change/password`, body);
}


export function addNewCardAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/payment/manage/addCreditCard`, body);
}

export function getAllCardListAPI() {
    return axios.get(`${getHostURL()}/api/v1/payment/manage/getAllCreditCards`);
}

export function setDefaultCardAPI(body) {
    return axios.put(`${getHostURL()}/api/v1/payment/manage/setDefaultCreditCard`, body);
}

export function removeSavedCardAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/payment/manage/removeCreditCard`, body);
}

export function getBankDetailsAPI() {
    return axios.get(`${getHostURL()}/api/v1/payment/manage/getBank`);
}

export function getCountryListAPI() {
    return axios.get(`${getHostURL()}/api/v1/payment/manage/getAllCountries`);
}

export function addBankDetailsAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/payment/manage/addBank`, body);
}

export function deleteBankDetailsAPI() {
    return axios.delete(`${getHostURL()}/api/v1/payment/manage/deleteBank`);
}

export function getUserAvailabilityAPI(body) {
    let context = 'startDateTime=' + body?.startDateTime + '&endDateTime=' + body?.endDateTime;
    return axios.get(`${getHostURL()}/api/v1/user/availability?${context}`);
}

export function markDateAvailabilityAPI(body) {
    return axios.post(`${getHostURL()}/api/v1/user/mark/available`, body);
}