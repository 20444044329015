import { AUTH_DATA, USER_DATA, LOGIN_USER_DATA, OTHER_USER_DATA, FILTER_DATA, SEARCH_DATA, CHAT_USER_DATA } from "../actions/types";

const initialState = {
  authData: undefined,
  rememberMeData: undefined,
  loginUserData: undefined,
  otherUserData: undefined,
  filterData: undefined,
  chatUserData: undefined,
  searchData: undefined,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_DATA:
      return {
        ...state,
        authData: action.payload,
      };
    case USER_DATA:
      return {
        ...state,
        rememberMeData: action.payload,
      };

    case LOGIN_USER_DATA:
      return {
        ...state,
        loginUserData: action.payload,
      };

    case OTHER_USER_DATA:
      return {
        ...state,
        otherUserData: action.payload,
      };
    case FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      }
    case CHAT_USER_DATA:
      return {
        ...state,
        chatUserData: action.payload,
      }
    case SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      }
    default:
      return state;
  }
}
