export function validateEmail(email) {
    var pattern = new RegExp(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/);
    return pattern.test(email);
}

export function validatePassword(password) {
    var pattern = new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{6,50}$/);
    return pattern.test(password);
}

export function validateTextOnly(inputText) {
    var pattern = new RegExp(/^[a-zA-Z ]{1,50}$/);
    return pattern.test(inputText);
}

export function validateEqualInputs(input1, input2) {
    return input1 === input2;
}

export function validateWebsite(website) {
    if (!website.startsWith("https://")) {
        if (website.startsWith("http://")) {
            website.replace("http://", "https://");
        } else {
            website = "https://" + website;
        }
    }
    let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let pattern = new RegExp(expression);
    return pattern.test(website);
}
