//Language
export const SET_LANG = 'SET_LANG';

//Auth
export const AUTH_DATA = "AUTH_DATA";
export const USER_DATA = "USER_DATA";
export const LOGIN_USER_DATA = "LOGIN_USER_DATA";

//OTHER USER DATA
export const OTHER_USER_DATA = "OTHER_USER_DATA";

//Filter DATA
export const FILTER_DATA = "FILTER_DATA";

//CHAT DATA
export const CHAT_USER_DATA = "CHAT_USER_DATA";
//Search DATA
export const SEARCH_DATA = "SEARCH_DATA";

