import moment from 'moment';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { saveChatUserData, saveOtherUserData } from '../../actions/auth';
import NoNotificationIcon from '../../assets/images/ic_no_natifications.svg';
import Emitter from '../../utils/Emitter';
import { deleteAllNotifications, getNotificationsFB, getUser, markAsReadAllNotifications, updateReadStatusFB } from '../../utils/firebase_helper/auth';
import { data } from '../../utils/locales/data';
import './notification-list.scss';

let strings = new LocalizedStrings(data);

class NotificationListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageSize: 15,
            notificationList: [],
            showLoadPreviousMessage: true,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getNotifications(false);
    }

    handleSearchShowHide = showSearchItems => {
        this.setState({ showSearchItems: showSearchItems });
    }

    handleSearch = searchText => {
        let trimmedText = searchText.trim();
        let filteredList = this.state.registeredUsers;
        if (trimmedText && filteredList?.length) {
            filteredList = filteredList.filter(item => {
                return item?.fullName
                    ?.toLowerCase()
                    .includes(trimmedText.toLowerCase());
            });
        }
        this.setState({ filteredList });
    }

    //Fetch data for next page
    fetchNextPage() {
        if (this.state.showLoadPreviousMessage && !this.state.showFooterLoader) {
            this.loadNextUsers()
        }
    }

    loadNextUsers = () => {
        if (this.state?.notificationList && this.state?.notificationList?.length) {
            this.setState({ showFooterLoader: true });
            this.getNotifications(true)
        }
    }

    timeSince = (date) => {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    openProfile = (item) => {
        // console.log('in openProfile fun...');
        getUser(item?.senderId).then(res => {
            if (res) {
                // console.log('my other profile ==> ', res);
                this.props.saveOtherUserData(res);
                this.props.closeNotificationMenu();
                this.props.history.push('/viewprofile')
            }
        }).catch(error => {
            // console.log('my other profile err ==> ', error);
            // setIsLoading(false);
            // setAlertMsg(error.message)
            this.setState({ showAlert: true, alertMsg: error.message })
        });
    }

    getNotifications = (isLoadPrevious) => {
        return new Promise((resolve, reject) => {
            getNotificationsFB(isLoadPrevious, this.state.pageSize).then((userlist) => {
                this.setState({ showFooterLoader: false, showNoResultBar: true, isLoading: false });
                // console.log('my get notification ==> ', userlist);
                if (userlist) {
                    if (userlist.length < this.state.pageSize) {
                        this.setState({ showLoadPreviousMessage: false });
                    }
                    if (isLoadPrevious) {
                        let previousMessages = this.state.notificationList.concat(userlist)
                        this.setState({ notificationList: previousMessages, showFooterLoader: false }, () => {
                            resolve();
                        });

                    } else {
                        this.setState({ notificationList: userlist }, () => {
                            resolve();
                        });
                    }
                } else if (!userlist) {
                    this.setState({ showLoadPreviousMessage: false, notificationList: [] }, () => {
                        resolve();
                    });
                }
            }).catch(err => {
                this.setState({ showNoResultBar: true })
                reject(err)
            })
        });
    }

    updateReadStatus = (item, index) => {
        // console.log("aa ----- item=>", item);
        if (!item.isNotiRead) {
            this.state.notificationList[index].isNotiRead = true;
            item.isNotiRead = true;
            this.setState({ update: true, notificationList: this.state.notificationList });
            updateReadStatusFB(item?.id);
            if (item.notification_type == "CHAT_MESSAGE") {
                getUser(item?.senderId).then(res => {
                    if (res) {
                        this.props.saveChatUserData(res);
                        this.props.closeNotificationMenu();
                        this.props?.onMenuSelect({ name: 'Messages' })
                        this.props.history.push('/chats');
                    }
                }).catch(error => {
                    // console.log('my other profile err ==> ', error);
                    this.props.closeNotificationMenu();
                    this.props?.onMenuSelect({ name: 'Messages' })
                    this.props.history.push('/chats');
                });
                // this.props.closeNotificationMenu();
                // this.props.saveChatUserData(item);
                // this.props?.onMenuSelect({ name: 'Messages' })
                // this.props.history.push('/chats')
            }
            Emitter.emit('NOTI_UPDATE', 1);
        } else {
            getUser(item?.senderId).then(res => {
                if (res) {
                    console.log('my other profile ==> ', res);
                    this.props.saveChatUserData(res);
                    this.props.closeNotificationMenu();
                    this.props?.onMenuSelect({ name: 'Messages' })
                    this.props.history.push('/chats');
                }
            }).catch(error => {
                console.log('my other profile err ==> ', error);
                    this.props.closeNotificationMenu();
                    this.props?.onMenuSelect({ name: 'Messages' })
                    this.props.history.push('/chats');
            });
        }
    }

    clearAll = () => {
        deleteAllNotifications().then(() => {
            this.setState({ notificationList: [] })
        })
    }

    updateReadStatusLocally = () => {
        let items = []
        this.state.notificationList?.forEach((element) => {
            element.isNotiRead = true
            items.push(element);
        });
        this.setState({ notificationList: items })
    }

    markAllAsRead = () => {
        markAsReadAllNotifications().then(() => {
            // console.log('markAllAsRead success');
            this.updateReadStatusLocally();
        })
    }

    renderListItem = (item, index) => {
        let isRead = item?.isNotiRead;
        let time = '';
        time = this.timeSince(moment.unix(item?.sentAt?.seconds)) + ' ago';
        return (
            <div
                className="list-item" key={`_${index}`}
                onClick={() => this.updateReadStatus(item, index)} >
                <div className="left-section">
                    <div className="text-info-wrapper">
                        <div className={`user-name-text ${!isRead ? 'unread-text' : ''}`}>{item?.fullName}</div>
                        <div className={`msg-text ${!isRead ? 'unread-text' : ''}`}>Sent a new message.</div>
                        <div className={`msg-time ${!isRead ? 'unread-text-time' : ''}`}>{time}</div>
                    </div>
                </div>
                <div className="right-section">
                    {!isRead ? <div className="unread-badge"></div> : null}
                </div>
            </div>
        )
    }

    renderEmptyNotification = () => {
        return (
            <div className="empty-container">
                <div className="content">
                    <img
                        src={NoNotificationIcon}
                        className="empty-notification-icon"
                        alt={'No-notification'}
                    />
                    <div className="empty-text">{strings.no_notifications}</div>
                </div>
            </div>
        )
    }

    render() {
        const { notificationList, isLoading } = this.state
        return (
            <div className="notification-list-wrapper">
                <div className="notification-heading">{strings.notifications}</div>
                {notificationList && notificationList.length ?
                    <div className="action-wrapper">
                        <div
                            className="all-noti-read-text"
                            onClick={() => this.markAllAsRead()}
                        >{strings.mark_all_as_read}</div>
                        <div
                            className="clear-all-text"
                            onClick={() => this.clearAll()}
                        >{strings.clear_all}</div>
                    </div> : null}
                <div className="list-container">
                    {notificationList && notificationList.length
                        ? notificationList.map((item, index) => {
                            return (
                                this.renderListItem(item, index)
                            )
                        })
                        : notificationList && notificationList.length === 0 && !isLoading
                            ? this.renderEmptyNotification()
                            : null}
                    {notificationList && notificationList.length && notificationList.length % this.state.pageSize === 0
                        ? <div
                            className="load-more-text"
                            onClick={() => this.fetchNextPage()}>Load More</div>
                        : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    language: state.language
});

export default connect(mapStateToProps, { saveOtherUserData, saveChatUserData })(withRouter(NotificationListComponent));