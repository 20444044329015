import React, { useState, useEffect } from "react";
import { ReactComponent as PlayIcon } from "../../assets/images/ic_playIcon.svg";
import { getMediaUrl } from "../../utils/firebase_helper/auth";
import ReactPlayer from 'react-player'
import './firebaseVideo.scss'


function FirebaseImage(props) {
    const [path, setPath] = useState("");
    const [thumbnail, setThumbnailPath] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const getPath = async () => {
        setIsLoading(true);
        await getMediaUrl(props.src)
            .then((data) => {
                setPath(data?.url);
                setIsLoading(false);
                return
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getThumbnailPath = async () => {
        setIsLoading(true);
        await getMediaUrl(props.thumbnail)
            .then((url) => {
                setThumbnailPath(url?.url);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    if (props.src && !isLoading && !path) {
        if( props && props.from === 'upload'){
            setPath(props.src)
        } else {
            getPath();
        }
    }
    if (props.thumbnail && !isLoading && !thumbnail) {
        getThumbnailPath();
    }

    useEffect(() => {
        getThumbnailPath();
        getPath();
    }, [props.src])

    return (
        <div className='player-wrapper-profile'>
            <ReactPlayer
                style={{ marginTop: '12px', borderRadius: '10px' }}
                key={path}
                width='100%'
                height='100%'
                className='react-player-profile'
                url={path ? path : true}
                controls
                autoPlay
                muted={false}
                // playing={true}
                volume={1}
                playing={props && props.from === 'list' ? props.index === props.currentIndex ? true : false : true}
                stopOnUnmount={false}
                playIcon={<PlayIcon width='85px' height='85px' />}
                previewTabIndex={props.index}
                light={thumbnail ? thumbnail : true}
                onPlay={props && props.from === 'list' ? () => {props.onChangeIndex(props.index);} : () => {}}
                onClickPreview={props && props.from === 'list' ? () => {props.onChangeIndex(props.index);} : () => {}}
            />
        </div>
    );
}



export default FirebaseImage;
