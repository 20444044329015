import React, { useEffect, useState } from 'react'
import { getDividedSearchData } from '../../utils/firebase_helper/auth'
import { saveOtherUserData, saveChatUserData, saveFilterData } from '../../actions/auth';
import FirebaseImage from '../../components/firebase_fast_image_view/index';
import { getUser } from '../../utils/firebase_helper/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const SearchComponent = (props) => {

    const [userData, setUserData] = useState(null)
    const [bio, setBio] = useState(null)
    const [business, setBusiness] = useState(null)
    const [type, setType] = useState(props?.location?.state?.flag)
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    useEffect(() => {
        getDividedSearchData(props?.searchData?.toString(), 10).then(e => {
            setUserData(e.nameSearch)
            setBusiness(e.businessNameSearch)
            setBio(e.bioSearch)
        })
    }, [])

    const openProfile = (item) => {
		getUser(item?.userId).then(res => {
			if (res) {
				console.log('my other profile ==> ', res);
				props.saveOtherUserData(res);
				// this.props.closeNotificationMenu();
				props.history.push('/viewprofile')
			}
		}).catch(error => {
			console.log('my other profile err ==> ', error);
			// setIsLoading(false);
			// setAlertMsg(error.message)
            setShowAlert(true);
            setAlertMsg(error.message);
		});
	}


    const renderSearchUsersList = () => {
        return (
            <>
                <div className="list-header">
                    Users
                </div>
                {userData?.map((item, index) => {
                    return (
                        <div 
                         onClick={() => {openProfile(item)}}
                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginLeft: '15px' }}>
                            <div className="search-avatar-div">
                                <FirebaseImage
                                    key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-user`}
                                    src={item?.profileImageUrl ? item?.profileImageUrl : null}
                                    className={"search-avatar-img"}
                                />
                            </div>
                            <div>
                                <span className="option_title">{item?.fullName}</span>
                                <span className="option_role">{item?.role}</span>
                                {/* <div className="option_des" >{item?.businessInfo?.name}</div> */}
                            </div>

                        </div>)
                })}



            </>
        )
    }

    const renderSearchBioList = () => {
        return (
            <>
                <div className="list-header">
                    Bio
                </div>
                {bio?.map((item, index) => {
                    return (
                        <div
                        onClick={() => {openProfile(item)}}
                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginLeft: '15px' }}>
                            <div className="search-avatar-div">
                                <FirebaseImage
                                    key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-bio`}
                                    src={item?.profileImageUrl ? item?.profileImageUrl : null}
                                    className={"search-avatar-img"}
                                />
                            </div>
                            <div>
                                <span className="option_title">{item?.fullName}</span>
                                <span className="option_role">{item?.role}</span>
                                <div className="option_des_search" >{item?.bio}</div>
                            </div>

                        </div>)
                })}



            </>
        )
    }

    const renderSearchBusinessList = () => {
        return (
            <>
                <div className="list-header">
                    Business
                </div>
                {business?.map((item, index) => {
                    return (
                        <div
                        onClick={() => {openProfile(item)}}
                         style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginLeft: '15px' }}>
                            <div className="search-avatar-div">
                                <FirebaseImage
                                    key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-business`}
                                    src={item?.profileImageUrl ? item?.profileImageUrl : null}
                                    className={"search-avatar-img"}
                                />
                            </div>
                            <div>
                                <span className="option_title">{item?.fullName}</span>
                                <span className="option_role">{item?.role}</span>
                                <div className="option_des_search" >{item?.businessInfo?.name}</div>
                            </div>

                        </div>)
                })}



            </>
        )
    }

    const renderView = () => {
        console.log('type---', type);
        if (type === "users") {
            return (
                renderSearchUsersList()
            )
        } else if (type === "bio") {
            console.log('bio-----');
            return (
                renderSearchBioList()
            )
        } else if (type === "business") {
            return (
                renderSearchBusinessList()
            )
        }
    }
    return (
        <>
            {renderView()}
        </>
    )
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    language: state.language,
    filterData: state.auth.filterData,
    searchData: state.auth.searchData,

});
export default withRouter(connect(mapStateToProps, { saveOtherUserData, saveFilterData, saveChatUserData })(SearchComponent))
