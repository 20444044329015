import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from "./components/protected_route";
import NotProtectedRoute from './components/not_protected_route';
import Login from './pages/Login';
import Signup from "./pages/SignUp";
import VerificationCode from './pages/VerificationCode';
import PageNotFound from './pages/PageNotFound';
import Settings from './pages/Settings'
import Chats from './pages/Chats'
import UserProfilePage from "./pages/UserProfile";
import home from "./components/home";
import OtherUserProfilePage from "./components/other-user-profile"
import TermsAndConditions from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Search from "./pages/Search";


export function routing(authData) {
    return (
        <Switch>
            <NotProtectedRoute isAuth={authData} path="/VerificationCode" component={VerificationCode} />
            <NotProtectedRoute isAuth={authData} path="/login" component={Login} />
            <NotProtectedRoute isAuth={authData} path="/signup" component={Signup} />
            <NotProtectedRoute isAuth={authData} path="/terms_of_service" component={TermsAndConditions} />
            <NotProtectedRoute isAuth={authData} path="/privacy_policy" component={PrivacyPolicy} />
            <ProtectedRoute isAuth={authData} path="/settings" component={Settings} />
            <ProtectedRoute isAuth={authData} path="/chats" component={Chats} />
            <ProtectedRoute isAuth={authData} path="/home" component={home} />
            <ProtectedRoute isAuth={authData} path="/userprofile" component={UserProfilePage} />
            <ProtectedRoute isAuth={authData} path="/terms_and_conditions" component={TermsAndConditions} />
            <ProtectedRoute isAuth={authData} path="/policy" component={PrivacyPolicy} />
            <ProtectedRoute isAuth={authData} path="/search" component={Search} />
            <ProtectedRoute isAuth={authData} path="/viewprofile" component={OtherUserProfilePage} />
            <Redirect path="/" exact to={authData ? "/home" : "/login"} />
            <Redirect to={authData ? "/home" : "/login"} />
            <Redirect path="*" component={PageNotFound} />
        </Switch>
    );
}
