import React, { useState } from "react";
import { ReactComponent as PlayIcon } from "../assets/images/ic_playIcon.svg";
import { getMediaUrl } from "../utils/firebase_helper/firestore";
import ReactPlayer from 'react-player'
import './firebaseVideo.scss'


function FirebaseImage(props) {
    const [path, setPath] = useState("");
    const [thumbnail, setThumbnailPath] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const getPath = () => {
        setIsLoading(true);
        getMediaUrl(props.src)
            .then((url) => {
                setPath(url);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getThumbnailPath = () => {
        setIsLoading(true);
        getMediaUrl(props.thumbnail)
            .then((url) => {
                setThumbnailPath(url);
                
        console.log('Thumbnail', url)
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    if (props.src && !isLoading && !path) {
        getPath();
    }
    if (props.thumbnail && !isLoading && !thumbnail) {
        getThumbnailPath();
    }

    return (
        <div className='player-wrapper'>
            <ReactPlayer
                style={{ marginTop: '12px' }}
                width='100%'
                height='100%'
                className='react-player'
                url={path ? path : true}
                controls
                muted={false}
                playing={true}
                stopOnUnmount={false}
                playIcon={<PlayIcon />}
                previewTabIndex={props.index}
                light={thumbnail ? thumbnail : true}

            />
        </div>
    );
}



export default FirebaseImage;
