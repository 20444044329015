import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import { Provider } from 'react-redux';
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';

function App() {

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  
  useEffect(() => {
    OneSignal.push(()=> {
      OneSignal.init(
        {
          appId: "b504b319-5cd8-495d-b683-680936038c4b",
      });
    });
  }, [OneSignal]);


  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Layout />
        </PersistGate>
        <ToastContainer position="top-center" autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </BrowserRouter>
  );
}
export default App