import moment from 'moment';
import LocalizedStrings from 'react-localization';
import {data} from '../../../utils/locales/data';
// import { translate } from '../../../utils/language-manager';
/*
 * Method use to return time string ex: "10:30 pm"
 * @param: time is in long
 */
let strings = new LocalizedStrings(data);

export function formatAMPM(time) {
    let date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    let strTime = hours + ':' + minutes + '' + ampm;
    return strTime;
}

/*
 * Method use to return day 
 */
export function getChatMessageDateTime(time) {
    let a = moment().startOf('day');
    let b = moment(time).startOf('day');
    let days = a.diff(b, 'days');
    let displayDate;
    if (days == 0) {
        displayDate = strings.today;
    } else if (days == 1) {
        displayDate = strings.yesterday;
    } else {
        displayDate = getDateFormat(time);
    }
    return displayDate;
}

/*
 * Method use to return date string
 */
/*
 * Method use to return date string
 */
export function getChatListTime(time) {
    var a = moment().startOf('day');
    var b = moment(time).startOf('day');
    let days = a.diff(b, 'days');
    let displayDate;
    if (days == 0) {
        displayDate = formatAMPM(time);
        // } else if (days < 365) {
        // displayDate = moment(time).format("MMM DD, ") + formatAMPM(time);
        // displayDate = moment(time).format("MMM DD");
    } else {
        // displayDate = moment(time).format("MMM DD, YYYY, ") + formatAMPM(time);
        displayDate = moment(time).format("MMM DD, YYYY");
    }
    return displayDate;
}

/*
 * Method use to return formated date string ex: "MM/dd/YYYY"
 * @param: time is in long
 */
export function getDateFormat(time) {
    let date = new Date(time);
    return (("0" + date.getDate()).slice(-2)) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
}

/*
 * Method use to check given 2 dates are same or not.
 * @param: date1 is in long
 * @param: date2 is in long
 */
export function isSameDate(date1, date2) {
    if (date1 && date2) {
        let d1 = new Date(date1);
        d1.setHours(0);
        d1.setMinutes(0);
        d1.setSeconds(0);
        d1.setMilliseconds(0);
        let d2 = new Date(date2);
        d2.setHours(0);
        d2.setMinutes(0);
        d2.setSeconds(0);
        d2.setMilliseconds(0);
        return d1.getTime() == d2.getTime()
    }
    return false;
}

/*
*Show msg time if difference between current and last msg is 1 mins or more
*/
export function canShowMsgTime(date1, date2) {
    let a = moment.utc(date1).local();
    let b = moment.utc(date2).local();
    let mins = b.diff(a, 'minutes');
    return mins >= 1;
}