import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";

const LoadingSpinner = (props) => {
    return(
        <div className="loading-overlay">  
            <PulseLoader color={'#FFFFFF'} loading={true} size={15} />
        </div>
    );
}

export default LoadingSpinner