import React from 'react';
import LocalizedStrings from 'react-localization';
import { data } from '../utils/locales/data';
import PNFIcon from '../assets/page_not_found.png';
import EmptyActionComponent from '../components/empty-action';

let strings = new LocalizedStrings(data);
const PageNotFound = props => {
    return (
        <EmptyActionComponent icon={PNFIcon} message={strings.page_not_found_label} />
    );
}
export default PageNotFound;