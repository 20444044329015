import moment from 'moment';

// long to any format
export function getFormatedDate(time, format = 'MMM DD, yyyy  hh:mm A') {
    return moment(time).format(format);
}

/*
 * Method use to return seconds to MM:SS 
 */
export function secToMinutes(sec) {
    let minutes = ("0" + Math.floor(sec / 60)).slice(-2);
    let seconds = ("0" + sec % 60).slice(-2);
    return minutes + ":" + seconds;
}