import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ArrowIcon from '../../assets/images/settings_arrow.svg';
import { showAlert } from '../../utils/common_helper';
import { data } from '../../utils/locales/data';
import NotificationSettings from './notification-settings';
import './settings.scss';

let strings = new LocalizedStrings(data);

class SettingsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isNotificationSettingModal: false,
            terms_privacy_base_url: '',
            showAlertFlag: false,
        }
    }

    handleSettingsClick = (item) => {
        if (item === 'notifications') {
            this.setState({ isNotificationSettingModal: true })
        } else if (item == 'Privacy Policy') {
            this.props.history.push('/policy')
        } else if (item == 'Terms of Service') {
            this.props.history.push('/terms_and_conditions')
        } else {
            this.setState({ showAlertFlag: true });
        }
    }

    closeAlerts = () => {
        this.setState({ showAlertFlag: false });
    }

    onCloseNotificationModal = () => {
        this.setState({ isNotificationSettingModal: false });
    }

    openURLS = (type) => {
        const { terms_privacy_base_url } = this.state;
        let link = document.createElement('a');
        link.setAttribute('target', '_blank');
        if (type === 'Privacy Policy') {
            link.setAttribute('href', `${terms_privacy_base_url}privacy-policy.html`);
            link.click();
        } else if (type === 'Terms of Service') {
            link.setAttribute('href', `${terms_privacy_base_url}terms-of-service.html`);
            link.click();
        }
    }

    render() {
        const { isNotificationSettingModal, showAlertFlag } = this.state;
        return (
            <>
                <div className="settings-wrapper">
                    <div className="heading-container">
                        {strings.setting}
                    </div>
                    <div className="settings-list-container">
                        <div
                            className="setting-item"
                            onClick={() => this.handleSettingsClick('notifications')}>
                            <div className="item-name">{strings.notifications}</div>
                            <div className="item-action">
                                <IconButton
                                    color="#FFF"
                                    aria-label="open drawer"
                                    edge="start"
                                    style={{ marginLeft: 0, color: 'white', width: 36 }}
                                >
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                </IconButton>
                                <NotificationSettings modalStatus={isNotificationSettingModal} onClose={this.onCloseNotificationModal} />
                            </div>
                        </div>
                        <div
                            className="setting-item"
                            onClick={() => this.handleSettingsClick('Privacy Policy')}>
                            <div className="item-name">{strings.privacy_policy}</div>
                            <div className="item-action">
                                <IconButton
                                    color="#FFF"
                                    aria-label="open drawer"
                                    edge="start"
                                    style={{ marginLeft: 0, color: 'white', width: 36 }}
                                >
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                </IconButton>
                            </div>
                        </div>
                        <div
                            className="setting-item"
                            onClick={() => this.handleSettingsClick('Terms of Service')}>
                            <div className="item-name">{strings.terms_service}</div>
                            <div className="item-action">
                                <IconButton
                                    color="#FFF"
                                    aria-label="open drawer"
                                    edge="start"
                                    style={{ marginLeft: 0, color: 'white', width: 36 }}
                                >
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    {showAlert('Coming Soon...', 'Ok', showAlertFlag, '', () => this.closeAlerts())}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
}
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsComponent))