import { AppBar, Backdrop, Button, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { saveChatUserData, saveOtherUserData } from '../../../actions/auth';
import { showAlert as showAlertModal } from '../../../utils/common_helper';
import { data } from '../../../utils/locales/data';
import AttachmentIcon from '../assets/images/ic_attachment.svg';
import LoaderIcon from '../assets/images/ic_load_previous.png';
import NoCoversationIcon from '../assets/images/ic_no_conversation.svg';
import RecentChatImageIcon from '../assets/images/ic_photo_chat_list.svg';
import { ReactComponent as VideoPlayIcon } from '../assets/images/ic_playIcon.svg';
import SearchIcon from '../assets/images/ic_search.svg';
import SendMessageIcon from '../assets/images/ic_send_message.svg';
import SendMessageWhiteIcon from '../assets/images/ic_send_message_white.svg';
import RecentChatVideoIcon from '../assets/images/ic_video_chat_list.svg';
import FirebaseImage from '../firebase_fast_image_view';
import FirebaseVideo from '../firebase_video_view';
import ImageCropper from '../image_cropper';
import { showSpinner } from '../utils/common_helper';
import { canShowMsgTime, formatAMPM, getChatListTime, getChatMessageDateTime, isSameDate } from '../utils/date-time-helper';
import { getChatMessages, getFriendList, removeSnapshot, sendChatMsg, setChatListSnapshot, setChatSetting, setChatSettingSnapshot, setChatSnapshot, updateLastMessage, updateOnlineUser } from '../utils/firebase_helper/firestore';
import { uploadFileBase64 } from '../utils/firebase_helper/store';
import VideoPreview from '../video_preview';
import './chat.scss';

let strings = new LocalizedStrings(data);
class ChatComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageSrc: null,
            videoSrc: null,
            videoBase64: null,
            newMessageText: '',
            data: [],
            filteredList: [],
            searchText: '',
            isLoading: true,
            isListLoading: true,
            isMSGLoading: true,
            selectedChatListPerson: props.chatUserData ?? null,
            message: "",
            textInputHeight: 47,
            messageData: [],
            senderData: props.authData,
            receiverData: props.chatUserData,
            chatSetting: undefined,
            pageSize: 50,
            previewImageUrl: '',
            previewVideoUrl: '',
            thumbnail: '',
            alertMsg: '',
            TextAreaRowCount: 0,
        }
        this.attachmentInput = React.createRef();
        this.clickAattachmentInput = this.clickAattachmentInput.bind(this);
        this.getFriendListAPI();
        this.setMyChatListSnapshot();
        // this.focusListener = window.addEventListener('focus', () => {
        //     this.getNotifications(false)
        // });
    }

    setMyChatListSnapshot = () => {
        this.removeMyChatListSnapshot();
        this.state.chatListSnapshot = setChatListSnapshot(() => {
            // console.log("aa -----------------------------------------------------------------------");
            this.getFriendListAPI(true);
            this.scrollToBottom();
        });
    }
    

    removeMyChatListSnapshot = () => {
        if (this.state.chatListSnapshot) {
            removeSnapshot(this.state.chatListSnapshot);
            this.state.chatListSnapshot = null;
        }
    }

    componentWillUnmount = () => {
        this.props.saveChatUserData(this.state.filteredList[0]);
        this.removeMyChatListSnapshot();
        // this.props.saveChatUserData({});
        updateOnlineUser(this.state?.senderData?.userId, this.state?.receiverData?.userId, false);
        this.setState({ selectedChatListPerson: null, messageData: [] });
    }

    componentWillMount = () => {
        this.getMessages(false);
        this.setChatSettingSnapshot();
        // console.log('componentWillMount');
        this.setNewChatSnapshot();
    }

    componentCleanup = () => {
        this.props.saveChatUserData(this.state.filteredList[0]);
        console.log('Clean up')
    }

    componentDidMount = () => {
        document.addEventListener('keypress', this.handleAreaKeyPress, false);
        window.addEventListener('beforeunload', this.componentCleanup);
        this.showLastMessage()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.chatUserData !== this.props.chatUserData && this.props.chatUserData) {
            this.setState({
                selectedChatListPerson: this.props.chatUserData, senderData: this.props.authData,
                receiverData: this.props.chatUserData, messageData: []
            }, () => {
                this.onChatListPersonClick(this.props.chatUserData);
                this.getMessages(false);
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleAreaKeyPress, false);
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    handleAreaKeyPress = (e) => {
        // console.log('e.key ==> ', e)
        if (e.key === 'Enter') {
            this.setState({ TextAreaRowCount: this.state.TextAreaRowCount + 1 });
        } else if (e.keycode == 8 || e.code === "Backspace") {
            // console.log('e.code ==> ', e.code)
            if (this.state.TextAreaRowCount > 0) {
                // console.log('innn')
                this.setState({ TextAreaRowCount: this.state.TextAreaRowCount - 1 }, () => {
                    // console.log('new value ==> ', this.state.TextAreaRowCount)
                });
            }
        }
    }

    getMessages = (isLoadPrevious) => {
        getChatMessages(this.state?.senderData?.userId, this.state?.receiverData?.userId, this.state.pageSize, isLoadPrevious).then((data) => {
            this.setState({ showFooterLoader: false });
            if (data) {
                // console.log('Here, getmessage again')
                if (data.length < this.state.pageSize) {
                    this.setState({ showLoadPreviousMessage: false });
                }
                if (isLoadPrevious) {
                    let previousMessages = this.state.messageData.concat(data)
                    this.setState({ messageData: previousMessages, showFooterLoader: false }, () => {
                        this.setState({ isMSGLoading: false })
                    });
                } else {
                    this.isLoadPreviousLoading = true;
                    this.setState({ messageData: data }, () => {
                        this.showLastMessage();
                        this.setState({ isMSGLoading: false })
                        if (this.state.messageData?.length) {
                            // console.log("aa -------- this.state.messageData[0]=>", this.state.messageData[0]);
                            updateLastMessage(this.state.senderData?.userId, this.state.receiverData?.userId, this.state.messageData[0].created_time);
                        }
                    });
                }
                // console.log('chat data is  ==> ', this.state.messageData)
            } else if (!data) {
                this.setState({ showLoadPreviousMessage: false, isMSGLoading: false });
            }
        })
    }

    loadPreviousMessage() {
        if (this.state.messageData && this.state.messageData.length) {
            this.setState({ showFooterLoader: true });
            this.getMessages(true);
        }
    }

    showLastMessage = () => {
        if (this.state.messageData?.length) {
            if (this.isLoadPreviousLoading) {
                this.scrollToBottom();
            }
            this.isLoadPreviousLoading = false;
        }
    }

    setNewChatSnapshot = () => {
        removeSnapshot(this.chatSnapshot);
        this.chatSnapshot = setChatSnapshot(this.state?.senderData?.userId, this.state?.receiverData?.userId, (data) => {
            if (data) {
                data.item.docId = data.docId;
                if (data.type === 'added') {
                    updateLastMessage(this.state.senderData?.userId, this.state.receiverData?.userId, data.item?.created_time);
                    // console.log('ADD: ', data.item, this.state.messageData[this.state.messageData.length - 1]);
                    this.state.messageData = [data.item].concat(this.state.messageData)
                    this.setState({ messageData: this.state.messageData }, () => {
                        if (data.item.sender_id === this.state?.senderData?.userId) {
                            // scroll only if current user msg
                            this.isLoadPreviousLoading = true;
                            this.showLastMessage()
                        }
                    });
                }
            }
        });
    }

    setChatSettingSnapshot = () => {
        this.chatSettingSnapshot = setChatSettingSnapshot(this.state?.senderData?.userId, this.state?.receiverData?.userId, (response) => {
            if (!this.state.chatSetting && response) {
                updateOnlineUser(this.state?.senderData?.userId, this.state?.receiverData?.userId, true);
            }
            this.setState({ chatSetting: response });
        });
    }

    getFriendListAPI = (isFocusCall) => {
        getFriendList((dataMap) => {
            // this.setState({ isLoading: true }, () => {
            //     console.log('my data update....235')
            //     this.scrollToBottom();
            // });
            // console.log('dataMap', dataMap)
            let data = [];
            Object.keys(dataMap).forEach(key => {
                let item = dataMap[key];
                if (isFocusCall) {
                    item.is_profile_load = true
                }
                data.push(item);
            });
            //Sort a chat list
            if (data?.length) {
                data.sort((a, b) => {
                    if (a?.chat_last_message?.created_time && !b?.chat_last_message?.created_time) {
                        return -1;
                    }
                    if (!a?.chat_last_message?.created_time && b?.chat_last_message?.created_time) {
                        return 1;
                    }
                    if (!a?.chat_last_message?.created_time || !b?.chat_last_message?.created_time) {
                        return 0;
                    }
                    return b?.chat_last_message?.created_time - a?.chat_last_message?.created_time;
                });
            }
            this.state.data = data;
            // console.log('get list data....', data, this.state.selectedChatListPerson)
            if(data && data.length && this.state.selectedChatListPerson === null) {
                this.props.saveChatUserData(data[0]);
                this.setState({selectedChatListPerson: data[0]});
            }
            this.setFilterListData();
        });
    }

    setFilterListData = () => {
        this.state.filteredList = this.state.data;
        if (this.state.searchText?.trim()?.length) {
            this.state.filteredList = this.state.filteredList.filter(item => item?.fullName.toLowerCase().includes(this.state.searchText.toLowerCase()));
        }
        this.setState({ filteredList: this.state.filteredList }, () => {
            this.setState({ isLoading: false, isListLoading: false });
        });
        // console.log('this.state.filteredList', this.state.filteredList)
    }

    clickAattachmentInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.attachmentInput.current.click();
    }

    onCurrentTextChange = (e) => {
        this.setState({ newMessageText: e.target.value })
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState({ isLoading: true });
            let file = e.target.files[0];
            if (file && file.type && file.type.includes('image')) {
                if (file.type.includes('jpg') || file.type.includes('jpeg') ||
                    file.type.includes('png') || file.type.includes('mp4')) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ imageSrc: reader.result }, () => {
                            this.setState({ isImageCropper: true, croppedImage: reader.result })
                            this.setState({ isLoading: false });
                        })
                    );
                    reader.readAsDataURL(e.target.files[0]);
                } else {
                    this.setState({ isLoading: false }, () => {
                        this.setState({
                            showAlert: true,
                            alertMsg: 'This file format is not supported.',
                        });
                    });
                    this.attachmentInput.current.value = '';
                }
            } else if (file && file.type && file.type.includes('video')) {
                this.generateVideoThumbnail(file).then(thumb => {
                    // console.log('Firebase thumb', thumb);
                    this.setState({ videoSrc: URL.createObjectURL(file), videoSelected: true, videoBase64: file, thumbnail: thumb }, () => {
                        this.setState({ isLoading: false });
                        this.setState({ isVideoPreview: true })
                    })
                });
            } else {
                //File Type Error
                this.attachmentInput.current.value = '';
            }
        }
    };

    generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");
            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);
            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                const thumb = canvas.toDataURL("image/png");
                // console.log(thumb)
                uploadFileBase64(thumb, '', 'THUMBNAILS', this.state?.senderData?.userId, 'jpg').then(res => {
                    return resolve(res.metadata.fullPath);
                }).catch(err => {
                    console.log(err)
                    return resolve('');
                });
            };
        });
    };

    closeCropper = () => {
        this.setState({ isImageCropper: false, croppedImage: null, imageSelected: false });
    }

    getCroppedImage = (img) => {
        // console.log('getCroppedImage --->', img)
        this.setState({ croppedImage: img, imageSelected: true });
    }

    closeVideoPreview = () => {
        this.setState({ isVideoPreview: false, videoSrc: null, videoBase64: null, videoSelected: false });
    }

    searchTextChange = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            this.setFilterListData()
        });
    }

    onChatListPersonClick = (item) => {
        updateOnlineUser(this.state?.senderData?.userId, this.state?.receiverData?.userId, false);
        this.props.saveChatUserData(item);
        this.setState({ selectedChatListPerson: item, receiverData: item, messageData: [], isMSGLoading: true, TextAreaRowCount: 0 }, () => {
            // changes snapshot after select other thread
            // console.log('onChatListPersonClick');
            this.setNewChatSnapshot();
        });
        updateOnlineUser(this.state?.senderData?.userId, item?.userId, true);
    }

    renderMessageType = (item) => {
        if (item?.chat_last_message.msg_type == "IMAGE") {
            return (
                <>
                    <img src={RecentChatImageIcon} className="attachment-icon" />
                    <span className={`recent-text ${item?.chat_unread_count > 0 ? 'recent-text-unread' : ''}`}>{item?.chat_last_message?.message && item?.chat_last_message?.message !== '' ? item?.chat_last_message?.message : 'Photo'}</span>
                </>
            )
        } else if (item?.chat_last_message.msg_type == "AUDIO") {
            return (
                <>
                    <img src={RecentChatVideoIcon} className="attachment-icon" />
                    <span className={`recent-text ${item?.chat_unread_count > 0 ? 'recent-text-unread' : ''}`}>{item?.chat_last_message?.message && item?.chat_last_message?.message !== '' ? item?.chat_last_message?.message : 'Audio'}</span>
                </>
            )
        } else if (item?.chat_last_message.msg_type == "VIDEO") {
            return (
                <>
                    <img src={RecentChatVideoIcon} className="attachment-icon" />
                    <span className={`recent-text ${item?.chat_unread_count > 0 ? 'recent-text-unread' : ''}`}>{item?.chat_last_message?.message && item?.chat_last_message?.message !== '' ? item?.chat_last_message?.message : 'Video'}</span>
                </>
            )
        } else {
            return (
                <>
                    <span className={`recent-text ${item?.chat_unread_count > 0 ? 'recent-text-unread' : ''}`}>{item?.chat_last_message?.message}</span>
                </>
            )
        }
    }

    renderChatListView = () => {
        const { filteredList, isLoading, searchText, data, isListLoading, selectedChatListPerson } = this.state;
        return (
            <div className="chat-list-section">
                <div className="heading-container">
                    <div>{'Messages'}</div>
                    {/* <div className="three-dot-icon">
                        <img src={ThreeDotsIcon} />
                    </div> */}
                </div>
                {data && data.length && !isListLoading
                    ? <div className="chat-list-container">
                        <div className="searchbox-wrapper">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchText}
                                onChange={this.searchTextChange}
                                className="search-input" />
                            <img
                                src={SearchIcon}
                                className="search-icon" />
                        </div>
                        {filteredList && filteredList.length
                            ? <div className="chat-list">
                                {filteredList && filteredList.length
                                    ? filteredList.map((item, itemIndex) => {
                                        if(item.userId === this.state.selectedChatListPerson.userId) {
                                            item.chat_unread_count = 0
                                        }
                                        return (
                                            <div className={`chat-list-item ${item.userId == this.state.selectedChatListPerson.userId ? 'selected-item' : ''}`}
                                                key={`kajjh055${itemIndex}`}
                                                onClick={() => this.onChatListPersonClick(item)}>
                                                <div className="left-details-section">
                                                    <div key={item?.userId} className="chat-profile">
                                                        <FirebaseImage
                                                            src={item?.profileImageUrl ? item?.profileImageUrl : null}
                                                            className={"profile-img"}
                                                            type={'square'}
                                                        />
                                                        {/* <img
                                                        src={DummyChatUserIcon}
                                                        className="profile-img" /> */}
                                                    </div>
                                                    <div className="recent-chat-section">
                                                        <div className="chat-person-name">{item?.fullName}</div>
                                                        <div className="chat-person-recent-chat">
                                                            {/* <img src={RecentChatVideoIcon} className="attachment-icon" />
                                                        <span className="recent-text">{item?.chat_last_message?.message}</span> */}
                                                            {this.renderMessageType(item)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-details-section">
                                                    <div className={`last-chat-time ${item?.chat_unread_count > 0 ? 'unread-last-chat-time' : ''} `}>{item?.chat_last_message?.created_time ? getChatListTime(item?.chat_last_message?.created_time) : ''}</div>
                                                    {item?.chat_unread_count > 0 ? <div className="unread-msg-count-badge">{item?.chat_unread_count}</div> : null}
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                            </div>
                            : null}
                        {/* filteredList && filteredList.length === 0 && !isLoading
                                ? this.renderNoConversationsView()
                                : null} */}
                    </div>
                    : data && data.length === 0 && !isListLoading
                        ? this.renderNoConversationsView()
                        : null}

            </div>
        )
    }

    uploadAttachmentToFirebase = (type) => {
        this.setState({ isLoading: true });
        uploadFileBase64(type === 'IMAGE' ? this.state.croppedImage : this.state.videoBase64, '', type, this.state?.senderData?.userId, type === 'IMAGE' ? 'jpg' : 'mp4').then(res => {
            this.handleSendMsg(this.state.newMessageText, type, res.metadata.fullPath, this.state.thumbnail);
        }).catch(err => {
            // console.log('upload error is', err)
            this.setState({ isLoading: false }, () => {
                this.setState({
                    showAlert: true,
                    alertTitle: strings.error,
                    alertMessage: strings.error_server_message,
                });
            });
        });
    }

    handleSendMsg = (message, type = "TEXT", url = "", thumbUrl = "") => {
        this.setState({ isLoading: true });
        let text = message.trim();
        this.setState({ message: "", isMsgTextBoxEmpty: true }, () => {
            if (!text && !(this.state.imageSelected || this.state.videoSelected)) {
                return;
            }
            sendChatMsg(this.state?.senderData?.userId, this.state?.receiverData?.userId, text, type, url, thumbUrl).then((response) => {
                if (response?.message_id) {
                    // console.log(response)
                    const temp = document.getElementById('msg-input');
                    if (temp) {
                        temp.value = '';
                    }
                    this.setState({ isLoading: false });
                    this.setState({ newMessageText: '' , TextAreaRowCount: 0});
                    if (this.state.isImageCropper) {
                        this.closeCropper();
                    }
                    if (this.state.isVideoPreview) {
                        this.closeVideoPreview();
                    }
                    if (!this.state.chatSetting) {
                        setChatSetting(this.state?.senderData?.userId, this.state?.receiverData?.userId);
                        //Update last message details to be used for list
                        updateLastMessage(this.state?.receiverData?.userId, this.state?.senderData?.userId, -1);
                    } else {
                        if (!this.state?.chatSetting?.active_users?.includes(this.state?.receiverData?.userId)) {
                            //Shoot push notification as receiver is not on this chat window
                        }
                    }
                    this.scrollToBottom();
                } else {
                    this.setState({ isLoading: false }, () => {
                        this.setState({
                            showAlert: true,
                            alertTitle: strings.error,
                            alertMessage: strings.error_server_message,
                        });
                    });
                }
            }).catch((error) => {
                this.setState({ isLoading: false }, () => {
                    this.setState({
                        showAlert: true,
                        alertTitle: strings.error,
                        alertMessage: strings.error_server_message,
                    });
                });
            })
        })
    }

    callImgMediaScreen = (url) => {
        // console.log('Herererer', url)
        this.setState({ imagePreview: true, previewImageUrl: url });
    }

    handleImagePreviewClose = () => {
        this.setState({ imagePreview: false, previewImageUrl: '' });
    }

    renderImagePreview = () => {
        return (
            <>
                <Backdrop
                    style={{ color: '#fff', zIndex: 2000 }}
                    open={this.state.imagePreview}
                    onClick={this.handleImagePreviewClose}
                >
                    <AppBar className="appbar" sx={{ position: 'relative' }}>
                        <Toolbar style={{ justifyContent: 'flex-end' }}>
                            <Button className="cancel-btn" onClick={() => this.handleImagePreviewClose()}>Close</Button>
                        </Toolbar>
                    </AppBar>
                    <FirebaseImage
                        src={this.state.previewImageUrl}
                        className={'msg-attachment-img-preview'}
                        id={this.state.previewImageUrl}
                        type={'chat'}
                        style={clsx(['msg-attachment-img-preview', {
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 5,
                        }])}
                    />
                </Backdrop>
            </>
        )
    }

    callVideoMediaScreen = (e, item) => {
        e.stopPropagation();
        // console.log('Herererer', item)
        this.setState({ videoPreview: true, previewVideoUrl: item });
    }

    handleVideoPreviewClose = () => {
        this.setState({ videoPreview: false, previewVideoUrl: '' });
    }

    renderVideoPreview = () => {
        return (
            <>
                <Backdrop
                    style={{ color: '#fff', zIndex: 2000 }}
                    open={this.state.videoPreview}
                >
                    <AppBar className="appbar" sx={{ position: 'relative' }}>
                        <Toolbar style={{ justifyContent: 'flex-end' }}>
                            <Button className="cancel-btn" onClick={() => this.handleVideoPreviewClose()}>Close</Button>
                        </Toolbar>
                    </AppBar>
                    <FirebaseVideo
                        key={this.state.previewVideoUrl?.url}
                        src={this.state.previewVideoUrl?.url}
                        index={1}
                        thumbnail={this.state.previewVideoUrl?.thumb_url ? this.state.previewVideoUrl?.thumb_url : null}
                    />
                </Backdrop>
            </>
        )
    }

    renderMessage = (item, index) => {
        if (!item?.msg_type || item.msg_type === "SYSTEM_MESSAGE") {
            return null;
        }
        var renderDateHeader = false;
        if (index === (this.state.messageData.length - 1) || (!isSameDate(item.created_time, this.state.messageData[index + 1].created_time))) {
            renderDateHeader = true
        }
        let isAttendant = this.state.receiverData?.userId === item.sender_id;
        let showMsgTime = false;
        if (index === (this.state.messageData.length - 1) || index === 0 || (!isSameDate(item?.created_time, this.state.messageData[index - 1]?.created_time)) || (item?.sender_id !== this.state.messageData[index - 1]?.sender_id) || (canShowMsgTime(item?.created_time, this.state.messageData[index - 1]?.created_time))) {
            showMsgTime = true
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}>
                {index === (this.state.messageData.length - 1) && this.state.showLoadPreviousMessage && !this.state.showFooterLoader &&
                    <Button className={'loadCommentView'} onClick={() => this.loadPreviousMessage()}>
                        <img source={LoaderIcon} className={'loadCommentIcon'} />
                        <Typography className={'loadComment'}>{strings.loading_more}</Typography>
                    </Button>}
                {renderDateHeader && <Typography className={'dayTextStyle'}>{getChatMessageDateTime(item.created_time)}</Typography>}
                <div style={{ flexDirection: isAttendant ? 'row-reverse' : 'row' }}>
                    {item.msg_type === 'TEXT' ? (
                        <div className={isAttendant ? 'oppsite-person-content' : 'current-person-content'} >
                            <div className={'msg-content-outer-wrapper'}>
                                <Typography
                                    className={'msg-text'}
                                >
                                    {item.message}
                                </Typography>

                                {showMsgTime && <Typography className={'msg-time'}>{formatAMPM(item.created_time)}</Typography>}
                            </div>
                        </div>) : (
                        item.msg_type === 'IMAGE' ? (
                            <div style={{ flexDirection: isAttendant ? 'row-reverse' : 'row' }}>
                                <div className={isAttendant ? 'oppsite-person-content' : 'current-person-content'}
                                    style={{ width: '100%' }}>
                                    <div className="image-msg-view">
                                        <Button
                                            onClick={() => { this.callImgMediaScreen(item.url) }}
                                            disableRipple style={{ padding: 0, backgroundColor: 'transparent' }}>
                                            {/* onClick={() => this.callImgMediaScreen(item.url)} > */}
                                            <FirebaseImage
                                                src={item.url}
                                                className={`msg-attachment-img ${item?.message && item?.message === '' ? 'single-img' : ''}`}
                                                id={item.docId}
                                                type={'chat'}
                                                style={clsx(['msg-attachment-img', {
                                                    borderBottomRightRadius: (item?.message ? 0 : 5),
                                                    borderBottomLeftRadius: (item?.message ? 0 : 5),
                                                }])}
                                                defaultImageStyle={clsx(['msg-attachment-img', {
                                                    borderBottomRightRadius: (item?.message ? 0 : 5),
                                                    borderBottomLeftRadius: (item?.message ? 0 : 5),
                                                    backgroundColor: 'plr'
                                                }])}
                                            />
                                        </Button>
                                        {item?.message ?
                                            <div className={isAttendant ? 'oppsite-person-content' : 'current-person-content'}
                                                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '100%' }}>
                                                {/* <RNMarkdownFormatter
                                            defaultStyles={[styles.textStyle, { color: isAttendant ? colors.COLOR_FFFFFF : colors.COLOR_333333 }]}
                                            text={item.message}
                                        /> */}
                                                <div className="msg-content-outer-wrapper">
                                                    <Typography
                                                        className={'msg-text'}
                                                    >
                                                        {item.message}
                                                    </Typography>
                                                </div>

                                            </div> : <div />}
                                        {showMsgTime && <Typography className={'msg-time'}>{formatAMPM(item.created_time)}</Typography>}
                                    </div>
                                </div>
                            </div>
                        ) :
                            <div style={{ flexDirection: isAttendant ? 'row-reverse' : 'row' }}>
                                <div className={isAttendant ? 'oppsite-person-content' : 'current-person-content'}
                                    style={{ width: '100%' }}>
                                    <div className="video-msg-view">
                                        <div className={'video-wrapper'}>
                                            <FirebaseImage
                                                src={item?.thumb_url}
                                                className={"video-image"}
                                                type={'chat'}
                                            />
                                            <IconButton className={"play-icon"} onClick={(e) => this.callVideoMediaScreen(e, item)}>
                                                <VideoPlayIcon width={40} height={40} />
                                            </IconButton>
                                        </div>
                                        {item?.message ?
                                            <div className={isAttendant ? 'oppsite-person-content' : 'current-person-content'}
                                                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '100%' }}>
                                                {/* <RNMarkdownFormatter
                                        defaultStyles={[styles.textStyle, { color: isAttendant ? colors.COLOR_FFFFFF : colors.COLOR_333333 }]}
                                        text={item.message}
                                    /> */}
                                                <div className="msg-content-outer-wrapper">
                                                    <Typography
                                                        className={'msg-text'}
                                                    >
                                                        {item.message}
                                                    </Typography>
                                                </div>
                                            </div> : <div />}
                                        {showMsgTime && <Typography className={'msg-time'}>{formatAMPM(item.created_time)}</Typography>}
                                    </div>
                                </div>
                            </div>)
                        //     item.msg_type === 'CALLOUT' ?
                        //         <View style={styles.calloutTextView} >
                        //             <View style={styles.calloutView}>
                        //                 <RNMarkdownFormatter
                        //                     defaultStyles={styles.calloutTextStyle}
                        //                     text={item.message}
                        //                 />
                        //             </View>
                        //             {showMsgTime && <Text style={styles.calloutTimeStyle}>{formatAMPM(item.created_time)}</Text>}
                        //         </View>
                        //         :
                    }
                </div>
            </div >
        )
    }

    navigateToUser = () => {
        this.props.saveOtherUserData(this.state.selectedChatListPerson);
        this.props.history.push('/viewprofile')
    }

    scrollToBottom = () => {
        const items = document.querySelectorAll(".msg-list-item");
        const last = items[0];
        last?.scrollIntoView();
        
        setTimeout(() => {
            const x = document.getElementById('myScrollID');
            if (x && x.scrollHeight) {
                x.scrollTop = x.scrollHeight;

            }
        }, 2000);
    }

    renderChatView = () => {
        const { newMessageText, isImageCropper, imageSrc, videoSrc, isVideoPreview, selectedChatListPerson, thumbnail, TextAreaRowCount } = this.state;
        return (
            <>
                <div className="chat-section">
                    <div className="heading-container">
                        <div
                            className="chat-person-header"
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.navigateToUser() }}>
                            <div className="chat-person-profile-pic">
                                <FirebaseImage
                                    key={selectedChatListPerson?.profileImageUrl}
                                    src={selectedChatListPerson?.profileImageUrl ? selectedChatListPerson?.profileImageUrl : null}
                                    className={""}
                                />
                            </div>
                            <div className="chat-person-name">{selectedChatListPerson ? selectedChatListPerson.fullName : ''}</div>
                        </div>
                    </div>
                    {/************** Chat-messages-view ***************/}
                    <div key={selectedChatListPerson?.userId} className="chat-view-container" id="chat-view-container">
                        {!isImageCropper && !isVideoPreview && this.state.messageData.length && !this.state.isLoading ?
                            <div className={`chat-msg-group ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'shrink-area' : ''}`} id={'myScrollID'}>
                                <List key={selectedChatListPerson?.userId} style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                    {this.state.messageData.map((item, index) => {
                                        return (
                                            <ListItem
                                                style={{ cursor: 'default' }}
                                                class='msg-list-item'
                                                key={item?.created_time}
                                                id={item?.created_time}>
                                                {this.renderMessage(item, index)}
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <div className="seperator"></div>
                            </div >
                            : !this.state.isMSGLoading && !isImageCropper && !isVideoPreview && !this.state.isLoading
                                ? this.renderNewConversationsView()
                                : null
                        }
                        {
                            isImageCropper
                                ? <div className={`image-cropper-view ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'shrink-area' : ''}`}>
                                    <ImageCropper
                                        isOpen={isImageCropper}
                                        src={imageSrc}
                                        onCloseCropperView={this.closeCropper}
                                        onCrop={(img) => { this.getCroppedImage(img) }} />
                                </div> : null
                        }

                        {
                            isVideoPreview
                                ? <div className={`image-cropper-view ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'shrink-area' : ''}`}>
                                    <VideoPreview
                                        isOpen={isVideoPreview}
                                        src={videoSrc}
                                        thumb={thumbnail}
                                        onCloseVideoPreview={this.closeVideoPreview}
                                    />
                                </div>
                                : null
                        }

                        {/***************** Botton Send Message Container ****************/}
                        <div className={`send-msg-wrapper ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'expand-area' : ''}`}>
                            {!isImageCropper && !isVideoPreview
                                ? <div className={`attachment-wrapper ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'expand-area' : ''}`}>
                                    <img src={AttachmentIcon} onClick={this.clickAattachmentInput} />
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .mp4"
                                        ref={this.attachmentInput}
                                        multiple={false}
                                        onChange={(e) => this.onSelectFile(e)}
                                        style={{ display: 'none' }} />
                                </div>
                                : null}
                            <div className={`text-field-wrapper ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'expand-area' : ''}`}>
                                <textarea
                                    id='msg-input'
                                    cols="50"
                                    rows="2"
                                    placeholder="Enter Text"
                                    // onKeyPress={this.handleAreaKeyPress}
                                    // onChange={this.handleAreaKeyPress}
                                    onKeyDown={this.handleAreaKeyPress}
                                    className={`msg-text-field ${isImageCropper || isVideoPreview ? 'padding-20' : ''} ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'expand-area' : ''}`}
                                    onChange={(e) => this.onCurrentTextChange(e)} 
                                    maxLength="1000"/>
                            </div>
                            <div
                                onClick={() => {
                                    isImageCropper || isVideoPreview
                                        ? this.uploadAttachmentToFirebase(isImageCropper ? 'IMAGE' : 'VIDEO')
                                        : this.handleSendMsg(newMessageText)
                                }}
                                className={`send-msg-icon-wrapper ${(newMessageText.length || this.state.videoSelected || this.state.imageSelected || isImageCropper || isVideoPreview) ? 'send-msg-icon-enable' : ''} ${newMessageText.length > 50 || TextAreaRowCount > 2 ? 'expand-area' : ''}`}>
                                <img src={(newMessageText.length || this.state.videoSelected || this.state.imageSelected || isImageCropper || isVideoPreview) ? SendMessageWhiteIcon : SendMessageIcon} />
                            </div>
                        </div>
                    </div >
                </div >
            </>
        )
    }

    renderNoConversationsView = () => {
        return (
            <div className="no-conversation-wrapper">
                <div className="content-div">
                    <div className="no-conversation-icon">
                        <img src={NoCoversationIcon} />
                    </div>
                    <div className="no-conversation-text">No conversations available</div>
                </div>
            </div>
        );
    }

    renderNewConversationsView = () => {
        return (
            <div className="new-conversation-wrapper">
                <div className="content-div">
                    <div className="new-conversation-icon">
                        <img src={NoCoversationIcon} />
                    </div>
                    <div className="new-conversation-text">Say ‘Hi’ and start the conversation!</div>
                </div>
            </div>
        );
    }

    closeAlerts = () => {
        this.setState({ showAlert: false, alertMsg: '' });
    }

    render() {
        const { isImageCropper, imageSrc, isLoading, selectedChatListPerson, imagePreview, videoPreview, showAlert, alertMsg } = this.state;
        return (
            <div className="chat-wrapper">
                <div className="chat-sub-wrapper">
                    {this.renderChatListView()}
                    {selectedChatListPerson ? this.renderChatView() : null}
                    {showSpinner(isLoading)}
                    {imagePreview ? this.renderImagePreview() : null}
                    {videoPreview ? this.renderVideoPreview() : null}
                    {showAlert && showAlertModal(alertMsg, 'Ok', showAlert, '', this.closeAlerts)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    chatUserData: state.auth.chatUserData,
});
export default connect(mapStateToProps, { saveOtherUserData, saveChatUserData })(withRouter(ChatComponent))