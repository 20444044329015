import React, { Component } from 'react';
import { data } from '../../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import '../auth.scss';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import DefaultUser from '../../../assets/default_image.svg';
import CameraIcon from '../../../assets/ic_camrea.svg';
import { validateEmail, validateEqualInputs, validatePassword, validateTextOnly } from '../../../utils/input-validation';
import { sendOTPAPI } from '../../../utils/api-helper';
import { showToast, showSpinner } from '../../../utils/common_helper';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'
import { TrendingUpRounded } from '@material-ui/icons';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Cropper from 'react-cropper';
import { set } from "../../../utils/local-storage-helper";
import { uploadFileBase64, fileType } from '../../../utils/firebase_helper/store';
import { auth, createUser } from '../../../utils/firebase_helper/auth';
import { saveAuthData } from '../../../actions/auth';
import LogoBlack from '../../../assets/logo_black.svg';
import { Hidden } from '@material-ui/core';
import 'cropperjs/dist/cropper.css';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { roles } from "../../../utils/constants"

let strings = new LocalizedStrings(data);

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            language: this.props?.language ? this.props.language : 'en',
            fullName: '',
            email: '',
            role: '',
            roleKey: '',
            website: '',
            errors: {},
            src: '',
            requestParam: props?.location?.state?.requestParam,
            userId: auth().currentUser.uid,
            isSubmitted: false,
            imageSelected: false,
            imageUrls: [],
            isHomeRedirect: false,
            isValidEmail: false,
        }
    }

    componentDidMount() {
        console.log("isFromSignup", this.props);
    }

    handleProfileUpload = () => {
        let input = document.getElementById('profile_image');
        input.click();
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result, imageSelected: true })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    handleClose = () => {
        this.setState({ imageSelected: false })
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    _crop() {
        // image in dataUrl
        // console.log(this.cropper.getCroppedCanvas().toDataURL());
    }

    onCropperInit(cropper) {
        this.cropper = cropper;
    }

    handleCropSave = () => {
        this.setState({ src: this.cropper.getCroppedCanvas().toDataURL() });
        this.setState({ imageSelected: false });
    }

    handleChange = (e) => {
        if (e.target.name == "role") {
            const data = roles.find(o => o.name === e.target.value);
            this.setState({ role: data?.name, roleKey: data?.id });
        } else {
            if (e.target.name == "email") {
                let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                if (reg.test(e.target.value) === false) {
                    this.setState({ isValidEmail: false })
                }
                else {
                    this.setState({ isValidEmail: true })
                }
            }
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ showLoader: true });
        const userId = auth().currentUser?.uid;
        if (this.state.src) {
            uploadFileBase64(
                this.state.src,
                '',
                fileType.IMAGE_JOB,
                userId,
                'jpg',
            ).then(res => {
                console.log(res.ref);
                let imageUrls = [];
                const image = {
                    isProfile: true,
                    url: res.ref,
                }
                imageUrls.push(image);
                let body = {
                    contactNo: `${this.props.userData?.selectedCountry?.code}-${this.props.userData?.contactNo}`,
                    countryCode: this.props.userData?.selectedCountry?.code,
                    countryName: this.props.userData?.selectedCountry?.name,
                    email: this.state.email || '',
                    fullName: this.state.fullName || '',
                    role: this.state.role || '',
                    roleKey: this.state.roleKey || '',
                    profileImageUrl: res.ref || '',
                    userId: userId || '',
                    website: this.state.website || '',
                    imageUrls: imageUrls || [],
                };
                body.createdAt = new Date();
                createUser(body).then(
                    res => {
                        console.info('Create firebase response', res);
                        this.setState({ showLoader: false });
                        if (this.props?.route?.params?.from == 'EDIT_PROFILE') {
                            this.props.navigation.pop();
                        } else {
                            set('VENTYOUR_PROFILE_COMPLETED', true);
                            set('VENTYOUR_USERDATA', res);
                            this.props.saveAuthData(res);
                            this.setState({ showLoader: false, isHomeRedirect: true });
                        }
                        this.setState({ showLoader: false });
                    },
                    err => {
                        this.setState({ showLoader: false }, () => {
                            setTimeout(
                                () => {
                                    this.setState({
                                        showAlert: true,
                                        alertTitle: 'Info',
                                        alertMessage: err.message,
                                    });
                                }
                            );
                        });
                    },
                );
            })
                .catch(error => {
                    this.setState({ showLoader: false }, () => {
                        setTimeout(
                            () => {
                                this.setState({
                                    showAlert: true,
                                    alertTitle: 'Info',
                                    alertMessage: error.message,
                                });
                            }
                        );
                    });
                });
        } else {
            let body = {
                contactNo: `${this.props.userData?.selectedCountry?.code}-${this.props.userData?.contactNo}`,
                countryCode: this.props.userData?.selectedCountry?.code,
                countryName: this.props.userData?.selectedCountry?.name,
                email: this.state.email || '',
                fullName: this.state.fullName || '',
                role: this.state.role || '',
                roleKey: this.state.roleKey || '',
                profileImageUrl: '',
                userId: userId || '',
                website: this.state.website || '',
                imageUrls: [],
            };
            body.createdAt = new Date();
            createUser(body).then(
                res => {
                    console.info('Create firebase response', res);
                    this.setState({ showLoader: false });
                    if (this.props?.route?.params?.from == 'EDIT_PROFILE') {
                        this.props.navigation.pop();
                    } else {
                        set('VENTYOUR_PROFILE_COMPLETED', true);
                        set('VENTYOUR_USERDATA', res);
                        this.props.saveAuthData(res);
                        this.setState({ showLoader: false });
                    }
                    this.setState({ showLoader: false });
                },
                err => {
                    this.setState({ showLoader: false }, () => {
                        setTimeout(
                            () => {
                                this.setState({
                                    showAlert: true,
                                    alertTitle: 'Info',
                                    alertMessage: err.message,
                                });
                            }
                        );
                    });
                },
            );
        }
    }

    render() {
        return (
            <>
                <div className="common-auth-wrapper">
                    {showSpinner(this.state.showLoader)}
                    <Hidden xsDown>
                        <Row>
                            <Col className="bg-image" >
                                <div className="logo"></div>
                            </Col>
                            <Col className="common-reg-wrapper">
                                <div className="form-wrapper">
                                    {strings.getLanguage() ?
                                        <>
                                            <p className="title">{strings.BASIC_INFO}</p>
                                        </>
                                        : <></>
                                    }
                                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: 86 }}>
                                            <input id="profile_image" type="file" accept="image/*" style={{ display: 'none' }} onChange={this.onSelectFile} />
                                            {this.state.src !== '' ?
                                                <img className='profileImage' onClick={this.handleProfileUpload} src={this.state.src} style={{ position: 'relative', cursor: 'pointer' }} />
                                                : (<><img onClick={this.handleProfileUpload} src={DefaultUser} style={{ position: 'relative', cursor: 'pointer' }} /><img onClick={this.handleProfileUpload} src={CameraIcon} style={{ position: 'absolute', cursor: 'pointer', bottom: 0, right: '40%' }} /></>)}
                                        </div>
                                        <div className="text-item text-item-top">
                                            <TextField className="text-field" onChange={this.handleChange}
                                                error={this.state.errors.fullName}
                                                id="fullName"
                                                name="fullName"
                                                label={strings.getLanguage() ? strings.full_name : ''}
                                                type="text"
                                                variant="outlined"
                                                value={this.state.fullName}
                                                helperText={this.state.errors.fullName}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </div>
                                        <div className="text-item">
                                            <TextField className="text-field" onChange={this.handleChange}
                                                error={this.state.errors.fullName}
                                                id="email"
                                                name="email"
                                                label={strings.getLanguage() ? strings.email : ''}
                                                type="text"
                                                variant="outlined"
                                                value={this.state.email}
                                                helperText={this.state.errors.email}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </div>
                                        <div className="text-item">
                                            <FormControl variant="outlined" className="role-dropdown text-field" error={this.state.errors.role}>
                                                {/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
                                                <InputLabel id="demo-simple-select-filled-label">{strings.getLanguage() ? strings.role : ''}</InputLabel>
                                                <Select onChange={this.handleChange}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    name="role"
                                                    value={this.state.role}
                                                // IconComponent={ArrowIcon}
                                                // className="role"
                                                // label={strings.getLanguage() ? strings.role : ''}
                                                >
                                                    {roles.map((option) => (
                                                        <MenuItem key={option.id} value={option.name}>
                                                            {option?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{this.state.errors.role}</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="text-item">
                                            <TextField className="text-field" onChange={this.handleChange}
                                                error={this.state.errors.website}
                                                id="website"
                                                name="website"
                                                label={strings.getLanguage() ? strings.website : ''}
                                                type="text"
                                                variant="outlined"
                                                value={this.state.website}
                                                helperText={this.state.errors.website}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </div>
                                        <div className="text-item email-field">
                                            <TextField className="text-field disabled" onChange={this.handleCodeChange}
                                                style={{ width: '30%', paddingRight: 5 }}
                                                // select
                                                error={this.state.errors.email}
                                                disabled
                                                id="code"
                                                name="country code"
                                                label={strings.getLanguage() ? strings.country_code : ''}
                                                type="text"
                                                variant="outlined"
                                                value={this.props.userData?.selectedCountry?.code}
                                                helperText={this.state.errors.email}
                                                inputProps={{ className: 'text-field-disabled' }}
                                            />
                                            <TextField className="text-field disabled" onChange={this.handleChange}
                                                style={{ width: '70%', paddingLeft: 5 }}
                                                disabled
                                                error={this.state.errors.email}
                                                id="phone"
                                                name="phone"
                                                label={strings.getLanguage() ? strings.phoneNo : ''}
                                                type="text"
                                                variant="outlined"
                                                value={this.props.userData?.contactNo}
                                                helperText={this.state.errors.email}
                                                inputProps={{ className: 'text-field-disabled' }}
                                            />
                                        </div>
                                        <div className="text-item">
                                            <Button
                                                type="submit"
                                                disabled={!(this.state.fullName?.length > 3 && this.state.email && this.state.role && this.state.isValidEmail)}
                                                className="common-button signup-button">{strings.getLanguage() ?
                                                    strings.continue : ''}</Button>{' '}
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Hidden>
                    <Hidden smUp>
                        <Col className="common-reg-wrapper">
                            <div className="form-wrapper">
                                {strings.getLanguage() ?
                                    <>
                                        <p className="title">{strings.BASIC_INFO}</p>
                                    </>
                                    : <></>
                                }
                                <form autoComplete="off" onSubmit={this.handleSubmit}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: 86 }}>
                                        <input id="profile_image" type="file" accept="image/*" style={{ display: 'none' }} onChange={this.onSelectFile} />
                                        {this.state.src !== '' ?
                                            <img className='profileImage' onClick={this.handleProfileUpload} src={this.state.src} style={{ position: 'relative', cursor: 'pointer' }} />
                                            : (<><img onClick={this.handleProfileUpload} src={DefaultUser} style={{ position: 'relative', cursor: 'pointer' }} /><img onClick={this.handleProfileUpload} src={CameraIcon} style={{ position: 'absolute', cursor: 'pointer', bottom: 0, right: '40%' }} /></>)}
                                    </div>
                                    <div className="text-item text-item-top">
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.fullName}
                                            id="fullName"
                                            name="fullName"
                                            label={strings.getLanguage() ? strings.full_name : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.fullName}
                                            helperText={this.state.errors.fullName}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </div>
                                    <div className="text-item">
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.fullName}
                                            id="email"
                                            name="email"
                                            label={strings.getLanguage() ? strings.email : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.email}
                                            helperText={this.state.errors.email}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </div>
                                    <div className="text-item">
                                        <FormControl variant="outlined" className="role-dropdown text-field" error={this.state.errors.role}>
                                            {/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
                                            <InputLabel id="demo-simple-select-filled-label">{strings.getLanguage() ? strings.role : ''}</InputLabel>
                                            <Select onChange={this.handleChange}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                name="role"
                                                value={this.state.role}
                                                MenuProps={{
                                                    getContentAnchorEl: () => null,
                                                }}
                                            // IconComponent={ArrowIcon}
                                            // className="role"
                                            // label={strings.getLanguage() ? strings.role : ''}
                                            >
                                                {roles.map((option) => (
                                                    <MenuItem key={option.id} value={option.name}>
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{this.state.errors.role}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="text-item">
                                        <TextField className="text-field" onChange={this.handleChange}
                                            error={this.state.errors.website}
                                            id="website"
                                            name="website"
                                            label={strings.getLanguage() ? strings.website : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.state.website}
                                            helperText={this.state.errors.website}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </div>
                                    <div className="text-item email-field">
                                        <TextField className="text-field disabled" onChange={this.handleCodeChange}
                                            style={{ width: '30%', paddingRight: 5 }}
                                            // select
                                            error={this.state.errors.email}
                                            disabled
                                            id="code"
                                            name="country code"
                                            label={strings.getLanguage() ? strings.country_code : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.props.userData?.selectedCountry?.code}
                                            helperText={this.state.errors.email}
                                            inputProps={{ className: 'text-field-disabled' }}
                                        />
                                        <TextField className="text-field disabled" onChange={this.handleChange}
                                            style={{ width: '70%', paddingLeft: 5 }}
                                            disabled
                                            error={this.state.errors.email}
                                            id="phone"
                                            name="phone"
                                            label={strings.getLanguage() ? strings.phoneNo : ''}
                                            type="text"
                                            variant="outlined"
                                            value={this.props.userData?.contactNo}
                                            helperText={this.state.errors.email}
                                            inputProps={{ className: 'text-field-disabled' }}
                                        />
                                    </div>
                                    <div className="text-item">
                                        <Button
                                            type="submit"
                                            disabled={!(this.state.fullName?.length > 3 && this.state.email && this.state.role && this.state.isValidEmail)}
                                            className="common-button signup-button">{strings.getLanguage() ?
                                                strings.continue : ''}</Button>{' '}
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Hidden>
                </div>
                {this.state.isHomeRedirect ? <Redirect to={{
                    pathname: "/home", state: {
                        isFromSignup: true,
                        requestParam: this.state
                    }
                }}></Redirect> : null}
                {this.state.imageSelected && (
                    <Dialog
                        fullWidth
                        maxWidth='md'
                        open={this.state.imageSelected}
                        onClose={this.handleCropClose}
                        TransitionComponent={this.Transition}
                    >
                        <AppBar className="appbar" sx={{ position: 'relative' }}>
                            <Toolbar style={{ justifyContent: 'center' }}>
                                <Button variant="text" style={{ marginRight: '34px' }} className="cancel-btn" onClick={this.handleCropClose}>Cancel</Button>
                                <Button className='save-btn' onClick={() => this.handleCropSave()}>
                                    {strings.save}
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <div>
                            <Cropper
                                src={this.state.src}
                                style={{ height: '75vh', width: '100%' }}
                                // Cropper.js options
                                dragMode='move'
                                background={false}
                                initialAspectRatio={1}
                                guides={true}
                                crop={this._crop.bind(this)}
                                onInitialized={this.onCropperInit.bind(this)}
                            />
                        </div>
                    </Dialog>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    userData: state.auth.loginUserData,
});
export default connect(mapStateToProps, { saveAuthData })(Signup)