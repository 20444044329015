import React from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../components/Loading-Spinner';
import ConfirmAlert from '../../components/Confirm-Alert';

export function showToast(message, type) {
  if (type) {
    if (type === "info") {
      toast.info(message, { autoClose: 3000, closeOnClick: true });
    } else if (type === "success") {
      toast.success(message);
    } else if (type === "warn") {
      toast.warn(message);
    } else if (type === "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "dark") {
      toast.dark(message);
    }
  } else {
    toast(message);
  }
}

export function showSpinner(spinnerFlag) {
  return (
    spinnerFlag && spinnerFlag === true ? <LoadingSpinner /> : null
  )
}

export function confirmAlert(message, positiveBTN, negativeBTN, isOpen, customClass, positiveCallback, negativeCallback) {
  return <ConfirmAlert
    type={'Confirm'}
    confirmMsg={message}
    positiveBTN={positiveBTN}
    negativeBTN={negativeBTN}
    isOpen={isOpen}
    customClass={customClass}
    positiveCallback={() => positiveCallback()}
    negativeCallback={() => negativeCallback()} />
}

export function showAlert(message, negativeBTN, isOpen, customClass, negativeCallback) {
  return <ConfirmAlert
    confirmMsg={message}
    // positiveBTN={positiveBTN}
    negativeBTN={negativeBTN}
    isOpen={isOpen}
    customClass={customClass}
    // positiveCallback={() => positiveCallback()}
    negativeCallback={() => negativeCallback()} />
}

export function capitalizeText(text) {
  let str = text ? text : '';
  str = str.replace('_', ' ');
  str = str.toLowerCase();
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  const finalSTR = arr.join(" ");
  return finalSTR;
}

export const showContactNumber = (phoneNumberString, code) => {
  let number = phoneNumberString;
  let no = number.length ? number.split('-').pop() : number;
  return (
    code +
    ' (' +
    no.substr(0, 3) +
    ') ' +
    no.substr(3, 3) +
    '-' +
    no.substr(6, 4)
  );
};

export function goclone(source) {
  if (Object.prototype.toString.call(source) === '[object Array]') {
    let clone = [];
    for (let i = 0; i < source.length; i++) {
      clone[i] = goclone(source[i]);
    }
    return clone;
  } else if (typeof (source) == "object") {
    let clone = {};
    for (let prop in source) {
      if (source.hasOwnProperty(prop) && source[prop]) {
        clone[prop] = goclone(source[prop]);
      } else {
        clone[prop] = source[prop];
      }
    }
    return clone;
  } else {
    return source;
  }
}