import React, { Component, useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import MenuDrawer from '../navigation/drawer';
import { withStyles } from "@material-ui/core/styles";
import { routing } from '../Router';
import { saveAuthData } from "../actions/auth";
import { setAuthToken } from "../utils/api-helper";
import { initializeApp } from "../utils/firebase_helper/auth";
import { confirmAlert } from '../utils/common_helper'
import Emitter from "../utils/Emitter";

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});

function Layout(props) {
    const [sidebarStatus, setSidebarStatus] = useState(false);
    const [isAuthenticated, SetIsAuthenticated] = useState(false);
    const [sidebarStaticStatus, setSidebarStaticStatus] = useState(false);
    const [isShowAlert, setIsShowAlert] = useState(false)
    const location = useLocation();
    const history = useHistory();

    const onChangeStatus = (status) => {
        setSidebarStatus(status);
    };

    const checkStaticOpen = (status) => {
        setSidebarStaticStatus(status)
    }

    useEffect(() => {
        if (props?.authData) {
            SetIsAuthenticated(true);
            setAuthToken(props.authData.accessToken, props.authData.refreshToken);
            initializeApp(props.authData.firebaseToken);
        } else {
            SetIsAuthenticated(false);
            setAuthToken(undefined, undefined);
        }
        // To update the access to ken and refresh token after refresh in redux
        Emitter.on("UPDATE_TOKEN", (data) => {
            let tempAuthData = { ...props.authData };
            tempAuthData.accessToken = data.access_token;
            tempAuthData.refreshToken = data.refresh_token;
            props.saveAuthData(tempAuthData);
        });
        // unmounting.
        return () => {
            Emitter.off("UPDATE_TOKEN");
        };
    }, [props.authData]);

    useEffect(() => {
        setIsShowAlert(false);
    }, [location.pathname])
    

    return (
        // <> {routing()}</>
        <div className={`${props.classes.root} ${isAuthenticated && isAuthenticated === true ? 'main-wrapper' : ''}`}>
            {confirmAlert('', '', '', isShowAlert, '', () => { }, () => { })}
            {isAuthenticated && isAuthenticated === true ?
                <MenuDrawer isStaticOpen={(e)=>checkStaticOpen(e)} />
                : null}
                
            <main className={`${isAuthenticated && isAuthenticated === true ? 'layout-main-auth' : 'layout-main-no-auth'} ${sidebarStaticStatus === false ? 'layout-siderbar-unstatic': ''}`}>
                <div className="main-page-wrapper">
                    {routing(props.authData)}
                </div>
            </main>
        </div>
    );

}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
});

export default connect(mapStateToProps, { saveAuthData })(withStyles(styles, { withTheme: true })(Layout));