// import I18n from 'i18n';
import firebase from "firebase/app";
import 'firebase/storage';

export const fileType = {
	IMAGE_CHAT: 'IMAGES/CHAT',
	VIDEO_CHAT: 'VIDEOS/CHAT',
	THUMBNAIL_CHAT: 'THUMBNAILS/CHAT',
};

const fileUrl = (ext, fileName, type, userId) => {
	const longTime = new Date().getTime();
	let file;
	if (ext) {
		file = `${fileName ? fileName + '_' + longTime : longTime}.${ext}`;
	} else {
		file = `${fileName ? fileName + '_' + longTime : longTime}`;
	}
	return `files/${userId}/${type}/${file}`;
};

const storage = firebase.storage();

/*
 * base64: File base64
 * fileName: xyz.png
 * type: IMAGES, DOCUMENTS, THUMBNAILS, VIDEOS
 * userId: backend id for login user
 */
export function uploadFileBase64(base64, fileName, type, userId = "1", fileExt, data, callback) {
    return new Promise((resolve, reject) => {
        let ref = fileUrl(fileExt, fileName, type, userId);
        let putString = null;
        if (type === 'IMAGE') {
            putString = firebase.storage().ref(ref).putString(base64.split(',')[1], 'base64');
        }
        if (type === 'THUMBNAILS') {
            putString = firebase.storage().ref(ref).putString(base64.split(',')[1], 'base64');
        }
        if(type === 'VIDEO') {
            putString = firebase.storage().ref(ref).put(base64);
        }

        if (callback) {
            putString.on('state_changed', taskSnapshot => {
                if (callback) {
                    callback(
                        taskSnapshot?.bytesTransferred,
                        taskSnapshot?.totalBytes,
                    );
                }
            });
        }
        putString
            .then(result => {
                if (data) {
                    data.ref = ref;
                    resolve(data);
                } else {
                    result.ref = ref;
                    resolve(result);
                }
            })
            .catch(error => {             
                reject({
                    code: error?.code,
                    message: error?.message,
                    title: 'error_title',
                })
            })
            
    });
}

export function deleteFile(ref) {
    return new Promise((resolve, reject) => {
      if (ref) {
        firebase.storage()
          .ref(ref)
          .delete()
          .then(result => {
            resolve(ref);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }