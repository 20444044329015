import React from 'react';
import TermsAndConditions from '../components/Terms_Condition';

function TermsAndConditionsPage(props) {

    return (
            <TermsAndConditions />
    );
}

export default TermsAndConditionsPage;