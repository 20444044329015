import React, { useEffect, useState } from 'react';
import { data } from '../../utils/locales/data';
import LocalizedStrings from 'react-localization';
import { Grid, IconButton } from '@material-ui/core';
import "./other-user-details.scss";
import FirebaseImage from "../firebase_fast_image_view";
import PlayCircle from "../../assets/play_circle.svg";
import Tag from "../../assets/tag.svg";
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseVideo from '../firebase_video_view';
import {ReactComponent as Chat} from '../../assets/chat_profile.svg'
import { saveChatUserData } from '../../actions/auth';

let strings = new LocalizedStrings(data);


function OtherUserProfile({ ...props }) {
    const [userDetails, setUserDetails] = useState(props?.otherUserData);
    const [playVideo, setPlayVideo] = useState(false);

    const handleVideoClose = () => {
        setPlayVideo(false);
    }

    const navigateToChat = (item) => {
        props.saveChatUserData(item);
        props.history.push('/chats');
    }

    const getFinalURL = (url) => {
        let trimUrl = url.toLowerCase().trim();
        if (trimUrl) {
            if (trimUrl.startsWith("https://")) {
                return trimUrl;
            } else {
                return 'https://' + trimUrl;
            }
        }  
    }
    
    const renderVideoPlayer = () => {
        return (
            <Dialog open={playVideo} onClose={handleVideoClose}>
                <DialogContent className='bio-dialog'>
                    <FirebaseVideo
                        src={userDetails?.videoUrl ? userDetails?.videoUrl[0]?.videoUrl : ''}
                        thumbnail={userDetails?.videoUrl[0]?.videoThumbnailUrl}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="user-details-wrapper">
            <IconButton className="chat-btn" onClick={() => navigateToChat(userDetails)}>
                <Chat />
            </IconButton>
            <div className="common-wrapper">
                <div className="page-header">
                    <div className="title">{strings.profile}</div>
                </div>
                <div className="other-user-profile-container">
                    <FirebaseImage
                        src={userDetails?.profileImageUrl}
                        className={"user-profile-picture"}
                    />
                </div>
                <div className="other-user-name">{userDetails?.fullName}</div>
                <div className="other-user-role">{userDetails?.role ? userDetails?.role : "Entrepreneur"}</div>

                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.BASIC_INFO}</div>
                    </div>
                    <Grid container className="info-container">
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.email}:</div>
                                <div className="value-text">{userDetails?.email}</div>
                            </Grid>
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.website}:</div>
                                {userDetails?.website ?
                                        <a className="value-link" href={getFinalURL(userDetails?.website)} target="_blank" rel="noopener noreferrer">{userDetails?.website ? userDetails?.website.toLowerCase() : 'NA'}</a>
                                        : <div className="value-text">{userDetails?.website ? userDetails?.website : '-'}</div>
                                    }
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.phoneNo}:</div>
                                <div className="value-text">{userDetails?.contactNo}</div>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} className="row-div">
                                <div className="key-text">{strings.role}:</div>
                                <div className="value-text">{userDetails?.role ? userDetails?.role : "Entrepreneur"}</div>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </div>
                {userDetails?.imageUrls?.length > 0 && (
                    <div className="personal-info-wrapper">
                        <div className="section-header">
                            <div className="title">{strings.profile_pictures}</div>
                        </div>
                        <Grid container className="info-container image-container" direction='row'>
                            <Grid item className="image-row-div" style={{
                                justifyContent: 'inherit',
                                cursor: 'auto'
                            }}>
                                <FirebaseImage
                                    src={userDetails?.imageUrls?.length > 0 ? userDetails?.imageUrls[0].url : ""}
                                    className={"profile-img"}
                                />
                            </Grid>
                            {userDetails?.imageUrls?.length > 1 && (
                                <Grid item className="image-row-div" style={{
                                    justifyContent: 'inherit',
                                    cursor: 'auto'
                                }}>
                                    <FirebaseImage
                                        src={userDetails?.imageUrls?.length > 1 ? userDetails?.imageUrls[1].url : ""}
                                        className={"profile-img"}
                                    />
                                </Grid>
                            )}
                            {userDetails?.imageUrls?.length > 2 && (
                                <Grid item className="image-row-div" style={{
                                    justifyContent: 'inherit',
                                    cursor: 'auto'
                                }}>
                                    <FirebaseImage
                                        src={userDetails?.imageUrls?.length > 2 ? userDetails?.imageUrls[2].url : ""}
                                        className={"profile-img"}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                )}
                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.bio}</div>
                    </div>
                    <div className="bio-text">{userDetails?.bio}</div>
                </div>

                <div className="personal-info-wrapper">
                    <div className="section-header">
                        <div className="title">{strings.short_video}</div>
                    </div>
                    {userDetails?.videoUrl?.length > 0 ?
                        <>
                            <div>
                                <div className="tag-container">
                                    <img src={Tag} />
                                    <div className="tag-name">{userDetails?.categoryName}</div>
                                </div>
                            </div>
                            <div style={{
                                height: '35.452vw',
                                margin: 10,
                                marginTop: 0
                            }}>
                                <FirebaseVideo
                                    key={userDetails?.videoUrl?.length > 0 ? userDetails?.videoUrl[0]?.videoUrl : ''}
                                    src={userDetails?.videoUrl?.length > 0 ? userDetails?.videoUrl[0]?.videoUrl : ''}
                                    className={"video-image-profile"}
                                    index={1}
                                    thumbnail={userDetails?.videoUrl?.length > 0 ? userDetails?.videoUrl[0]?.videoThumbnailUrl : null}
                                />
                            </div>
                        </> : null}
                </div>
                {(userDetails?.businessInfo?.name || userDetails?.businessInfo?.location || userDetails?.businessInfo?.bio) &&
                    <div className="personal-info-wrapper" style={{ marginBottom: 10 }}>
                        <div className="section-header">
                            <div className="title">{strings.business_info}</div>
                        </div>
                        <div style={{ padding: 17 }}>
                            <div className="business-subheading">{strings.heading_business_name}</div>
                            <div className="business-text">{userDetails?.businessInfo?.name || '-'}</div>
                            <div className="business-subheading">{strings.heading_business_location}</div>
                            <div className="business-text">{userDetails?.businessInfo?.location || '-'}</div>
                            <div className="business-subheading">{strings.heading_business_details}</div>
                            <div className="business-text">{userDetails?.businessInfo?.bio || '-'}</div>
                        </div>
                    </div>
                }
            </div>
            {playVideo && renderVideoPlayer()}
        </div>
    )
}
const mapStateToProps = (state) => ({
    otherUserData: state.auth.otherUserData,
});
export default connect(mapStateToProps, {saveChatUserData})(OtherUserProfile);