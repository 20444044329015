import { Badge, Button, FormControl, Hidden, InputAdornment, Menu, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { default as ListItem, default as ListItemButton } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Container, Dropdown, DropdownMenu, DropdownToggle, Navbar } from 'reactstrap';
import { saveFilterData, saveOtherUserData, saveSearchData } from '../../actions/auth';
import { ReactComponent as ArrowIcon } from '../../assets/arrowicon.svg';
import RemoveIcon from "../../assets/icon_remove.svg";
import { ReactComponent as CloseIcon } from '../../assets/ic_remove.svg';
import { ReactComponent as Bell } from '../../assets/images/header/ic_bell.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/header/ic_filter.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/header/ic_searchIcon.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as MobileSearch } from '../../assets/mobile_search.svg';
import { ReactComponent as NoSearchDataFound } from '../../assets/no_search_data.svg';
import { ReactComponent as FilterSearchIcon } from '../../assets/search_icon.svg';
import { goclone, showToast } from '../../utils/common_helper';
import { filterRoles } from '../../utils/constants';
import Emitter from '../../utils/Emitter';
import { getCategories, getDividedSearchData, getMarketPlaceListFB, getUnreadNotificationsFB, getUser, setNotificationListSnapshot } from '../../utils/firebase_helper/auth';
import { getArray, setArray } from '../../utils/local-storage-helper';
import { data } from '../../utils/locales/data';
import FirebaseImage from '../firebase_fast_image_view';
import NotificationListComponent from '../notification-list';
import './style.scss';
// import { useHistory } from 'react-router';

let strings = new LocalizedStrings(data);
// let history = useHistory()

class TopBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userData: props.authData,
			categoriesList: [],
			filteredCategoriesList: [],
			marketPlaceList: [],
			filteredMarketPlaceList: [],
			selectedCategory: props.filterData?.filterCategory ?? [],
			userNameSearchedList: [],
			bioSearchedList: [],
			businessSearchedList: [],
			selecedRole: 'All',
			searchText: '',
			searchBarText: '',
			open: true,
			filterRole: props.filterData?.filterRole ?? { id: 'all', name: 'All' },
			filterData: props.filterData ?? { filterRole: { id: 'all', name: 'All' }, filterCategory: [] },
			selectedRoleIndex: 0,
			searchOpen: false,
			isLoading: false,
		}
		this.arrayHolder = goclone(this.state.data);
		setNotificationListSnapshot((unreadNotiCount) => {
			// console.log("aa ------- unreadNotiCount=>", unreadNotiCount);
			this.setState({ unreadNotiCount });
		});
		Emitter.on('NOTI_UPDATE', this.updateNotiCount);
		Emitter.on('VENTYOUR_USER_UPDATE', this.updateUser);
	}

	updateUser = (newValue) => {
		// console.log("aa ---------------------- VENTYOUR_USER_UPDATE=>", this.props.authData?.profileImageUrl);
		if (this.state.isMount) {
			this.setState({ userData: this.props.authData });
		}
	}

	updateNotiCount = (newValue) => {
		getUnreadNotificationsFB((unreadNotiCount) => {
			// console.log("aa ------- unreadNotiCount=>", unreadNotiCount);
			if (this.state.isMount) {
				this.setState({ unreadNotiCount });
			}
		});
	}

	componentDidMount() {
		this.state.isMount = true;
		// this.setState({ searchOpen: Boolean(this.state.searchAnchor) })
		getCategories().then(res => {
			const documents = res.docs.map(doc => doc.data());
			this.setState({ categoriesList: documents, filteredCategoriesList: documents });
		});
		this.getFeedList();
		if (this.state.selectedCategory.length < 1) {
			const data = getArray('filterData');
			this.setState({ selectedCategory: data || [] });
			let body = {
				filterRole: this.state.filterRole,
				filterCategory: data,
			};
			this.props.saveFilterData(body);
		}
		getDividedSearchData().then(res => {
			// console.log("dataforsearch", res)
		})
		// console.log("this.props.history", this.props.history.push('#'))
	}

	componentWillUnmount() {
		this.state.isMount = false;
		Emitter.off('VENTYOUR_USER_UPDATE', this.updateUser);
		Emitter.off('NOTI_UPDATE', this.updateNotiCount);
	}

	componentDidUpdate(prevProps) {
		// if ((prevProps.authData?.profileImageUrl !== this.props.authData?.profileImageUrl ||
		// 	prevProps.authData?.fullName !== this.props.authData?.fullName) && this.props.authData) {
		// 	// console.log(this.props.authData)
		// 	this.setState({ userData: this.props.authData });
		// }
	}

	getFeedList = () => {
		// console.log(":::filterData2", this.state.filterData)
		try {
			getMarketPlaceListFB(false, 20, this.state.filterData).then(res => {
				this.setState({ marketPlaceList: res, filteredMarketPlaceList: res });
				// console.log("feeds", res)
				this.arrayHolder = goclone(this.state.marketPlaceList);
				this.props.saveSearchData(res)
				// console.log("searchD", this.props.searchData)
				if (res.length == 0) {
					this.setState({ isEmpty: true })
				}
			});
		} catch (ex) {
			console.error(ex);
		}
	}

	handleToggle = (value) => () => {

		const currentIndex = this.state.selectedCategory.indexOf(value);
		const newChecked = [...this.state.selectedCategory];
		if (currentIndex === -1) {
			if(this.state.selectedCategory?.length === 10) {
				showToast('You can only select up to 10 categories');
			} else {
				newChecked.unshift(value);
			}
		} else {
			newChecked.splice(currentIndex, 1);
		}
		this.setState({ selectedCategory: newChecked });
	};

	handleRemove = (index) => {
		const newChecked = [...this.state.selectedCategory];
		newChecked.splice(index, 1);
		this.setState({ selectedCategory: newChecked });
	}

	handleClearAll = () => {
		this.setState({ selectedCategory: [], filterRole: { id: 'all', name: 'All' } })
		let body = {
			filterRole: this.state.filterRole,
			filterCategory: this.state.selectedCategory,
		};
		this.props.saveFilterData(body);
	}

	handleViewAll = (e, cat) => {
		if (cat == 'users') {
			e.preventDefault();
			e.stopPropagation();
			console.log("hello")
			this.setState({ usersPage: true, searchAnchor: false })
			this.props.history.push('/search', { flag: 'users' })
		} else if (cat == 'bio') {
			e.preventDefault();
			e.stopPropagation();
			this.setState({ bioPage: true })
			this.props.history.push('/search', { flag: 'bio' })
		} else if (cat == 'business') {
			e.preventDefault();
			e.stopPropagation();
			this.setState({ businessPage: true })
			this.props.history.push('/search', { flag: 'business' })
		}
	}

	handleChange = (e) => {
		const data = filterRoles.find(o => o.name === e.target.value);
		console.log("handlesearch==", data, data.length)
		this.setState({ filterRole: data, applyEnableCheck: true })
	}

	handleRoleItemClick = (event, index) => {
		this.setState({ selectedIndex: index });
	}

	handleFilterApply = () => {
		let body = {
			filterRole: this.state.filterRole,
			filterCategory: this.state.selectedCategory,
		};
		this.props.saveFilterData(body);
		// this.setState({ applyEnableCheck: false })
		// console.log("filData", body, body?.filterRole)
		setArray('filterData', body);
		this.handleCloseFilter();
	}

	toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

	handleSignOut = () => {
		alert("signout")
	}

	handleFilter = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleCloseFilter = () => {
		this.setState({ anchorEl: null });
	}

	toggleNotification = () => {
		this.setState({ notificationOpen: !this.state.notificationOpen })
	}

	closeNotification = () => {
		this.setState({ notificationOpen: false })
	}

	handleSearchChange = (e) => {
		let trimmedText = e.target.value.trim();
		let filteredList = this.state.categoriesList;
		if (trimmedText && filteredList?.length) {
			filteredList = filteredList.filter(item => item?.name?.toLowerCase().includes(trimmedText.toLowerCase()));
		}
		this.setState({ searchText: e.target.value, filteredCategoriesList: filteredList, clearSearch: true });
	}

	onRemoveText = () => {
		this.setState({ searchText: '', filteredCategoriesList: this.state.categoriesList, clearSearch: false });
	}

	closeMenu = () => {
		this.setState({ searchMenu: false }, () => {
			document.removeEventListener('click', this.closeMenu);
		});
	}

	handleListSearchChange = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true });
		let trimmedText = e.target.value.trim();
		if (trimmedText != '') {
			// this.setState({ searchAnchor: e.currentTarget })
			this.setState({ searchMenu: true }, () => {
				document.addEventListener("click", this.closeMenu);
			});
		} else {
			this.setState({ searchMenu: false }, () => {
				document.removeEventListener('click', this.closeMenu);
			});

		}
		this.props.saveSearchData(trimmedText)
		console.log(trimmedText)
		getDividedSearchData(trimmedText, 3).then(res => {
			this.setState({ isLoading: false, userNameSearchedList: res?.nameSearch?.slice(0, 3), bioSearchedList: res?.bioSearch?.slice(0, 3), businessSearchedList: res?.businessNameSearch?.slice(0, 3) })
			console.log("userNameSearchedList", this.state.userNameSearchedList)

		})
		this.setState({ searchBarText: e.target.value, clearSearch: true });
	}

	onRemoveSearchedText = () => {
		this.setState({ searchBarText: '', searchMenu: false, clearSearch: false });
		this.props.saveSearchData('')

	}

	handleClick = (event) => {
		this.setState({ searchAnchor: event.currentTarget });
	}

	handleClose = () => {
		this.setState({ searchAnchor: null });
	}

	openProfile = (item) => {
		console.log('in openProfile fun...', this.props.history);
		getUser(item?.userId).then(res => {
			if (res) {
				console.log('my other profile ==> ', res);
				this.props.saveOtherUserData(res);
				// this.props.closeNotificationMenu();
				this.props.history.push('/viewprofile')
			}
		}).catch(error => {
			console.log('my other profile err ==> ', error);
			// setIsLoading(false);
			// setAlertMsg(error.message)
			this.setState({ showAlert: true, alertMsg: error.message })
		});
	}

	render() {
		// const { authData } = this.props;
		// console.log("locale", this.props?.history?.location?.pathname)
		return (
			<Navbar fixed="top" style={{ height: 50, boxShadow: '0 3px 15px rgba(0, 0, 0, 0.06)', backgroundColor: '#141414', padding: 0, }} >
				<Hidden xsDown>
					<Container style={{ padding: '0 15vw', maxWidth: '100%' }} >
						<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
							< div style={{ display: 'flex' }}>
								<div style={{ display: 'flex' }}>
									<IconButton
										color="#FFF"
										aria-label="open drawer"
										edge="start"
										style={{ marginLeft: 0, color: 'white', padding: 0 }}
									>
										<MenuIcon
											// onMouseEnter={this.props.onHoverOpen} 
											onClick={this.props.handleDrawerOpen} />
									</IconButton>
								</div>
								<Logo className='top-bar-logo' />
								{/* *********Searchbar******* */}
								{this.props?.history?.location?.pathname == '/home' ?
									<>
										<div className="search-container">
											<FormControl sx={{ width: '25ch', backgroundColor: '#fff' }} variant="outlined">

												<OutlinedInput
													id="outlined-adornment-weight"
													value={this.state.searchBarText}
													onChange={this.handleListSearchChange}
													placeholder="Search"
													inputProps={{
														autoComplete: 'off'
													}}

													startAdornment={<InputAdornment style={{ padding: '0px' }}><IconButton><SearchIcon /></IconButton></InputAdornment>}
													endAdornment={this.state.searchBarText != '' ?
														<InputAdornment>
															<IconButton
																aria-label="remove search"
																onClick={this.onRemoveSearchedText}
																edge="end"
																style={{ marginRight: '0px' }}
															>
																<CloseIcon />
															</IconButton>
														</InputAdornment> : null}
												// onClick={() => { this.setState({ searchOpen: true }) }}
												/>
											</FormControl>
											{/* <Menu
											id="basic-menu"
											className="search-dropdown"
											anchorEl={this.state.searchAnchor}
											open={this.state.searchAnchor}
											onClose={this.handleClose}
											aria-haspopup="true"
											aria-expanded={this.state.searchOpen ? 'true' : undefined}
											getContentAnchorEl={null}
											anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
											transformOrigin={{ vertical: 'top', horizontal: 'center' }}
											style={{ overflow: "hidden" }}> */}
											{this.state.searchMenu ?
												<div className="search-dropdown-custom">
													{this.state.userNameSearchedList.length == 0 && this.state.bioSearchedList == 0 && this.state.businessSearchedList == 0 ?
														!this.state.isLoading && (<div className="no-search-data-found"><NoSearchDataFound /></div>) :
														<div>
															{this.state.userNameSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.users}</div>
																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'users')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem onClick={this.handleClose} className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.userNameSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { console.log("clicked", index) }}
																				>
																					<div
																						style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}
																						onClick={() => this.openProfile(item)}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div
																							className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-user`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<span className="option_title">{item?.fullName}</span>
																						<span className="option_role">{item?.role}</span>
																						{/* <div className="option_role" style={{ fontSize: '13px' }}>{role}</div> */}
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																	{/* </MenuItem> */}
																	{this.state.bioSearchedList == 0 && this.state.businessSearchedList == 0 ? null :
																		<hr style={{ color: '#A8A8BC', height: '1px', opacity: '0.4' }} />}
																</div> : null}
															{this.state.bioSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.bio}</div>

																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'bio')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.bioSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { this.setState({ searchAnchor: false }) }}
																				>
																					<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}
																						onClick={() => this.openProfile(item)}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-bio`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<div>
																							<span className="option_title">{item?.fullName}</span>
																							<span className="option_role">{item?.role}</span>
																							<div className="option_des" >{item?.bio}</div>
																						</div>
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																	{/* </MenuItem> */}
																	{this.state.businessSearchedList == 0 ? null :
																		<hr style={{ color: '#A8A8BC', height: '1px', opacity: '0.4' }} />}
																</div> : null}
															{this.state.businessSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.business}</div>
																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'business')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.businessSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { this.setState({ searchAnchor: false }) }}
																				>
																					<div
																						onClick={() => this.openProfile(item)}
																						style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-business`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<div>
																							<span className="option_title">{item?.fullName}</span>
																							<span className="option_role">{item?.role}</span>
																							<div className="option_des" >{item?.businessInfo?.name}</div>
																						</div>
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																</div> : null}
														</div>}
													{/* </MenuItem> */}
													{/* </Menu> */}
												</div> : null}
										</div>
										<div className="filter" >
											<Badge color="primary" variant='dot' invisible={this.state.selectedCategory?.length < 1}>
												<FilterIcon aria-controls="basic-menu"
													aria-haspopup="true"
													aria-expanded={this.state.open ? 'true' : undefined}
													onClick={this.handleFilter}
												/>
											</Badge>
											<Menu
												id="basic-menu"
												className="filter-dropdown filter-container"
												anchorEl={this.state.anchorEl}
												open={this.state.anchorEl}
												onClose={this.handleCloseFilter}
											>
												<div className="header-container">
													<div className="filter_title">Filter</div>
													<div className="clear-all-text" onClick={this.handleClearAll}>{strings.clear_all}</div>
												</div>
												<div style={{ height: 60, marginLeft: '16px', marginRight: '16px' }}>
													<FormControl variant="outlined" className="role-dropdown">
														{/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
														{/*<InputLabel id="demo-simple-select-filled-label">{strings.role}</InputLabel>*/}
														<Select onChange={(e) => this.handleChange(e)}
															labelId="demo-simple-select-outlined-label"
															id="demo-simple-select-outlined"
															name="role"
															value={this.state.filterRole?.name}
															IconComponent={ArrowIcon}
															className="role-dd"
														// label={strings.getLanguage() ? strings.role : ''}
														>
															{filterRoles.map((option, index) => (
																<MenuItem key={option.id + index} value={option.name}
																// selected={index === this.state.selectedIndex}
																// onClick={event => this.handleRoleItemClick(event, index)}
																>
																	{option?.name}
																	{/* {this.state.selectedRoleIndex === index}{
																	<ListItemIcon >
																		<ArrowIcon />
																	</ListItemIcon>
																} */}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</div>
												<div className='category-search' style={{ backgroundColor: 'transparent !important', marginLeft: '16px', marginRight: '16px' }}>
													<div className='sub-heading-text'>
														{strings.select_categories}
													</div>
													{/* <div className="filter-search-bar-container"> */}
													<TextField
														variant='outlined'
														fullWidth
														// label={strings.search}
														placeholder={strings.search}
														value={this.state.searchText}
														onChange={this.handleSearchChange}
														inputProps={{ className: 'search-category' }}
														InputProps={{
															startAdornment: <InputAdornment position="start"><FilterSearchIcon /> </InputAdornment>,
															endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end">{this.state.searchText != '' ? <CloseIcon onClick={this.onRemoveText} /> : null} </InputAdornment>,

														}}
													/>
													{/* </div> */}
													<div className="chip-list">
														{this.state.selectedCategory?.map((item, index) => {
															return (
																<div className="chip-list-item">
																	<p className="chip-list-item-text">{item}</p>
																	<IconButton className="chip-list-remove" onClick={() => this.handleRemove(index)}>
																		<img src={RemoveIcon} className="chip-list-remove" />
																	</IconButton>
																</div>
															)
														})}
													</div>
													<List className='list-height'>
														{this.state.filteredCategoriesList.map((value) => {
															const labelId = `checkbox-list-label-${value.name}`;
															return (
																<ListItem
																	key={value.name}
																	disablePadding
																>
																	<ListItemButton role={undefined} onClick={this.handleToggle(value.name)} dense>
																		<ListItemIcon>
																			<Checkbox
																				edge="start"
																				checked={this.state.selectedCategory.indexOf(value.name) !== -1}
																				onChange={() => {
																					this.setState({ applyEnableCheck: true });
																				}}
																				tabIndex={-1}
																				disableRipple
																				size="medium"
																				// icon={<CheckedIcon />}
																				// checkedIcon={<CheckedIcon />}
																				color={'primary'}
																				style={{ backgroundColor: 'transparent', color: '#E0E0E0', height: 34, width: 54 }}
																				inputProps={{ 'aria-labelledby': labelId }}
																			/>
																		</ListItemIcon>
																		<ListItemText className='checkbox-title' id={labelId} primary={`${value.name}`} />
																	</ListItemButton>
																</ListItem>
															);
														})}
													</List>
												</div>
												<div id='buttonItem' className='button-item' style={{ paddingLeft: 0, position: 'sticky' }}>
													{/* <Card raised style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}> */}
													<div>
														<Button className="btn-class" style={{ textTransform: 'capitalize' }} disableRipple onClick={() => this.handleCloseFilter()}>Cancel</Button>
													</div>
													<div>
														<Button className={this.state.applyEnableCheck ? "apply-class" : "apply-class-disable"} disabled={!this.state.applyEnableCheck} style={{ textTransform: 'capitalize', border: 'none' }} onClick={() => this.handleFilterApply()}>Apply</Button>
													</div>
													{/* </Card> */}
												</div>
											</Menu>
										</div>
										{/* null} */}
										{/* *********Searchbar End******* */}
									</>
									: null}
							</div>
							<div className="right-section">
								<Dropdown
									isOpen={this.state.notificationOpen}
									toggle={this.toggleNotification}>
									<DropdownToggle className="notification-toggle-btn" >
										<div style={{ position: 'relative' }}>
											{this.state.unreadNotiCount ? <Badge color="primary" variant='dot' overlap="circular">
												<Bell />
											</Badge> : <Bell />}
											{/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-info notification-badge">
											{'&nbsp'}
											<span class="visually-hidden">unread messages</span>
										</span> */}
										</div>
									</DropdownToggle>
									<DropdownMenu right className="notification-dropdown-menu">
										<NotificationListComponent closeNotificationMenu={() => this.closeNotification()} onMenuSelect={this.props?.onMenuSelect} />
									</DropdownMenu>
								</Dropdown>
								<div style={{
									display: 'flex',
									alignItems: 'center',
								}} onClick={() => this.props.history.push('/userProfile')}>
									<div className="user-name">
										{this.state.userData?.fullName}
									</div>
									<FirebaseImage
										src={this.state.userData?.profileImageUrl}
										className='profile-avatar' />
								</div>
							</div>
						</div>
					</Container >
				</Hidden>
				<Hidden smUp>
					<Container style={{ maxWidth: '100%' }} >
						<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
							< div style={{ display: 'flex', width: '100%' }}>
								<div style={{ display: 'flex' }}>
									<IconButton
										color="#FFF"
										aria-label="open drawer"
										edge="start"
										style={{ marginLeft: 0, color: 'white', padding: 0 }}
									>
										<MenuIcon
											// onMouseEnter={this.props.onHoverOpen} 
											onClick={this.props.handleDrawerOpen} />
									</IconButton>
								</div>
								{!this.state.searchOpen && (
									<div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
										<Logo className='top-bar-logo' />
									</div>
								)}
								{/* *********Searchbar******* */}
								{(this.props?.history?.location?.pathname == '/home' && this.state.searchOpen) ?
									<>
										<div className="search-container">
											<OutlinedInput
												id="outlined-adornment-weight"
												value={this.state.searchBarText}
												onChange={this.handleListSearchChange}
												placeholder="Search"
												inputProps={{
													autoComplete: 'off'
												}}

												startAdornment={<InputAdornment style={{ padding: '0px' }}><IconButton><SearchIcon /></IconButton></InputAdornment>}
												endAdornment={this.state.searchBarText != '' ?
													<InputAdornment>
														<IconButton
															aria-label="remove search"
															onClick={this.onRemoveSearchedText}
															edge="end"
															style={{ marginRight: '0px' }}
														>
															<CloseIcon />
														</IconButton>
													</InputAdornment> : null}
											// onClick={() => { this.setState({ searchOpen: true }) }}
											/>
											{this.state.searchMenu ?
												<div className="search-dropdown-custom">
													{this.state.userNameSearchedList.length == 0 && this.state.bioSearchedList == 0 && this.state.businessSearchedList == 0 ?
														!this.state.isLoading && (<div className="no-search-data-found"><NoSearchDataFound /></div>) :
														<div>
															{this.state.userNameSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.users}</div>
																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'users')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem onClick={this.handleClose} className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.userNameSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { console.log("clicked", index) }}
																				>
																					<div
																						style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}
																						onClick={() => this.openProfile(item)}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div
																							className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-user`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<span className="option_title">{item?.fullName}</span>
																						<span className="option_role">{item?.role}</span>
																						{/* <div className="option_role" style={{ fontSize: '13px' }}>{role}</div> */}
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																	{/* </MenuItem> */}
																	{this.state.bioSearchedList == 0 && this.state.businessSearchedList == 0 ? null :
																		<hr style={{ color: '#A8A8BC', height: '1px', opacity: '0.4' }} />}
																</div> : null}
															{this.state.bioSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.bio}</div>

																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'bio')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.bioSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { this.setState({ searchAnchor: false }) }}
																				>
																					<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}
																						onClick={() => this.openProfile(item)}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-bio`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<div>
																							<span className="option_title">{item?.fullName}</span>
																							<span className="option_role">{item?.role}</span>
																							<div className="option_des" >{item?.bio}</div>
																						</div>
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																	{/* </MenuItem> */}
																	{this.state.businessSearchedList == 0 ? null :
																		<hr style={{ color: '#A8A8BC', height: '1px', opacity: '0.4' }} />}
																</div> : null}
															{this.state.businessSearchedList.length != 0 ?
																<div>
																	<div className="header-container">
																		<div className='search-menu-title'>{strings.business}</div>
																		<div className="clear-all-text" onClick={(e) => this.handleViewAll(e, 'business')}>{strings.view_all}</div>
																	</div>
																	{/* <MenuItem className='category-search' style={{ backgroundColor: 'transparent !important' }}> */}
																	<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
																		{this.state.businessSearchedList?.map((item, index) => {
																			return (
																				<ListItem
																					key={index + 'business'}
																					disablePadding
																					onClick={() => { this.setState({ searchAnchor: false }) }}
																				>
																					<div
																						onClick={() => this.openProfile(item)}
																						style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
																						{/* <Avatar src={getMediaUrl(item?.profileImageUrl)} /> */}
																						<div className="search-avatar-div">
																							<FirebaseImage
																								key={item?.profileImageUrl ? item?.profileImageUrl : `${index}-default-business`}
																								src={item?.profileImageUrl ? item?.profileImageUrl : null}
																								className={"search-avatar-img"}
																							/>
																						</div>
																						<div>
																							<span className="option_title">{item?.fullName}</span>
																							<span className="option_role">{item?.role}</span>
																							<div className="option_des" >{item?.businessInfo?.name}</div>
																						</div>
																					</div>
																				</ListItem>
																			);
																		})}
																	</List>
																</div> : null}
														</div>}
												</div> : null}
										</div>
										{/* null} */}
										{/* *********Searchbar End******* */}
									</>
									: null}
							</div>
							<div className="right-section">
								{this.props?.history?.location?.pathname == '/home' && (
								<div style={{
									display: 'flex',
									alignItems: 'center',
								}} onClick={() => this.setState({ searchOpen: !this.state.searchOpen })}>
									<MobileSearch />
								</div>

								)}
								<div className="filter" >
									<Badge color="primary" variant='dot' invisible={this.state.selectedCategory?.length < 1}>
										<FilterIcon aria-controls="basic-menu"
											aria-haspopup="true"
											aria-expanded={this.state.open ? 'true' : undefined}
											onClick={this.handleFilter}
										/>
									</Badge>
									<Menu
										id="basic-menu"
										className="filter-dropdown filter-container"
										anchorEl={this.state.anchorEl}
										open={this.state.anchorEl}
										onClose={this.handleCloseFilter}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										<div className="header-container">
											<div className="filter_title">Filter</div>
											<div className="clear-all-text" onClick={this.handleClearAll}>{strings.clear_all}</div>
										</div>
										<div style={{ height: 60, marginLeft: '16px', marginRight: '16px' }}>
											<FormControl variant="outlined" className="role-dropdown">
												{/* <InputLabel htmlFor="outlined-age-native-simple">{strings.getLanguage() ? strings.role : <></>}</InputLabel> */}
												{/*<InputLabel id="demo-simple-select-filled-label">{strings.role}</InputLabel>*/}
												<Select onChange={(e) => this.handleChange(e)}
													labelId="demo-simple-select-outlined-label"
													id="demo-simple-select-outlined"
													name="role"
													value={this.state.filterRole?.name}
													IconComponent={ArrowIcon}
													className="role-dd"
												// label={strings.getLanguage() ? strings.role : ''}
												>
													{filterRoles.map((option, index) => (
														<MenuItem key={option.id + index} value={option.name}
														// selected={index === this.state.selectedIndex}
														// onClick={event => this.handleRoleItemClick(event, index)}
														>
															{option?.name}
															{/* {this.state.selectedRoleIndex === index}{
																	<ListItemIcon >
																		<ArrowIcon />
																	</ListItemIcon>
																} */}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>
										<div className='category-search' style={{ backgroundColor: 'transparent !important', marginLeft: '16px', marginRight: '16px' }}>
											<div className='sub-heading-text'>
												{strings.select_categories}
											</div>
											{/* <div className="filter-search-bar-container"> */}
											<TextField
												variant='outlined'
												fullWidth
												// label={strings.search}
												placeholder={strings.search}
												value={this.state.searchText}
												onChange={this.handleSearchChange}
												inputProps={{ className: 'search-category' }}
												InputProps={{
													startAdornment: <InputAdornment position="start"><FilterSearchIcon /> </InputAdornment>,
													endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end">{this.state.searchText != '' ? <CloseIcon onClick={this.onRemoveText} /> : null} </InputAdornment>,

												}}
											/>
											{/* </div> */}
											<div className="chip-list">
												{this.state.selectedCategory?.map((item, index) => {
													return (
														<div className="chip-list-item">
															<p className="chip-list-item-text">{item}</p>
															<IconButton className="chip-list-remove" onClick={() => this.handleRemove(index)}>
																<img src={RemoveIcon} className="chip-list-remove" />
															</IconButton>
														</div>
													)
												})}
											</div>
											<List className='list-height'>
												{this.state.filteredCategoriesList.map((value) => {
													const labelId = `checkbox-list-label-${value.name}`;
													return (
														<ListItem
															key={value.name}
															disablePadding
														>
															<ListItemButton role={undefined} onClick={this.handleToggle(value.name)} dense>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		checked={this.state.selectedCategory.indexOf(value.name) !== -1}
																		onChange={() => {
																			this.setState({ applyEnableCheck: true });
																		}}
																		tabIndex={-1}
																		disableRipple
																		size="medium"
																		// icon={<CheckedIcon />}
																		// checkedIcon={<CheckedIcon />}
																		color={'primary'}
																		style={{ backgroundColor: 'transparent', color: '#E0E0E0', height: 34, width: 54 }}
																		inputProps={{ 'aria-labelledby': labelId }}
																	/>
																</ListItemIcon>
																<ListItemText className='checkbox-title' id={labelId} primary={`${value.name}`} />
															</ListItemButton>
														</ListItem>
													);
												})}
											</List>
										</div>
										<div id='buttonItem' className='button-item' style={{ paddingLeft: 0, position: 'sticky' }}>
											{/* <Card raised style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}> */}
											<div>
												<Button className="btn-class" style={{ textTransform: 'capitalize' }} disableRipple onClick={() => this.handleCloseFilter()}>Cancel</Button>
											</div>
											<div>
												<Button className={this.state.applyEnableCheck ? "apply-class" : "apply-class-disable"} disabled={!this.state.applyEnableCheck} style={{ textTransform: 'capitalize', border: 'none' }} onClick={() => this.handleFilterApply()}>Apply</Button>
											</div>
											{/* </Card> */}
										</div>
									</Menu>
								</div>
								<Dropdown
									isOpen={this.state.notificationOpen}
									toggle={this.toggleNotification}>
									<DropdownToggle className="notification-toggle-btn" >
										<div style={{ position: 'relative' }}>
											{this.state.unreadNotiCount ? <Badge color="primary" variant='dot' overlap="circular">
												<Bell />
											</Badge> : <Bell />}
											{/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-info notification-badge">
											{'&nbsp'}
											<span class="visually-hidden">unread messages</span>
										</span> */}
										</div>
									</DropdownToggle>
									<DropdownMenu right className="notification-dropdown-menu">
										<NotificationListComponent closeNotificationMenu={() => this.closeNotification()} onMenuSelect={this.props?.onMenuSelect} />
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</Container >
				</Hidden>
			</Navbar >
		);
	}
}

const mapStateToProps = (state) => ({
	authData: state.auth.authData,
	filterData: state.auth.filterData,
	searchData: state.auth.searchData,
});

export default connect(mapStateToProps, { saveFilterData, saveSearchData, saveOtherUserData })(withRouter(TopBar));