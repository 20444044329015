import React from 'react';
import './style.scss';

const EmptyAction = props => {
    return (
        <div className="empty-wrapper centered-style">
        <div>
            <img src={props.icon} />
            <p>{props.message}</p>
            <button className="empty-action-btn" onClick={props.buttonClick}>{props.buttonText}</button>
        </div>
    </div>
    );
}
export default EmptyAction;